import { t } from 'i18next'
import React from 'react'

import { MdOutlineSubtitles ,MdOutlinePhotoSizeSelectLarge} from "react-icons/md";
import { BsCardHeading} from "react-icons/bs";
import InputRadio from './InputRadio';
import { useDispatch, useSelector } from 'react-redux';
import { Select_Cart_Style } from '../../services/redux/action/action';
import { useEffect } from 'react';

export default function SelectCartStyle() {
const dispatch =useDispatch()
let dataComang = useSelector(state =>state.Select_Cart_Style)
const hendlingData = (name,value)=>{
  let Obect =new Object
  Obect[name] =value
  if(dataComang){
     dataComang[name] =value
    dispatch(Select_Cart_Style(dataComang))
  }else{
    dispatch(Select_Cart_Style(Obect))
  }
}
// show test
useEffect(()=>{
 let data = JSON.parse(sessionStorage.getItem("Select_Cart_Style"))
 if(data){
  dispatch(Select_Cart_Style(data))
   }else{
  dispatch(Select_Cart_Style({  Cardcontent: "All",ProductDisplayFormat:"scrolling"}))
 }
},[dispatch])

  return (
    <>
    <form   onChange={(e)=>{  hendlingData(e.target.name,e.target.value,1)}}>
      <h5 className='text-center'>
      {t("Card content")}
      </h5>
      <div className='d-flex  justify-content-around fs-6 flex-wrap p-2'>
      
        <InputRadio dir={"1"} name={"Cardcontent"} value={"Image"} StyelOption={dataComang.Cardcontent} >
         <MdOutlinePhotoSizeSelectLarge/> {t("View photos")}
        </InputRadio>
        <InputRadio dir={"2"} name={"Cardcontent"} value={"Name"} StyelOption={dataComang.Cardcontent} >
        <MdOutlineSubtitles/> {t("View Name")}
        </InputRadio>
        <InputRadio dir={"4"} name={"Cardcontent"} value={"All"} StyelOption={dataComang.Cardcontent} >
        <BsCardHeading/> {t("View All")}
        </InputRadio>
      
      </div>
     
    </form>
    <form   onChange={(e)=>{ hendlingData(e.target.name,e.target.value,2)}}>
      <h5 className='text-center'>
      {t("Products display format")}
      </h5>
      <div className='d-flex  justify-content-around fs-6 flex-wrap py-2'>
  
        <InputRadio dir={"5"} name={"ProductDisplayFormat"} value={"scrolling"} StyelOption={dataComang.ProductDisplayFormat} >
        {t("scrolling")}
        </InputRadio>
        <InputRadio dir={"6"} name={"ProductDisplayFormat"} value={"pagination"} StyelOption={dataComang.ProductDisplayFormat} >
        {t("pagination")}
        </InputRadio>
      
      
      
      </div>
    </form>
    </>

  )
}
