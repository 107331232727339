import React from 'react'
import "./style.css";
import { AiFillHome } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { BsGear } from "react-icons/bs";
import SelectInput from '../../lib/SelectInput/SelectInput';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { products_brands } from '../../services/redux/action/action';
  
export default function CatigortySession({categoryList,setcategoryId,setbrands,brands}) {
    const { t, i18n } = useTranslation();
    const [openCatigory,setopenCatigory] = useState(true)
    const [catigoryName,setcatigoryName] = useState("all")
  
let lang =i18n.language;
let productsBrands = useSelector((state) => state.products_brands);
 const dispatch = useDispatch();
useEffect(()=>{
  dispatch(products_brands());

},[])
const selectCatigory =(e) =>{
  if(e.target.value !== "all"){
    let dataName = categoryList.filter(item => item.id == e.target.value )
    setcatigoryName(dataName);
  }else if(e.target.value === "all"){
    setcatigoryName(e.target.value);

  }
 
  setcategoryId(e.target.value)
  setopenCatigory(true)
}
let optionBrand=[];
let defouldValue ='';
if (productsBrands && productsBrands.status) {
  productsBrands.data.brands.forEach((item) => {
    optionBrand.push({ value: item.id, label: item[`name_${lang}`] });
  });
  let valueDate =productsBrands.data.brands.filter(brandID => brandID.id === brands )
    defouldValue  =brands && { value: valueDate[0].id, label: valueDate[0][`name_${lang}`] }
    

}
 
 
  return (
  <>
  
      <nav className={`navbar   ${openCatigory ? "bg-nav" : "bg_nav_select"} `}>
        <div className="w-100">
 
            <button   className="navbar-brand m-auto pt-0" onClick={() => setopenCatigory(!openCatigory)} >
              <span className="icon-home m-auto">
                {" "}
                <AiFillHome />{" "}
              </span>
            </button>
            {
               !openCatigory && 
               <div className='p-2'> 
               <SelectInput  defouldValue={defouldValue} placeholder={t("Select the Brand")} option={optionBrand} getValue={setbrands} />

                </div>


            }
            { openCatigory ? <div className="name-text">{catigoryName === "all" ? t("All") : catigoryName[0][`category_${lang}_name`]  } </div>:
            <select onChange={(e) => selectCatigory(e)} className="form-select w-100     overflow-auto border-0 h-100" multiple  size="10"   aria-label="multiple select example">
              {
            categoryList && categoryList.map((item) => <option key={item.id} value={item.id}>{item[`category_${lang}_name`] }</option> )
            }

          </select>
            }
          </div>         
         <span    className=" py-3 setting_btn" data-bs-toggle="modal" data-bs-target="#customAlert">
         <BsGear size={35}/>
 
  </span>
         </nav>
  </>
  )
}
