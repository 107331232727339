import React from 'react'
 
export default function InputRadio({name,StyelOption,value,children,dir}) {
  return (
   <>
    <div className="form-check form-check-inline">
        <input className="form-check-input" dir={dir} type="radio" name={name} onChange={e => e} checked={StyelOption === value} id={value} value={value} />
        <label className="form-check-label" htmlFor={value}>
            {children}
        </label>
        </div>
   </>
  )
}
