import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Navbar from "./layouts/Navbar/Navbar"
import Footer from "./layouts/footer/footer"
import PrivateRoute from "./components/PrivateRoute/PrivateRoute"
import Session from "./pages/Session/Session"
import Login from "./pages/login/Login"
import HomePage from "./pages/HomePage/HomePage"
import AllSession from "./pages/AllSession/AllSession"
import Payment from './pages/Payment/payment'
import Validate from "./pages/validate/validate"
import DetailsSession from "./pages/DetailsSession/DetailsSession"
import Refund from "./pages/Refund/Refund"
import TabelSetting from "./pages/TabelSetting/TabelSetting"
import OrdersSession from "./pages/OrdersSession/OrdersSession"
import SessionPayments from "./pages/SessionPayments/SessionPayments"
import SelectUserToPay from './pages/SelectUserToPay/SelectUserToPay';
import OrderSuspension from './pages/OrderSuspension/OrderSuspension';
import ClosingSession from './components/ClosingSession/ClosingSession';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Notification from './features/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { ValueSearch } from './services/redux/action/action';
import CashInOut from './components/CashInOut/CashInOut';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import NewPassword from './pages/NewPassword/NewPassword';
import Prodects from './pages/Prodects/Prodects';
import AddNewProdect from './pages/AddNewProdect/AddNewProdect';
import AddCustomerMain from './pages/AddCustomerMain/AddCustomerMain';
import AddCustomerSection from './pages/AddCustomerSection/AddCustomerSection';

function App() {
  const { t, i18n } = useTranslation();
  const [styleLink, setStyleLink] = useState(null)
  const [userLogn, setuserLogn] = useState(null)
  const lang = i18n.language;
  const userRedsucer = useSelector(item => item.login)
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    setuserLogn(user)
  }, [userRedsucer])
  useEffect(() => {
    if (lang === 'ar') {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = '/Ar.css'
      document.head.appendChild(link)
      setStyleLink(link)
    } else {
      if (styleLink) {
        document.head.removeChild(styleLink)
        setStyleLink(null)
      }
    }
  }, [lang]);



  return (
    <>
      <div  >
        <BrowserRouter>
          {userLogn && userLogn.data.access_token && <> <Navbar />     </>}
          <Notification />
          <ClosingSession />
          <CashInOut />
          <Routes>
            <Route path="/Session" element={<PrivateRoute> <Session /></PrivateRoute>} />
            <Route index path="/" element={<PrivateRoute> <HomePage /></PrivateRoute>} />
            <Route index path="/AllSession" element={<PrivateRoute> <AllSession /></PrivateRoute>} />
            <Route path="/Payment" element={<PrivateRoute><Payment /></PrivateRoute>} />
            <Route path='/Validate' element={<PrivateRoute><Validate /></PrivateRoute>} />
            <Route path='/AddCustomerMain' element={<PrivateRoute><AddCustomerMain /></PrivateRoute>} />
            <Route path='/AddCustomerSection' element={<PrivateRoute><AddCustomerSection /></PrivateRoute>} />
            <Route path='/Sessions/:id' element={<PrivateRoute><DetailsSession /></PrivateRoute>} />
            <Route path='/Refund' element={<PrivateRoute><Refund /></PrivateRoute>} />
            <Route path='/Prodects' element={<PrivateRoute><Prodects /></PrivateRoute>} />
            <Route path='/SelectUserToPay' element={<PrivateRoute><SelectUserToPay /></PrivateRoute>} />
            <Route path='/AddNewProdect' element={<PrivateRoute><AddNewProdect /></PrivateRoute>} />
            <Route path='/TabelSetting' element={<PrivateRoute><TabelSetting /></PrivateRoute>} />
            <Route path='/OrdersSession/:id' element={<PrivateRoute><OrdersSession /></PrivateRoute>} />
            <Route path='/SessionPayments/:id' element={<PrivateRoute><SessionPayments /></PrivateRoute>} />
            <Route path='/OrderSuspension' element={<PrivateRoute><OrderSuspension /></PrivateRoute>} />
            <Route path='/Login' element={<Login />} />
            <Route path='/NewPassword' element={<NewPassword />} />
            <Route path='/ForgetPassword' element={<ForgetPassword />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>

      </div>

    </>
  );
}

export default App;
