import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListprodectByBusinessId } from "../../services/redux/action/action";
import i18next, { t } from "i18next";

import { MdDelete } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import Select from "react-select";

function CloneForm({forms,setForms}) {
   const dispatch = useDispatch();
  const lang = i18next.language;

  useEffect(() => {
    dispatch(ListprodectByBusinessId({ simple_list: 1 }));
  }, []);
  const addForm = () => {
    setForms([...forms, { id: "", qty: "" }]);
  };
  const removeAddForm = () => {
    forms.splice(forms.length - 1, 1);
    setForms([...forms]);
  };
 
  const handleChange = (index, event, name) => {
 
    const newForms = [...forms];
    newForms[index][name ? name : event.target.name ] = name ?   event.value: event.target.value;
    setForms(newForms);
  };
  const prodects = useSelector((state) => state.ListprodectByCatigory);
 
  let optinalProdect = [];
  if (prodects && prodects.status) {
    prodects.data.products_list.forEach((element) => {
      optinalProdect.push({
        value: element.id,
        label: element[`product_${lang}_name`],
      });
    });
  }
  return (
    <div>
     
     
 
      {forms.map((form, index) => (
        <div key={index} className="row">
          <div className="col-6">
            <Select
              className="basic-single"
              classNamePrefix={"select"}
              isSearchable={true}
              options={optinalProdect}
              name="id"
              
              isClearable={false}
              placeholder={t("Select Product")}
               onChange={(data) => {data &&  handleChange(index,   data, "id"); }}
            />
          </div>
          <div className="col-6 d-flex align-items-start  ">
            <div className="mb-3">
              <input
                name="qty"
                onChange={(event) => handleChange(index, event)}
                type="number"
                value={forms[index].qty}
                className="form-control"
                placeholder={t("Enter qty")}
              />
            </div>
             {forms.length - 1 == index  && forms.length > 1 &&(
              <button
                className="btn red_btn mx-1 p-0 px-1"
                onClick={() => removeAddForm(index)}
              >
                {" "}
                <MdDelete size={25} />
              </button>
            )}
            { index == 0 &&
                  <button type="button" className="dark_btn btn fs-6  py-1  mx-1 " onClick={addForm}>
                  <AiOutlinePlus size={22}/>
                </button>
            }
          </div>
        </div>
      ))}
    </div>
  );
}
export default CloneForm;
