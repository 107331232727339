import React from 'react'
 
export default function CustomAlert({openref,closeRef,children}) {
  return (
 <>
     <span  ref={openref}  className=" d-none" data-bs-toggle="modal" data-bs-target="#customAlert">
      
 
  </span>
<div className="modal fade" id="customAlert" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
         <button type="button" ref={closeRef} className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body text-center fs-4 py-4">
      {children}
      </div>
    </div>
  </div>
</div>
</>
  )
}
