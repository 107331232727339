import React from "react";
import "./style.css"
const DetialsTabel = ({ContentTabel}) =>{
    return(
        <>
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" >
  <div className="offcanvas-header">
    <h5 className="offcanvas-title" id="offcanvasExampleLabel">Detailes Tabel</h5>
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
    <div className="div_body">
     <div className="row">
     {
        ContentTabel && <div className="row fs-5">
            <div  >
            tabel number: {ContentTabel.tabel_number}
            </div>
            <hr className="my-2" />
            <div   >
              Count Peopel: {ContentTabel.Count_Peopel}
            </div>
            <hr className="my-2" />
            <div    >
              state: busy
            </div>
            <hr className="my-2" />
        </div>
     }
    </div>
   
    </div>
    <div className=" d-flex    align-items-center justify-content-between ">
     <button data-bs-dismiss="offcanvas" aria-label="Close" className="btn  w-50 m-2  dark_btn" >close</button>
     <button  data-bs-dismiss="modal" aria-label="Close" className="btn w-50  m-2   red_btn" >follow</button>
   
    </div>
  </div>
</div>
        </>
    )
}
export default DetialsTabel;