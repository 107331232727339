import { t, use } from 'i18next'
import React from 'react'
import "./style.css"
import { BiSearchAlt2 } from "react-icons/bi"
import { useDispatch, useSelector } from 'react-redux'
import { ValueSearch } from '../../services/redux/action/action'
import { useState } from 'react'
import { useEffect } from 'react'

export default function InputSearsh() {
const dispatch = useDispatch()
const [valueInput,setvalueInput] = useState('');
const locationPage = window.location.pathname;

 useEffect(()=>{
  setvalueInput('')
  dispatch({type:"ValueSearch",ValueSearch:""})
  
 },[locationPage])
 
 const onHendling =(e) =>{
  
   e.preventDefault() 
   dispatch(ValueSearch(e.target.value))
   
  }
  
   return (
    <div className="mx-2">      <form onSubmit={(e) =>onHendling(e)} className="d-flex     justify-content-center" role="search">
      <input value={valueInput}  onChange={(e) =>{ setvalueInput(e.target.value);onHendling(e)}} className=" input-search w-75 form-control me-2 w-sm-75 w-md-100" type="search" placeholder={`${t("Search")}...`} aria-label="Search" />
      <button className="button-search btn btn-outline-success" type="submit"><BiSearchAlt2 /></button>
   </form> 
    </div>
  )
}
