import React from 'react'
import { AiOutlineFullscreen,AiOutlineFullscreenExit } from "react-icons/ai"

export default function BtnFullscreen({full_screen}) {
   return (
    <>
       <div className="w-25 m-auto text-center my-2">
      {  !full_screen ? <button className="btn btn_full_screen" onClick={() => document.documentElement.requestFullscreen()}>    <span className="open_icon"> <AiOutlineFullscreen size={25}/></span></button>
              :<button className="btn btn_full_screen" onClick={() => document.exitFullscreen()}>    <span className="close_icon">  <AiOutlineFullscreenExit size={25} /> </span></button>
}
      </div> 
    </>
  )
}
