import React from 'react'
import "./style.css"
export default function Shapes_div_input({Value_Tabels_Number,Value_people_Number,copy,onAddRemoveClass}) {

  return (
    <>
         <div className="shapes_div_input">
                <input
                    className="w-75 m-1 rounded-pill   input_numberTabel form-control p-0"
                    placeholder="tabels"
                    type="number"
                    name="numberTabel"
                
                    onChange={ (e) => {Value_Tabels_Number(e.target.value,copy.props.id);onAddRemoveClass(e)}}
                  />
                    <input
                    className="w-75 m-1  rounded-pill border-0 input_numberPeopel form-control p-0 "
                 
                    type="number"
                    placeholder="People"
                    name="numberPeopel"
                    onChange={ (e) => {Value_people_Number(e.target.value,copy.props.id);onAddRemoveClass(e)}}
                  />
                </div>
    </>
  )
}
