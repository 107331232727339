import React from 'react'

export default function InputCalc({changeHandler,nameInput,LabelNAme}) {
  const user = JSON.parse(sessionStorage.getItem("user"))

  return (
 <>
   <div className=" d-flex my-1     justify-content-center">
      <input dirname={LabelNAme} onChange={changeHandler} type="number" name={nameInput} placeholder='0' className='form-control input_total_price  '  id="" />
        <div className='w-25  d-flex align-items-center justify-content-center lh-1 '>  {LabelNAme} {user && user.data.currency_symbol}</div>
     </div>
 </>
  )
}
