import React, {   useState } from "react";
import "./style.css";
import { ImCalculator } from "react-icons/im";
import {  useNavigate } from "react-router-dom";
import InputCalcTotal from "../../components/InputCalCTotal/InputCalcTotal";
 import { useDispatch, useSelector } from "react-redux";
import { close_session } from "../../services/redux/action/action";
import { useRef } from "react";
import { t } from "i18next";
import SpinnerLoding from "../SpinnerLoding/SpinnerLoding";
import PrintSession from "../PrintSession/PrintSession";
import HeaderInPrint from "../HeaderInPrint/HeaderInPrint";
import FooterInPrint from "../FooterInPrint/FooterInPrint";
import { useEffect } from "react";

export default function ClosingSession() {
  const [showCalc, setShowCalc] = useState(false);
  const [notes, setnote] = useState("");
  const [TotalSession, setTotalSession] = useState('');
   const [detailsTotal , setdetailsTotal] =useState([])
  const [dataListsTotal,setDataListTotal]=useState([])
  const refCloth = useRef()
 const Navigate =useNavigate()
 const desiptch =useDispatch();
 const clothSesson =useRef()

 
const user = JSON.parse(sessionStorage.getItem("user"))
   const currentTotal = (total)=>{
      return (Number((total * -1)) + Number(TotalSession)).toFixed(2)
     
   }
   
    let sessionLocal =useSelector(state => state.open_session)
      let session =(sessionLocal && sessionLocal.status) ? sessionLocal.data.pos_session: JSON.parse(sessionStorage.getItem("open_session"))
 
       let getSessionTotals =useSelector(state => state.get_session_totals)
    const hendelClothSession = () =>{ 
    let data ={
      session_id:session.id,
      diffrence:(Number(getSessionTotals.data.session_totals.cash_total    ) * -1) + Number(TotalSession) ,
      closing_note:notes,
      closing_cash_currency_cat:dataListsTotal
    }
      desiptch(close_session(data))
     }
 
    let closeSession = useSelector(state => state.close_session)
    
    useEffect(()=>{
        if(closeSession && closeSession.status){
              sessionStorage.removeItem("open_session")
              desiptch({type:"nearest_pos",nearest_pos:''})
              desiptch({type:"open_session",open_session:''})
              sessionStorage.removeItem("nearestpos")
              desiptch({type:"close_session",close_session:''})
              sessionStorage.removeItem("invoice_local");
              sessionStorage.removeItem("items")
            sessionStorage.removeItem("customer")
            desiptch({
                  type: "add_sales_invoice",
                  add_sales_invoice: "" 
                });
              clothSesson.current.click()
              Navigate("/");

            }
    },[closeSession])
    const KeepSessionOpen =() =>{
      Navigate("/");

      sessionStorage.removeItem("open_session")
      desiptch({type:"open_session",open_session:''})
      sessionStorage.removeItem("nearestpos")
      desiptch({type:"nearest_pos",nearest_pos:''})
      
      desiptch({type:"close_session",close_session:''})
     
    }
    const componentRef = useRef()
  return (
    <>
      <section className="ClosingSession">
        <div
          className="modal fade position-relative p-0"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
     
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-lg  ">
            <div className="modal-content "> 
              
              <div className="modal-header">
         <PrintSession componentRef={componentRef}/>

                <h5 className="modal-title m-auto" id="staticBackdropLabel">
              
                {t("ClosingSession")}
                </h5>
              
              </div>
              
              {showCalc && <InputCalcTotal dataListsTotal={dataListsTotal} setDataListTotal={setDataListTotal} setShowCalc={setShowCalc} setTotalSession={setTotalSession} detailsTotal={detailsTotal} setdetailsTotal={setdetailsTotal}/>}
              { getSessionTotals && getSessionTotals.status ?<>
              <div  ref={componentRef} className="modal-body px-2 px-sm-5 py-3     lh-base">
              <div className=' header_print_block'>
           <HeaderInPrint titel={"ClosingSession"}/>
            </div>
                <div className="">
                  <div className="row">
                   <div className="col-12 col-sm-6"> 
                   <div className="row">
                      <div className="col-6">{t("TotalOrders")}</div>
                      <div className="col-6"> {(getSessionTotals.data.session_totals.orders_total).toFixed(2)}</div>
                      <div className="col-6">{t("Payments")}</div>
                      <div className="col-6"> {getSessionTotals.data.session_totals.payment_total.toFixed(2)}</div>
                      <div className="col-6">{t("Customer_Account")}</div>
                      <div className="col-6"> {getSessionTotals.data.session_totals.customer_account_total.toFixed(2)}</div>
                   </div>
                   </div>
                   <div className="col-sm-6 col-12"></div>
                   <div className="col-md-7 col-12 mt-3">
                
                    
                    <div className="row">
                      <div  className="col-8">{t("paymntMithod")}</div>
                      <div className="col-4">{t("ExpectedCounted")}</div>
                      </div>
                      <div className="row">
                          <div className="col-8">{t("cash")} </div>
                           <div className="col-4">{getSessionTotals.data.session_totals.cash_total.toFixed(2)} </div>
                      </div>
                      <div className="row">
                          <div className="col-8">{t("Opening")} </div>
                           <div className="col-4">{getSessionTotals.data.session_totals.opening.toFixed(2)} </div>
                      </div>
                   
                      <div className="row">
                          <div className="col-8">{t("Bank")} </div>
                           <div className="col-4">{getSessionTotals.data.session_totals.visa_total.toFixed(2)} </div>
                      </div>
                      <div className="row">
                          <div className="col-8">{t("Customer_Account")} </div>
                           <div className="col-4">{getSessionTotals.data.session_totals.customer_account_total.toFixed(2)} </div>
                      </div>
                      
                   
                      
                   
                   </div>
                   <div className="col-md-5 col-12 mt-3">
                    <div>{t("Difference")}</div>
                    <div className="d-flex  flex-column flex-lg-row    align-items-center">
                      <div className="d-flex   ">
                         <input
                          onChange={(event) =>
                            setTotalSession(event.target.value)
                          }
                          value={TotalSession}
                          type="number"
                          name=""
                          placeholder="0"
                          className="form-control input_total_price form-control-lg my-2"
                          id=""
                        />
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            setShowCalc(true);
                           
                          }}
                          className="mx-3 calc_icon  my-2"
                        >
                        
                          <ImCalculator />
                        </button>
                      </div>
                      
                        <div>{currentTotal(Number(getSessionTotals && getSessionTotals.data.session_totals.cash_total))} {user && user.data.currency_symbol} </div>
                      </div>
                   </div>

                  </div>
                    <div className="">
                      <textarea
                        className="form-control "
                        onChange={(event) => setnote(event.target.value)}
                        placeholder="Notes..."
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
               
                </div>
                <div className='footer_print_block'>
               <br />
            <FooterInPrint/>
            </div>
              </div>
              <div className="modal-footer">
                 <div className="pb-3 text-center">
                    <button  disabled={!TotalSession}   onClick={hendelClothSession}   type="submit" className="btn red_btn m-1"  >

                        {t("ClosingSession")}
                      </button>
                   
                      <button  onClick={KeepSessionOpen}  type="submit"  className="btn dark_btn m-1" data-bs-dismiss="modal" aria-label="Close">
                       
                   {t("keep_Session_Open")}
                      </button>
                      <button   type="submit" ref={clothSesson}  className="btn dark_btn m-1 d-none" data-bs-dismiss="modal" aria-label="Close">
                       
                       {t("keep_Session_Open")}
                          </button>
                      <button type="button" ref={refCloth}  className="btn red_btn m-1" data-bs-dismiss="modal" aria-label="Close">
                         
                      {t("Continuity_selling")}
                      </button>
                    </div>
              </div>
              </>:<SpinnerLoding/>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
