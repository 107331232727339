 import React from 'react'
 import { GrDocumentPdf } from "react-icons/gr";
 import ReactToPrint from 'react-to-print';
export default function PrintSession({componentRef}) {
  return (
    <>
  <ReactToPrint
        trigger={() => <GrDocumentPdf size={25}/>}
        content={() => componentRef.current}
      />
          </>
  )
}
