import React, { useEffect } from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css'; // Import the CSS file for default styles
import PromptCustomNotification from './PromptCustomNotification';
import "./style.css"
export default function NotificationMAsseg() {
  const [masseg,setMassge] = useState('')
  const OPenMAssg =useRef()
    const data = [
  
    {name:"test14",id:4}

    ]
    function handleAction(data) {
        NotificationManager.info( 
        <p>{data}</p>
        , 'Title', 1000000, () => {
          setMassge(data)
            OPenMAssg.current.click();
        });
      }
      let ignore = true;
     useEffect(() => {
      
      if(ignore){
           data.forEach(element => {
        handleAction(element.name)
    });
      }
   
  return () => { ignore = false }
        
     }, [])
  return (
    <>
    <div>
      
    <button type="button" className=" d-none " ref={OPenMAssg} data-bs-toggle="modal" data-bs-target="#OPenMAssg">
       </button>
    
    {/* <NotificationContainer /> */}
    
    <PromptCustomNotification data={masseg}/>
   </div>
   </>
  )
}
