import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import VODOLOGO from "../../asssets/images/VODOLOGO.png";
import InputLogin from "../../components/InputLogin/InputLogin";
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset_password } from '../../services/redux/action/action';
export default function ForgetPassword() {
    const [email,setEmail]= useState('')
    const [massege,setmassege]= useState('')
    const path = window.location.origin
   const navigate = useNavigate();
   const dispatch =useDispatch()
    const submituser =(e)=>{
      e.preventDefault()
      email && sessionStorage.setItem("email_forgect_pass",JSON.stringify(email.target.value));
      dispatch(reset_password({email:email.target.value}))
    }

    
let resetPassword = useSelector(state => state.reset_password)
   useEffect(()=>{
  if(resetPassword && resetPassword.status){
    navigate("/NewPassword")
  }else if(resetPassword && !resetPassword.status){
    setmassege(resetPassword.data)
    setTimeout(()=>{
      setmassege('')
    },4000)
  }
 
  },[resetPassword])

  return (
    <>
    <Helmet>
    <title>  Forget Password</title>
    <link rel="canonical" href={path} />
    <meta
      name="description"
      content="Log in to the system with your own account"
    />
  </Helmet>
  <h1 className="visually-hidden">Log in to the system with your own account</h1>
  <div className="login-page d-flex flex-column justify-content-center align-items-center">
  <div className="container text-center ">
  <img width={200} className=" mt-5" src={VODOLOGO} alt="" />
    
  </div>
    <div className="container form-login mt-5">
        
        <form onSubmit={submituser} className="w-50 m-auto">

        {massege && <div test-id="masseg_erorr" className="alert alert-danger">{ massege}</div>}
            <InputLogin value={email && email.target.value}  getvslueform={setEmail}  type={"email"} placeholder={t("Enter your Email")}/>
            <div className='text-center'>
            <button disabled={!email}  className="btn dark_btn mt-3 w-50 " type="submit" >{t("Sent")}</button>

            </div>
            

        </form>
    </div>
  </div>
  </>
  )
}
