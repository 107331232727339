import React from 'react'
import { AddCart } from '../services/redux/action/action';
import { useDispatch } from 'react-redux';

export default function AddCartHook() {
    const dispatch = useDispatch();
    const user = JSON.parse(sessionStorage.getItem("user"));

    const addCartfun = (setactiveid, setTextVal, setcartid, items, setChange, setCount, setitemActive) => {
        const localcard = JSON.parse(sessionStorage.getItem("items")); //get itme
        setactiveid(items.id); // to active
        setTextVal("Qty"); // to activ qty
        setitemActive(items)

        let refuntCheck =
            localcard && localcard.length > 0 && localcard[0]["final_price"] < 0
                ? false
                : true;

        if (refuntCheck) {

            if (localcard) {
                if (localcard.find((product) => product.id === items.id)) {
                    setcartid(items.id);
                    const index = localcard.findIndex((item) => item.id === items.id);
                    if (
                        localcard[index]["final_price"] > 0 &&
                        ((user.data.pos_stock_permission === 1 &&
                            localcard[index].stock > localcard[index]["order"]) ||
                            user.data.pos_stock_permission === 0)
                    ) {
                        localcard[index]["order"] += 1;
                        dispatch(AddCart(localcard));
                        document.querySelector(".scanner_sound")?.play();
                    }
                } else {
                    dispatch(AddCart([...localcard, items]));
                    document.querySelector(".scanner_sound")?.play();
                }
            } else {
                dispatch(AddCart([items]));
                document.querySelector(".scanner_sound")?.play();
            }
        }
        setChange(false);
        setCount(0);
    }
    return [addCartfun]
}
