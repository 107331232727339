import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom'
import { ValueSearch } from '../../services/redux/action/action';
import "./style.css"
export default function NavComp() {
  const { t, i18n } = useTranslation();
  let lang = i18n.language
const [valueInput,setvalueInput] = useState('');
 const  dispatch= useDispatch()
  const hendeling =(e)=>{
    let text = e.target.text
   e.target.text = lang === "en" ? "Unauthorized":"غير مصرح"
   setTimeout(() =>{
    e.target.text = text
   },4000)
  }
  const onHendling =(e) =>{
    e.preventDefault() 
    dispatch(ValueSearch(e.target.value))
   }
   let path = window.location.pathname  
  return (
    
  <>
  <nav className='NavComp'>

   
 <ul className="nav NavComp justify-content-between  align-items-center container-xxl flex-wrap">
  
    <li className="nav-item">
      <NavLink to={"/"}>
          <h3 className='p-2'>{t("PointOfSale")}</h3>
      </NavLink>
    </li>
    <div className='d-flex nav-item  flex-wrap' >

    <li >
    <Link to={"/"} className={`nav-link ${path === "/" && "active"}`} aria-current="page" href="#">{t("Dashboard")} </Link>
  </li>
  <li onClick={hendeling} className='Not_available'>
    <Link className="nav-link"  >{t("Orders")}</Link>
  </li>
  <li  >
    <NavLink to={"/Prodects"} className="nav-link"  >{t("Products")}</NavLink>
  </li>
  <li onClick={hendeling} className="Not_available ">
    <Link className="nav-link"  >{t("Reporting")}</Link>
  </li>
  <li onClick={hendeling} className=" Not_available">
    <Link className="nav-link" >{t("Configuration")}</Link>
  </li>
 
    </div>

  <li className="nav-item">
  
    <input type="number" value={valueInput} placeholder={`${t("Search")} ...`} onChange={(e)=>{onHendling(e);setvalueInput(e.target.value)}} className='form-control w-100  rounded-pill' />
  
  </li>

</ul> 

</nav>
</>
  )
}
