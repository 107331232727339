import { t } from "i18next";
import React, { useState } from "react";
 import "./style.css"
export default function WithOut({ setWithOut }) {
  const [checked, setChecked] = useState([]);
   const checkList = ["Apple", "Banana", "Tea", "Coffee"];
  const hendalSubmit = (event) => {
    event.preventDefault()
    setWithOut(checked)
    setChecked('')
  
  };
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  }
  return (
    <>
      <div
        className="modal fade"
        id="WithOut"
        tabIndex="-1"
        aria-labelledby="WithOutLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
          <form className="fs-5" onSubmit={(event) => hendalSubmit(event)}>
            <div className="text-center">
              <h4 className="modal-title " id="NotesCompLabel">
                {t("Without")}
              </h4>
            </div>
            <div className="modal-body">
              
                <div className="row">
                  {
                    checkList.map((item, index) => (
                            <div key={index} className="col-12 col-sm-4  my-2">
                                  <div className="form-check ">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={item}
                                value={item}
                                checked={checked.includes(item)}
                                onChange={handleCheck} 
                              />
                              <label className="form-check-label" htmlFor={item}>
                               {item}
                              </label>
                            </div>
                           </div>

                    ))
                  }
                 
                 
                </div>
              
              
              
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {t("Discard")}
              </button>
              <button
                type="submit"
                className="btn py-1 red_btn"
                 
                data-bs-dismiss="modal"
              >
                {t("Confirm")}
              </button>
            </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
