import React, { useState } from "react";
import "./style.css"
 
import { useTranslation } from "react-i18next";
 
 import CartItemAsidePage from "../../components/CardItemAsidPage/CartItemAsidePage";
import { TotalHook } from "../../hooks/TotalHook";
 
function AsideOrderSuspension({ prodect,onCompletOrder}) {
const [active, setActive] = useState(null);
const user = JSON.parse(sessionStorage.getItem("user"))
 
 const { t, i18n } = useTranslation();
const [total,tax,extraTotal] = TotalHook(prodect)
   return (
    <>
  
      <div className="all-aside" >
        <div className="items-price asideOrderSuspension">
          <div className="list-group">
            
            {
              prodect? prodect.map((item) =>{
                return(
                  
                  <CartItemAsidePage   key={item.id}  {...item }   isActive={active === item.id  } />

                )
              }): null
            }
          </div>
        </div>
    
        <div className="total-price ">
          <div className="d-flex  text-center p-1 justify-content-around">
            <div >
              <p>{t("point")}</p>
              <p className=" text-danger">0</p>

            </div>
            <div>
              <p>{t("taxes")}</p>
              <p>{tax.toFixed(2)} {user && user.data.currency_symbol}</p>
            </div>
            <div >
            <p >{t("total")}</p>
            <p>{(total + extraTotal).toFixed(2)}</p>
          </div>
          </div>
         
        </div>
        <div>
          <button onClick={() => onCompletOrder(prodect)} className="red_btn w-100 fs-4 my-2 p-2">{t("Order completion")}</button>
         </div>
      </div>

    </>
  );
}

export default AsideOrderSuspension;





  