import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NavComp from "../../layouts/NavComp/NavComp";
import "./style.css";

import PaginateComp from "../../components/PaginateComp/PaginateComp";
import FiGrFavComp from "../../components/FiGrFavComp/FiGrFavComp";
import { useDispatch, useSelector } from "react-redux";
import { session_payment_list } from "../../services/redux/action/action";
import SpinnerLoding from "../../components/SpinnerLoding/SpinnerLoding";
import PrintSession from "../../components/PrintSession/PrintSession";
import FooterInPrint from "../../components/FooterInPrint/FooterInPrint";
import HeaderInPrint from "../../components/HeaderInPrint/HeaderInPrint";
import { t } from "i18next";

export default function SessionPayments() {
  const despitch = useDispatch();
  let param = useParams();
  const [Idpos, setidpos] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const [page, setpage] = useState(1);

  useEffect(() => {
    if (param.id) {
      despitch(session_payment_list(param.id, page));
    }
  }, [param]);
  let sessionPaymentList = useSelector((state) => state.session_payment_list);
  const componentRef = useRef();
  return (
    <>
      <section className="SessionPayments">
        <NavComp />
        <div className="container-xxl">
          <div className="d-sm-flex mt-3    justify-content-between">
            <nav aria-label="breadcrumb ">
              <ol className="breadcrumb fs-4">
                <li className="breadcrumb-item ">
                  <Link to={"/"}>{t("PointOfSale")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/AllSession"}>{t("Sessions")} </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/Sessions/${param.id}`}>pos/{param.id}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t("Payments")}
                </li>
              </ol>
            </nav>
            <div className="d-flex justify-content-end">
              {sessionPaymentList &&
                sessionPaymentList.status &&
                sessionPaymentList.data.pages_number && (
                  <PaginateComp
                    setnumberPage={setpage}
                    pageCounts={sessionPaymentList.data.pages_number}
                  />
                )}
            </div>
          </div>

          <FiGrFavComp
            setReset={setidpos}
            setstartDate={setstartDate}
            setendDate={setendDate}
          />
          <PrintSession componentRef={componentRef} />

          {sessionPaymentList && sessionPaymentList.status ? (
            <div ref={componentRef} className="shadow p-2     overflow-auto">
              <table className="table none_print   table-hover  fs-5 mb-0 ">
                <thead>
                  <tr className="hide_print">
                    <th>{t("Date")}</th>
                    <th> {t("PaymentMethod")} </th>
                    <th> {t("Order")} </th>
                    <th> {t("total")} </th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>

              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item border-0">
                  <button
                    className="accordion-button collapsed p-1 fs-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    {t("cash")}
                  </button>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <table className="table   table-hover  fs-5 ">
                      <thead>
                        <tr className="header_print">
                          <td colSpan={7}>
                            <HeaderInPrint titel={"Payments"} />
                          </td>
                        </tr>
                        <tr className="header_print">
                          <th>{t("Date")}</th>
                          <th> {t("PaymentMethod")} </th>
                          <th> {t("Order")} </th>
                          <th> {t("total")} </th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {sessionPaymentList.data &&
                        sessionPaymentList.data.cash_payments.length > 0 ? (
                          sessionPaymentList.data.cash_payments.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>{item.date}</td>
                                <td>{item.transfer} </td>
                                <td> {item.order} </td>
                                <td> {item.total} </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={4} className={"text-center"}>
                              {t("no_data")}
                            </td>{" "}
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr className="footer_print">
                          <td colSpan={7}>
                            <FooterInPrint />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <hr />
                <div className="accordion-item border-0">
                  <button
                    className="accordion-button collapsed  p-1 fs-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseThree"
                  >
                    {t("Bank")}
                  </button>

                  <div
                    id="panelsStayOpen-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <table className="table   table-hover  fs-5 ">
                      <thead>
                        <tr className="header_print">
                          <td colSpan={7}>
                            <HeaderInPrint titel={"Payments"} />
                          </td>
                        </tr>
                        <tr className="header_print">
                          <th>{t("Date")}</th>
                          <th> {t("PaymentMethod")} </th>
                          <th> {t("Order")} </th>
                          <th> {t("total")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessionPaymentList.data &&
                        sessionPaymentList.data.visa_payments.length > 0 ? (
                          sessionPaymentList.data.visa_payments.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>{item.date}</td>
                                <td>{item.transfer} </td>
                                <td> {item.order} </td>
                                <td> {item.total} </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={4} className={"text-center"}>
                              {t("no_data")}
                            </td>{" "}
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr className="footer_print">
                          <td colSpan={7}>
                            <FooterInPrint />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <SpinnerLoding />
          )}
        </div>
      </section>
    </>
  );
}
