import React from 'react'
import "./style.css"
import { Bars } from  'react-loader-spinner'

export default function SpinnerLoding() {
  return (
    <div className='spinner_container'>
  <Bars
  color="#eb3d34"
  width="30%"
  height="40%"
  visible={true}
  ariaLabel='falling-lines-loading'
/>
    </div>
  )
}
