import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCart } from "../../services/redux/action/action";
import { useTranslation } from "react-i18next";
function AsideRefund({massge,counts,prodect,setActive,active,setCount}) {

const [number ,setnumber] =useState(1);
const { t, i18n } = useTranslation();
const lang = i18n.language;
useEffect(()=>{
  setnumber(counts)
},[counts])


 
   const Carts = ({ id, product_ar_name,product_en_name ,final_price,order, isActive ,order_refund,refund_amount}) => {
    return (
    
           <div  key={id} href="#" onClick={(e) => {navigate(id) ; handleClick(e,id)}} className={isActive ? "active  list-group-item list-group-item-action" : " list-group-item list-group-item-action"} 
           aria-current="true" >
           <div className="item d-flex justify-content-between ">
             <div className="lh-1">
               <h5>{lang == "ar" ? product_ar_name: product_en_name}</h5>
               <div className="order-price my-2 d-flex"  ><div> { t("order") } { order - refund_amount}   </div>  / <div>  { t("price") }  {(final_price).toFixed(2)} </div> </div>
               <h6>{refund_amount >  0 &&<span>{("refund")} : {refund_amount}</span>}</h6>
              
               {order_refund && <h6 className={order_refund > order ? "text-danger":"text-success"}> -{order_refund}</h6>}
             </div>
             <h4 > {(Number(order) * Number(final_price)).toFixed(2)}$</h4>
           
           </div>  
           </div>
    );
  };
  const [details, setDetails] = useState(null);
 
const dispatch = useDispatch()
 
useEffect(()=>{
  if(document.querySelector(".list-group-item.active")){
    let myElement = document.querySelector(".list-group-item.active");
    var topPos = myElement.offsetTop;
    document.querySelector(".items-price").scrollTop = topPos

  }
})
// filter item and set item and rest calc
  const navigate = (id) => {
  setActive(id);
  const filter = prodect.filter(employee => {
    return employee.id === id;
  });
  setDetails(filter);
  const  index = prodect.findIndex(item => item.id == id) 
  let number = prodect[index]["order_refund"] ? prodect[index]["order_refund"]:0
  setCount(number)
 
};
// /////////////////////////   add order number ti=o refund
useEffect(()=>{
  if(prodect && details != null){
const  index = prodect.findIndex(item => item.id == details[0].id) ;
if(details && index != -1  && counts >= 1 &&  (prodect[index]["order"] - Number(prodect[index]["refund_amount"]) ) >= counts ){
     prodect[index]["order_refund"] = counts
 }else if (details && index != -1  && counts === 0){
  delete  prodect[index]["order_refund"]
 }
}
dispatch(AddCart(prodect))
 
},[counts])
  
let total = 0;
let tax =0;

 
  if(prodect){
    prodect.map((item) => {
     total += (item.order - item.refund_amount)* item.final_price
   
      tax += (item.order- item.refund_amount) * item.tax_amount
    })
   }
 
const handleClick = (e,id) => {
  if(e.detail == 2){
     let filtered = prodect.filter((item) =>{return item.id != id})
      sessionStorage.setItem("items",JSON.stringify(filtered))
  }

};
 
  return (
    <>
      <div className="all-aside" >
      <h6 className="p-1 text-center text-danger">{massge}</h6>

        <div className="items-price">

          <div className="list-group">
            {
              prodect? prodect.map((item) =>{
                return(
                  
                 <Carts key={item.id}   {...item }   isActive={active === item.id  }  />
                
                )
              }): null
            }
          </div>
        </div>
        <div className="total-price ">
          <div className="d-flex  text-center p-1 justify-content-around">
            <div >
              <p>{t("point")}</p>
              <p className=" text-danger">0</p>
  
            </div>
            <div>
              <p>{t("taxes")}</p>
              <p>{tax}$</p>
            </div>
            <div >
            <p >{t("total")}</p>
            <p>{total.toFixed(2)}</p>
          </div>
          </div>
         
        </div>
      </div>

    </>
  );
}

export default AsideRefund;





  