import { t } from "i18next";
import React from "react";
import ItemNotifcation from "../../components/itemNotifcationPage/itemNotifcationPage";
import "./style.css"
export default function PageNotification() {
  return (
    <>
      <div
        className="modal fade modal-xl modal-dialog modal-dialog-scrollable     overflow-hidden PageNotification"
        id="PageNotification"
        // data-bs-backdrop="static"
        // data-bs-keyboard="false"
        // tabIndex="-1"
        aria-labelledby="PageNotification"
        // aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header flex-column">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <h4 className="modal-title text-center" id="staticBackdropLabel">
                {t("Notification")}
              </h4>
            </div>

            <div className="modal-body ">
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
            <ItemNotifcation/>
           </div>
          </div>
        </div>
      </div>
    </>
  );
}
