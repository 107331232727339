import { t } from "i18next";
import React from "react";
import "./style.css";
import { FaUserCircle } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function DropdownUser({ logout, userLogn, OpenSession }) {
  const navgite = useNavigate();

  return (
    <>
      {OpenSession ? (
        <div className="d-flex     align-items-center">
          <FaUserCircle size={30} />
          <h5 className="px-1 m-0 ">
            {userLogn && userLogn.data.cash_account_name}
          </h5>
        </div>
      ) : (
        <div className=" dropdown DropdownUser text-center">
          <button
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FaUserCircle /> {userLogn && userLogn.data.cash_account_name}
          </button>
          <ul className="dropdown-menu p-0 text-center  fs-5">
            {userLogn && Number(userLogn.data.pos_add_customer) === 1 && (
              <>
                <hr className="m-0 mx-2" />
                <li
                  onClick={() => navgite("/AddCustomerMain")}
                  className="dropdown-item  py-2  "
                >
                  {t("Add Customer")}{" "}
                  <span className="icon_red">
                    {" "}
                    <AiOutlineUsergroupAdd />
                  </span>
                </li>
              </>
            )}
            {userLogn && Number(userLogn.data.pos_add_product) === 1 && (
              <>
                <hr className="m-0 mx-2" />
                <li
                  onClick={() => navgite("/AddNewProdect")}
                  className="dropdown-item  py-2  "
                >
                  {t("Add product")}{" "}
                  <span className="icon_red">
                    {" "}
                    <AiOutlineUsergroupAdd />
                  </span>
                </li>
              </>
            )}
            <hr className="m-0 mx-2" />
            <li onClick={logout} className="dropdown-item  py-2  ">
              {t("logout")}{" "}
              <span className="icon_red">
                {" "}
                <FiLogOut />
              </span>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}
