import React, { useEffect } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiTwotonePrinter } from "react-icons/ai";
import { MdEmail } from "react-icons/md";

import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

import { t } from "i18next";
import { VODOGLOBAL_ACOUNTING_API } from "../../services/redux/action/action";

function Validate() {
  const [full_screen_mode, setFull_screen_mode] = useState();
  const invoice_local = JSON.parse(sessionStorage.getItem("invoice_local"));
  const customer = JSON.parse(sessionStorage.getItem("customer"));
  const user = JSON.parse(sessionStorage.getItem("user"));
  let id = invoice_local.creatinv.data.invoice_id
    ? invoice_local.creatinv.data.invoice_id
    : invoice_local.creatinv.data.id;
  useEffect(() => {
    let OpenSession = JSON.parse(sessionStorage.getItem("open_session"));
    if (!OpenSession) {
      navigate("/");
    }
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let value_full_screen = sessionStorage.getItem("Full_screen_Boolean");
    setFull_screen_mode(value_full_screen);
  }, []);
  const removeandrelod = () => {
    sessionStorage.removeItem("invoice_local");
    sessionStorage.removeItem("items");
    sessionStorage.removeItem("customer");
    sessionStorage.removeItem("Refund");
    navigate("/Session");
    dispatch({
      type: "add_sales_invoice",
      add_sales_invoice: "",
    });
    dispatch({
      type: "create_credit_note_refund",
      create_credit_note_refund: "",
    });
  };

  const path = window.location.origin;

  const handlePrint = (id) => {
    const url = `${VODOGLOBAL_ACOUNTING_API}/sales_invoice/getInvoicePrintView/${id}`;
    const tempWindow = window.open("", "Print", "height=600,width=800");

    const newIframe = document.createElement("iframe");
    newIframe.src = url;
    newIframe.style.border = "0";
    newIframe.style.width = "100%";
    newIframe.style.height = "100%";
    if (tempWindow) {
      tempWindow.document.body.style = `margin: 0;`;

      tempWindow.document.body.appendChild(newIframe);
    }
    newIframe.onload = () => {
      tempWindow.print();
      tempWindow.close();
    };
  };
  return (
    <>
      <Helmet>
        <title> Invoice printing </title>
        <link rel="canonical" href={path} />
        <meta name="description" content="Printing your invoice by account" />
      </Helmet>
      <h1 className="visually-hidden">Printing your invoice by account</h1>
      <div className="container-xxl">
        <div className="payment rounded p-2 d-flex justify-content-end">
          <button
            onClick={removeandrelod}
            type="button"
            className=" btn btn-danger p-3 fs-5"
          >
            {t("newOrder")}
            <AiOutlineShoppingCart />{" "}
          </button>
        </div>
        <div className="row ">
          <div className="col col-md-6 col-12 text-center">
            <div className="button-print">
              <h3 className="fs-5 mt-5"> {t("RecieveReceipt")}</h3>

              <button
                id="btn-print"
                className="btn btn-danger text-wight w-100  p-2"
                onClick={() => handlePrint(id)}
              >
                {" "}
                <AiTwotonePrinter />
                {t("Print Receipt")}
              </button>
              {user && Number(user.data.pos_choose_customers) === 1 && (
                <Link
                  to={!customer && "/SelectUserToPay"}
                  state={"validate"}
                  className="input-group mt-5 input-email"
                >
                  <span>
                    <MdEmail />
                  </span>

                  <input
                    type="email"
                    readOnly
                    defaultValue={customer && customer.email}
                    className="form-control"
                    id="inputGroupFile4"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                  />
                  <button
                    className="btn btn-danger text-wight"
                    type="button"
                    id="inputGroupFileAddon04"
                  >
                    {t("send Email")}
                  </button>
                </Link>
              )}
            </div>
          </div>

          <div className="col col-md-6 col-12 ">
            <iframe
              title="invoce"
              onLoad={() => handlePrint(id)}
              src={`${VODOGLOBAL_ACOUNTING_API}/sales_invoice/getInvoicePrintView/${id}`}
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Validate;
