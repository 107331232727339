import React from 'react'
import PageNotification from "./pages/PageNotification/PageNotification";
import NotificationMAsseg from "./components/NotificationMAsseg/NotificationMAsseg";
export default function Notification() {
  const userLogn = JSON.parse(sessionStorage.getItem("user")); 

  return (
    <>
     { userLogn && userLogn.data.access_token && <>  
      <PageNotification/>
      <NotificationMAsseg/>  
     </>
     }
    </>
  )
}
