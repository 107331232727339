import React, { useEffect, useState } from "react";
import "./style.css";
import { ImCalculator } from "react-icons/im";
import InputCalcTotal from "../../components/InputCalCTotal/InputCalcTotal";
import { useDispatch, useSelector } from "react-redux";
import {
  cash_accounts_list,
  open_session,
} from "../../services/redux/action/action";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import SpinnerLoding from "../SpinnerLoding/SpinnerLoding";
import { t } from "i18next";

export default function OPenSession({ user, masseg }) {
  const [showCalc, setShowCalc] = useState(false);
  const [notes, setnotes] = useState("");
  const [TotalSession, setTotalSession] = useState("");
  const [detailsTotal, setdetailsTotal] = useState("");
  const [nameManger, setnameManger] = useState("");
  const [Nearestpos, setNearestpos] = useState("");
  const [dataListsTotal, setDataListTotal] = useState([]);

  const desiptch = useDispatch();
  const navgite = useNavigate();

  useEffect(() => {
    desiptch(cash_accounts_list(user.data.user_id));
  }, [desiptch, user.data.user_id]);

  const opeSession = (event) => {
    event.preventDefault();
    const data = {
      note: notes,
      opening_cash_currency_cat: dataListsTotal,
      from_cash_account: nameManger,
      opening_cash: dataListsTotal ? TotalSession : 0,
      to_cash_account: user.data.cash_account_id,
      pos_id: Nearestpos,
      user_id: user.data.user_id,
    };

    desiptch(open_session(data));
  };

  const cashAccountsList = useSelector((state) => state.cash_accounts_list);

  const options = [];
  if (cashAccountsList && cashAccountsList.status) {
    cashAccountsList.data.data.forEach((item) => {
      options.push({ value: item.id, label: item.name });
    });
  }

  const emptyRedux = () => {
    desiptch({ type: "nearest_pos", cash_accounts_list: "" });
    sessionStorage.removeItem("nearestpos");
  };
  const nearestpos = JSON.parse(sessionStorage.getItem("nearestpos"));

  const optionPoint = [];
  if (nearestpos) {
    nearestpos.data.current_pos.forEach((e) => {
      optionPoint.push({ value: e.id, label: e.name });
    });
  } else {
    navgite("/");
  }

  return (
    <div className="open_cash ">
      <div className="container-xxl">
        <div className="  m-auto  shadow item   w-resp-opnCash  position-relative">
          {showCalc && (
            <InputCalcTotal
              setShowCalc={setShowCalc}
              setTotalSession={setTotalSession}
              detailsTotal={detailsTotal}
              setdetailsTotal={setdetailsTotal}
              dataListsTotal={dataListsTotal}
              setDataListTotal={setDataListTotal}
            />
          )}
          <h5 className="text-center py-4 m-0">{t("OpenSession")}</h5>
          {masseg && (
            <div className="text-center fs-5 alert alert-danger m-2 lh-1">
              {" "}
              {masseg}{" "}
            </div>
          )}

          <form onSubmit={opeSession}>
            <div className="d-flex flex-column flex-sm-row    align-items-center justify-content-between px-4 py-2 ">
              <div className=" col-12 col-md-6 fs-5  my-2">
                {t("OPeningCash")}
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-end">
                {nameManger && (
                  <>
                    <input
                      onChange={(event) => setTotalSession(event.target.value)}
                      value={TotalSession}
                      type="text"
                      name=""
                      placeholder="0"
                      className="form-control input_total_price form-control-lg my-2"
                      id=""
                    />
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setShowCalc(true);
                      }}
                      className="mx-3 calc_icon  my-2"
                    >
                      <ImCalculator />
                    </button>
                  </>
                )}
              </div>
            </div>
            <div>
              <div className="row px-4 py-2">
                <div className={"col-12 col-sm-6"}>
                  <h4 className="m-0 px-2 ">
                    {t("Select the point of sale")}:
                  </h4>
                </div>
                <div className={"col-12 col-sm-6"}>
                  {optionPoint.length > 0 ? (
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={true}
                      options={optionPoint}
                      isClearable={true}
                      onChange={(data) => setNearestpos(data && data.value)}
                    />
                  ) : (
                    <SpinnerLoding />
                  )}
                </div>
              </div>

              <div className="row px-4 py-2">
                <div className="col-12 col-sm-8 col-lg-6 d-flex align-items-center">
                  <h4 className="m-0 px-2">{t("From")}:</h4>
                  <div className="w-100">
                    {options.length > 0 ? (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        options={options}
                        onChange={(data) => setnameManger(data && data.value)}
                      />
                    ) : (
                      <SpinnerLoding />
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-6 d-flex align-items-center  ">
                  <h4 className="px-2 mb-0">{t("To")}:</h4>
                  <p className="m-0 fs-5 lh-1">
                    {" "}
                    {user.data.cash_account_name}
                  </p>
                </div>
              </div>
            </div>
            <div className="p-3">
              <textarea
                className="form-control "
                onChange={(event) => setnotes(event.target.value)}
                placeholder="Notes..."
                id="exampleFormControlTextarea1"
                rows="5"
              ></textarea>
            </div>
            <div className="pb-3 text-center d-flex align-items-center justify-content-around">
              <button
                type="submit"
                className="btn red_btn"
                disabled={!Nearestpos}
              >
                {t("OpenSession")}
              </button>
              <Link to="/" className="btn dark_btn" onClick={emptyRedux}>
                {" "}
                {t("Discard")}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
