import React from "react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import './style.css';
function Footer() {
    const { t, i18n } = useTranslation();
   const saveChange =(lange) => {
    sessionStorage.setItem("lang",JSON.stringify(lange))
   }
   const localLang =JSON.parse(sessionStorage.getItem("lang"))
   let lang =localLang ? localLang : i18n.language;
 useEffect(() =>{
    if(localLang){
        i18n.changeLanguage(localLang) 
    }
 },[])
 
 
 
    return (
        <>
            <footer className="container-xxl  ">
            <div>{t("Copyright")}</div>
             <div className="d-flex fs-4 align-items-center ">
                     {t("language")}
                <select  value={lang}  className="form-select fs-5 mx-2" aria-label="Default select example"  onChange={(e) =>{  i18n.changeLanguage(e.target.value);saveChange(e.target.value)}}>
                  <option  value={"en"}  >  en </option> 
                  <option  value={"ar"} >  ar </option> 
                </select>
            </div>
            </footer>
        </>
    );
}

export default Footer;
