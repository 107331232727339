import React from "react";
import { useTranslation } from "react-i18next";
import { DiscHook } from "../../hooks/DiscHook";
const CartItemAsidePage = ({
  navigate,
  handleClick,
  id,
  product_ar_name,
  product_en_name,
  final_price,
  order,

  details,
  Note,
  withOut,
  extra,
  event,
  Disc,
  removeitem,
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [discountedPrice] = DiscHook(final_price, Disc); // to work discount
  const user = JSON.parse(sessionStorage.getItem("user"));

  const active = () => {
    if (details)
      return event
        ? id == details.id && event.reference == details.event.reference
        : id == details.id;
  };

  return (
    <div
      key={id}
      href="#"
      onClick={(e) => {
        navigate && navigate(removeitem);
        handleClick && handleClick(e, removeitem);
      }}
      className={
        active()
          ? "active  list-group-item list-group-item-action"
          : " list-group-item list-group-item-action"
      }
      aria-current="true"
    >
      <div className="item d-flex justify-content-between ">
        <div>
          <h5>{lang === "ar" ? product_ar_name : product_en_name}</h5>
          <div className="order-price my-2 d-flex lh-1">
            <div>
              {" "}
              {t("order")} {order}{" "}
            </div>{" "}
            /{" "}
            <div>
              {" "}
              {t("price")} {Number(final_price).toFixed(2)}{" "}
            </div>{" "}
          </div>
          {event && (
            <div className="text-muted  mb-1 lh-sm ">
              {t("event")}: {event.title}
              <div>
                <p className=" m-0">
                  <span>Start </span>
                  {event.start}
                </p>
                <p className=" m-0">
                  <span>End </span>
                  {event.end}
                </p>
              </div>
            </div>
          )}
          {Disc && Disc > 0 ? (
            <p className="lh-sm mb-0 text_disc text-center mt-1">
              -{Disc && Disc} %
            </p>
          ) : null}
          {extra && (
            <div className="text-muted  mb-1 lh-sm ">
              {t("extra")}:
              {extra.map((item) => (
                <p className=" m-0">
                  {" "}
                  -{item.name} :- {item.price}{" "}
                  {user && user.data.currency_symbol}{" "}
                </p>
              ))}
            </div>
          )}
          {extra && (
            <div className="text-muted  mb-1 lh-sm ">
              {t("extra")}:
              {extra.map((item) => (
                <p className=" m-0">
                  {" "}
                  -{item.name} :- {item.price}{" "}
                  {user && user.data.currency_symbol}{" "}
                </p>
              ))}
            </div>
          )}
          {withOut && (
            <div className="text-muted   mb-1 lh-sm ">
              {t("Without")}:
              {withOut.map((item) => (
                <span className="my-3"> -{item} </span>
              ))}
            </div>
          )}
          {Note && <div className="text-muted  lh-sm   mb-1 "> - {Note}</div>}
        </div>
        <h4>
          {" "}
          {Disc
            ? (order * discountedPrice).toFixed(2)
            : (order * final_price).toFixed(2)}
          {user && user.data.currency_symbol}
        </h4>
      </div>
    </div>
  );
};
export default CartItemAsidePage;
