import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import VODOLOGO from "../../asssets/images/VODOLOGO.png";
import InputLogin from "../../components/InputLogin/InputLogin";
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { data } from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { forgot_password } from '../../services/redux/action/action';
export default function NewPassword() {
    const [code,setcode]= useState('')
    const [newPass,setNewPass]= useState('')
    const [massege,setmassege]= useState('')

       const navigate = useNavigate();
       const dispatch =useDispatch()

       let email = JSON.parse(sessionStorage.getItem("email_forgect_pass"))
    const submituser =(e)=>{
        e.preventDefault()
        const data ={ email:email,code: code.target.value,password:newPass.target.value  }
           dispatch(forgot_password(data))
       }
let forgotPassword = useSelector(state => state.forgot_password)
 
 
    useEffect(()=>{
      if(forgotPassword && forgotPassword.status){
            navigate("/Login")
            sessionStorage.removeItem("email_forgect_pass")

      }else if(forgotPassword && !forgotPassword.status){
        setmassege(forgotPassword.data)
        setTimeout(()=>{
          setmassege('')
        },4000)
      }
     
      },[forgotPassword])
const path = window.location.origin
  return (
    <>
    <Helmet>
    <title>  Forget Password</title>
    <link rel="canonical" href={path} />
    <meta
      name="description"
      content="Log in to the system with your own account"
    />
  </Helmet>
  <h1 className="visually-hidden">Log in to the system with your own account</h1>
  <div className="login-page d-flex flex-column justify-content-center align-items-center">
  <div className="container text-center ">
  <img width={200} className=" mt-5" src={VODOLOGO} alt="" />
    
  </div>
    <div className="container form-login mt-5">
        
        <form onSubmit={submituser} className="w-50 m-auto">

        {massege && <div test-id="masseg_erorr" className="alert alert-danger">{ massege}</div>}
           <InputLogin value={code && code.target.value}  getvslueform={setcode}  type={"text"} placeholder={t("Enter code ")}/>
            <InputLogin value={newPass && newPass.target.value}  getvslueform={setNewPass}  type={"password"} placeholder={t("Enter your Email")}/>
            <div className='text-center'>
            <button disabled={!code ||  !newPass}  className="btn dark_btn mt-3 w-50 " type="submit" >{t("Sent")}</button>

            </div>
            

        </form>
    </div>
  </div>
  </>
  )
}
