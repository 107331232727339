 import React, { useState } from "react";
import DetialsTabel from "../DetialsTabel/DetialsTabel";
import "./style.css";
const SelectTabel = () => {
  let DataTabel = JSON.parse(sessionStorage.getItem("AllDataTabels"));
  const [activeTabel, setActiveTabel] = useState();
  const [ ContentTabel, setContentTabel] = useState();

  return (
    <>
      <div
        className="modal fade"
        id="SelectTabel"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header     justify-content-center border-0">
            <nav className="nav nav-pills flex-column flex-sm-row shadow p-2">
          <a className="flex-sm-fill text-sm-center nav-link  " aria-current="page" href="#">Active</a>
          <a className="flex-sm-fill text-sm-center nav-link active" aria-current="page" href="#">{DataTabel && DataTabel.PositionName}</a>
          </nav>
          
            </div>
            <div className="modal-body">
              <DetialsTabel ContentTabel={ContentTabel} />
              <section className="main_Tabel ">
                {DataTabel && DataTabel.tabelsData &&
                  DataTabel.tabelsData.map((item) => (
                    <div
                      onClick={() => {
                        setActiveTabel(item.id);
                        setContentTabel(item)
                      }}
                      data-bs-toggle="offcanvas"
                      href="#offcanvasExample"
                      role="button"
                      aria-controls="offcanvasExample"
                      key={item.id}
                      className={`${item.type} ${
                        item.id == activeTabel && "active_tabel"
                      } d-flex flex-column allDrownDiv   align-items-center  justify-content-center  `}
                      style={{ top: item.y, left: item.x }}
                    >
                      <div className="tabel_number"> {item.tabel_number}</div>
                      <div className="mt-1 shadow p-1 number_clint ">
                        {" "}
                        clients: {item.Count_Peopel}
                      </div>
                    </div>
                  ))}
              </section>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn red_btn">
                save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectTabel;
