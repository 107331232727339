import { t } from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import FooterInPrint from '../FooterInPrint/FooterInPrint';
import HeaderInPrint from '../HeaderInPrint/HeaderInPrint';

export default function AllSessionTabel({sessionsList,componentRef}) {
   const Navget =useNavigate();

  return (
    <>
     <table ref={componentRef}   className="table  table-hover  fs-5 ">
    <thead>
    <tr className='header_print'>
        <td colSpan={6} >
        <HeaderInPrint titel={"AllSession"}/> 
        </td>
      </tr>
        <tr>
        <th>{t("SessionID")}</th>
        <th>{t("PointOfSale")}</th>
        <th>{t("OpenedBy")}</th>
        <th>{t("OpeningDate")}</th>
        <th>{t("ClosingDate")}</th>
        <th>{t("Status")}</th>   
        </tr>
    </thead>
    <tbody>
        {sessionsList && sessionsList.data[0]   && sessionsList.data[0].sessions[0].id ?sessionsList.data[0].sessions.map((item)=>(
                <tr key={item.id} onClick={() =>Navget(`/Sessions/${item.id}`)}>
                <td>{item.id} </td>
                <td>{item.pos} </td>
                <td>{item.user} </td>
                <td>{item.created_date} </td>
                <td>{item.close_date} </td>
                <td>{ item.status === "open" ? <span className='alert alert-danger m-0 p-1'>{item.status}</span>: <span className='alert alert-primary m-0 p-1'>{item.status}</span> }  </td>
              </tr>
      )):<tr><td colSpan={6} className={"text-center"}>{t("no_data")}</td> </tr> }
     </tbody>
     <tfoot>
      <tr  className='footer_print border-0' >
        <td colSpan={7} className="border-0">
          <FooterInPrint/>
        </td>
      </tr>
    </tfoot>
</table>
    </>
  )
}
