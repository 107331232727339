import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";
import { TbBrandAirtable, TbH2 } from "react-icons/tb";
import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { BsLink45Deg } from "react-icons/bs";
import { MdCompareArrows } from "react-icons/md";
import AsideHome from "../../layouts/asideHome/asideHome";
import Carts from "../../components/carts/carts";
import "./style.css";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCart,
  ListprodectByCatigory,
  opt_session_style,
  Orders_Suspension,
} from "../../services/redux/action/action";
import Calculatorfun from "../../components/Calculator/Calculator";
import Scinner from "../../components/scinner/scinner";
import OPenSession from "../../components/OPenSession/OPenSession";
import SelectTabel from "../../components/SelectTabel/SelectTabel";
import { t } from "i18next";
import SpinnerLoding from "../../components/SpinnerLoding/SpinnerLoding";
import CatigortySession from "../../layouts/CatigorySession/CatigortySession";
import PaginateComp from "../../components/PaginateComp/PaginateComp";
import SettingSectionCart from "../../components/settingSectionCart/SettingSectionCart";
import CustomAlert from "../../components/customeAlert/CustomAlert";
import SelectEvent from "../../components/Model/SelectEvent";
import { useRef } from "react";
function Session() {
  const [prodect, setProdect] = useState([]);
  const [counts, setCount] = useState(0);
  const [cartid, setcartid] = useState(null);
  const [activeid, setactiveid] = useState(null);
  const [itemActive, setitemActive] = useState(null);
  const [data, setData] = useState(null);
  const [Change, setChange] = useState(true);
  const [numberPage, setnumberPage] = useState(1);
  const [masseg, setmassge] = useState("");
  const [categoryId, setcategoryId] = useState("all");
  const [textVal, setTextVal] = useState("Qty");
  const [brands, setbrands] = useState("");
  const [itemProdect, setitemProdect] = useState("");

  const dispatch = useDispatch();
  const valueSearch = useSelector((state) => state.ValueSearch);
  const customer = JSON.parse(sessionStorage.getItem("customer"));
  const user = JSON.parse(sessionStorage.getItem("user"));
  const prodects = useSelector((state) => state.ListprodectByCatigory);
  const cards = useSelector((state) => state.item);
  const path = window.location.origin;
  let dataComang = useSelector((state) => state.Select_Cart_Style);
  const orders_Suspension = useSelector((state) => state.orders_Suspension);
  const open__session = useSelector((state) => state.open_session);

  useEffect(() => {
    //session open
    if (
      open__session &&
      open__session.status &&
      open__session.message !== "error"
    ) {
      sessionStorage.setItem(
        "open_session",
        JSON.stringify(open__session.data.pos_session)
      );
    } else if (
      open__session &&
      open__session.status &&
      open__session.message === "error"
    ) {
      setmassge(open__session.data.error);
      setTimeout(() => {
        setmassge("");
      }, 5000);
    }
  }, [open__session]);
  //  check if session open or not
  let OpenSession =
    open__session && open__session.status && open__session.message !== "error"
      ? open__session.data.pos_session
      : JSON.parse(sessionStorage.getItem("open_session"));
  useEffect(() => {
    //get all data by filter page and search and category
    if (OpenSession !== null) {
      if (dataComang.ProductDisplayFormat === "scrolling") {
        dispatch(
          ListprodectByCatigory({
            pos_id: OpenSession.pos_id,
            page: "all",
            category_id: categoryId,
            keyword: valueSearch,
            brand_id: brands,
          })
        );
      } else {
        dispatch(
          ListprodectByCatigory({
            pos_id: OpenSession.pos_id,
            page: numberPage,
            category_id: categoryId,
            keyword: valueSearch,
            brand_id: brands,
          })
        );
      }
    }
  }, [categoryId, numberPage, valueSearch, dataComang, open__session, brands]);

  useEffect(() => {
    //  show   all prodects
    if (prodects && prodects.data) {
      setData(prodects.data.products_list);
    }
  }, [prodects]);

  useEffect(() => {
    //sad page number
    setnumberPage(1);
  }, [categoryId, valueSearch]);
  //  active button
  const activfun = (item) => {
    setCount(0);
    setChange(false);
    setTextVal(item.target.name);
    item.target.classList.add("active");
  };
  const getClassName = (eventKey) => {
    return eventKey === textVal ? "active calculator-key" : "calculator-key";
  };
  // //////
  const optsessionstyle = useSelector((state) => state.opt_session_style);
  const hendel_Opt_Session_Style = (name) => {
    optsessionstyle[name] = !optsessionstyle[name];
    dispatch(opt_session_style(optsessionstyle));
  };
  useEffect(() => {
    let opt_sessionstyle = JSON.parse(
      sessionStorage.getItem("opt_session_style")
    );
    if (opt_sessionstyle) {
      dispatch(opt_session_style(opt_sessionstyle));
    } else {
      dispatch(opt_session_style({ Show_Keyboard: true, show_Prodect: true }));
    }
  }, []);

  const orderSuspension = () => {
    const randomId = Math.random() * 100;
    var date = new Date();
    var options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    var formattedDate = date.toLocaleString("en-US", options);
    if (cards && cards.length > 0) {
      const data = {
        customer_name: customer ? customer.first_name : "not Select",
        customer_id: customer ? customer.id : "-",
        date: formattedDate,
        prodects: cards,
        id: randomId.toFixed(3),
      };

      if (orders_Suspension) {
        sessionStorage.setItem(
          "orders_Suspension",
          JSON.stringify([...orders_Suspension, data])
        );
        dispatch(Orders_Suspension([...orders_Suspension, data]));
      } else {
        sessionStorage.setItem("orders_Suspension", JSON.stringify([data]));
        dispatch(Orders_Suspension([data]));
      }
      dispatch(AddCart([]));
    }
  };
  const refEvent = useRef();
  return (
    <>
      <Helmet>
        <title>Vodo </title>
        <link rel="canonical" href={path} />
        <meta
          name="description"
          content="A page showing your products that are in your store"
        />
      </Helmet>
      {!OpenSession && <OPenSession masseg={masseg} user={user} />}
      <SelectTabel />
      <SelectEvent
        refEvent={refEvent}
        itemProdect={itemProdect}
        setCount={setCount}
        setChange={setChange}
        setTextVal={setTextVal}
        setactiveid={setactiveid}
        setitemActive={setitemActive}
        setcartid={setcartid}
        setProdect={setProdect}
      />
      <Scinner
        setactiveid={setactiveid}
        setCount={setCount}
        setTextVal={setTextVal}
        setcartid={setcartid}
        setChange={setChange}
        refEvent={refEvent}
        setitemActive={setitemActive}
        setProdect={setProdect}
        setitemProdect={setitemProdect}
      />
      <CustomAlert>
        <SettingSectionCart />
      </CustomAlert>
      <h1 className="visually-hidden">All products that are in stock</h1>

      <div className="d-flex justify-content-center">
        <div className="row home-contect">
          <aside
            className={`${
              optsessionstyle && optsessionstyle.show_Prodect && "col-md-4"
            } col  col-12 order-2 order-md-1`}
          >
            <AsideHome
              setCount={setCount}
              setTextVal={setTextVal}
              activeid={activeid}
              textVal={textVal}
              cartid={cartid}
              counts={counts}
              prodect={prodect}
              Change={Change}
              itemActive={itemActive}
              setChange={setChange}
            />
            <div className="d-flex w-100 flex-column align-items-end">
              {optsessionstyle && optsessionstyle.Show_Keyboard && (
                <div className="calc-fun w-100 position-relative  ">
                  <Calculatorfun setCount={setCount} setChange={setChange} />
                  <div
                    style={{ bottom: "25%" }}
                    className="functions position-absolute"
                  >
                    <button
                      name="Qty"
                      className={getClassName("Qty")}
                      onClick={(item) => activfun(item)}
                    >
                      {t("Qty")}
                    </button>
                    <button
                      title="Not available for this account"
                      name="Disc"
                      className={getClassName("Disc")}
                      onClick={(item) => {
                        activfun(item);
                        setCount(0);
                      }}
                      disabled={
                        user && Number(user.data.pos_add_discount) !== 1
                      }
                    >
                      {t("Disc")}
                    </button>

                    <button
                      title="Not available for this account"
                      name="Price"
                      className={getClassName("Price")}
                      onClick={(item) => activfun(item)}
                      disabled={
                        user && Number(user.data.pos_price_update) !== 1
                      }
                    >
                      {t("Price")}
                    </button>
                  </div>
                </div>
              )}
              <div className="w-100 d-flex">
                {user && Number(user.data.pos_choose_customers) === 1 && (
                  <Link
                    title="Not available for this account"
                    to={"/SelectUserToPay"}
                    state={"Session"}
                    className="user-item w-100  text-center"
                  >
                    <span className="user-icon">
                      <FaUserCircle />
                    </span>
                    <p className="paym-text">{t("List of clients")}</p>
                  </Link>
                )}

                {OpenSession &&
                  OpenSession.pos_type &&
                  OpenSession.pos_type !== 1 && (
                    <div
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#SelectTabel"
                      data-bs-whatever="@getbootstrap"
                      title="Not available for this account"
                      className="user-item  w-100 text-center"
                    >
                      <div>
                        <span className="user-icon ">
                          <TbBrandAirtable />
                        </span>
                        <p className="paym-text ">{t("Select Tabel")}</p>
                      </div>
                    </div>
                  )}
                <div className="user-item  w-100 text-center">
                  {cards && cards.length > 0 ? (
                    <Link className="link" to={"/Payment"}>
                      <span className="user-icon ">
                        <MdOutlinePayments />
                      </span>
                      <p className="paym-text">{t("Payment")}</p>
                    </Link>
                  ) : (
                    <Link className="link">
                      <span className="user-icon ">
                        <MdOutlinePayments />
                      </span>
                      <p className="paym-text"> {t("Payment")}</p>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </aside>

          {optsessionstyle && optsessionstyle.show_Prodect && (
            <div className="cards  position-relative   col col-md-8 col-12 order-md-2 order-1 flex-column justify-content-between d-flex">
              {data && data !== null ? (
                <>
                  {" "}
                  <div>
                    <div className="position-absolute parind_session"></div>
                    <div className="row card-group ">
                      {data && data !== null && data.length > 0 ? (
                        data.map((item) => {
                          return (
                            Number(item.final_price) > 0 && (
                              <div
                                key={item.id}
                                className="col-lg-2 col-sm-4 col-md-3 col-6 mt-1"
                              >
                                <Carts
                                  setCount={setCount}
                                  setChange={setChange}
                                  setTextVal={setTextVal}
                                  setactiveid={setactiveid}
                                  setcartid={setcartid}
                                  item={item}
                                  setProdect={setProdect}
                                  refEvent={refEvent}
                                  setitemActive={setitemActive}
                                  setitemProdect={setitemProdect}
                                />
                              </div>
                            )
                          );
                        })
                      ) : (
                        <h5 className="mt-5 text-center">{t("no_data")}</h5>
                      )}
                    </div>
                  </div>
                  <div
                    style={{ bottom: "0px" }}
                    className="d-flex justify-content-center    mt-3  "
                  >
                    {dataComang.ProductDisplayFormat === "pagination" && (
                      <PaginateComp
                        pageCounts={prodects.data.pages_number}
                        setnumberPage={setnumberPage}
                      />
                    )}
                  </div>
                </>
              ) : (
                <SpinnerLoding />
              )}
            </div>
          )}
          <div className=" w-100  d-flex m-auto  div-buttons  order-2  ">
            <div className="row w-100">
              <div className="col  col-12 col-sm-6 col-md-3  mt-1">
                <button
                  type="button"
                  className="btn w-100   btn-outline border buttons-icon"
                  onClick={() => hendel_Opt_Session_Style("Show_Keyboard")}
                >
                  <span className="icon-dedails">
                    {optsessionstyle.Show_Keyboard ? (
                      <AiFillEyeInvisible size={25} />
                    ) : (
                      <AiFillEye size={25} />
                    )}
                  </span>
                  {t("keyboard")}
                </button>
              </div>
              <div className="col col-12 col-sm-6 col-md-3  mt-1">
                <button
                  type="button"
                  onClick={() => hendel_Opt_Session_Style("show_Prodect")}
                  className="btn w-100  btn-outline border  buttons-icon"
                >
                  <span className="icon-dedails">
                    {optsessionstyle.show_Prodect ? (
                      <AiFillEyeInvisible size={25} />
                    ) : (
                      <AiFillEye size={25} />
                    )}
                  </span>
                  {t("Products")}
                </button>
              </div>
              {user && Number(user.data.pos_refund) === 1 && (
                <div className="col  col-12 col-sm-6 col-md-3  mt-1">
                  <Link
                    to={"/Refund"}
                    className="btn w-100   btn-outline border buttons-icon "
                  >
                    <span className="icon-dedails">
                      <MdCompareArrows />
                    </span>
                    {t("Refund")}
                  </Link>
                </div>
              )}
              <div className="col col-12 col-sm-6  col-md-3  mt-1">
                <button
                  onClick={orderSuspension}
                  type="button"
                  className="btn w-100  btn-outline border  buttons-icon"
                >
                  <span className="icon-dedails">
                    {" "}
                    <BsLink45Deg />
                  </span>
                  {t("Suspend")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {prodects &&
          prodects.data &&
          optsessionstyle &&
          optsessionstyle.show_Prodect && (
            <CatigortySession
              brands={brands}
              setbrands={setbrands}
              setcategoryId={setcategoryId}
              categoryList={prodects.data.category_list}
            />
          )}
      </div>
    </>
  );
}

export default Session;
