import { t } from 'i18next'
import React from 'react'
 export default function HeaderInPrint({titel}) {
  const user = JSON.parse(sessionStorage.getItem("user"))
  
  return (
     <header className='headerPrint'>
       <div className='d-flex align-items-center  justify-content-between'>
        <div > 
            <img width={150} src={user && user.data.business_logo} alt="" />
        </div>
        <div>
            <h5>
                 {user && user.data.business_name}
            </h5>
             
        </div>
       </div>
       <h4 className='text-center'>
       {t(titel)} 
       </h4>
     </header>
  )
}
