import { t } from 'i18next'
import React from 'react'

export default function PromptCustomNotification({data}) {
  return (
<>
 

 <div className="modal fade" id="OPenMAssg" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
       {data}
      </div>
      <div className="modal-footer d-flex justify-content-between">
        <button type="button" className="btn dark_btn "  >{t("Refusal")}</button>
        <button type="button" className="btn  red_btn">{t("Confirm")}</button>
      </div>
    </div>
  </div>
</div>
</>
  )
}
