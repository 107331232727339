import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from '../../services/redux/action/action';
import { Helmet } from 'react-helmet-async';
import VODOLOGO from "../../asssets/images/VODOLOGO.png";

import "./style.css"
import { t } from "i18next";
import InputLogin from "../../components/InputLogin/InputLogin";
const Login = () => {
const [massege,setmassege] = useState('')
const [value,setvalue]=useState({  residence_num:'' ,mobile:'' ,password:'' })
const dispatch = useDispatch()
const navigate = useNavigate();

const submituser =(event) =>{
     event.preventDefault()
     dispatch(login(value))
}
const result = useSelector(state => state.login);
const userLogn = JSON.parse(sessionStorage.getItem('user'));
  
 useEffect(()=>{
  if(result &&  result.data && result.data.status !== 1 ){
    setmassege( result.data.msg)
    setTimeout(()=>{
      setmassege(null)
    },10000)
   }
   if(userLogn && userLogn.status === 1){
    navigate("/") 
    window.location.reload()
}

 },[result])
useEffect(()=>{
const olduser = JSON.parse(sessionStorage.getItem('user'));

if(olduser){
    sessionStorage.removeItem('user')
}
},[])
const path = window.location.origin
const getvslueform = (event) =>{
const name = event.target.name;
const val = event.target.value;
setvalue({
  ...value,
  [name]:val
})
}
    return (<>
      <Helmet>
        <title> Login </title>
        <link rel="canonical" href={path} />
        <meta
          name="description"
          content="Log in to the system with your own account"
        />
      </Helmet>
      <h1 className="visually-hidden">Log in to the system with your own account</h1>
      <div className="login-page d-flex flex-column justify-content-center align-items-center">
      <div className="container text-center ">
      <img width={200} className=" mt-5" src={VODOLOGO} alt="" />
      </div>
        <div className="container form-login mt-5">
            <form onSubmit={submituser} className="w-50 m-auto">
            {massege && <div test-id="masseg_erorr" className="alert alert-danger">{ massege}</div>}
                <InputLogin value={ value.residence_num}  getvslueform={getvslueform}  type="text" placeholder={t("residence num")} name={"residence_num"}/>
                <InputLogin value={ value.mobile}  getvslueform={getvslueform}  type="text" placeholder={t("mobile")} name={"mobile"}/>
                <InputLogin value={ value.password}  getvslueform={getvslueform}  type="password" placeholder={t("password")} name={"password"}/>
              
                <button disabled={value.residence_num.length < 4 || value.mobile.length < 4  || value.password.length < 4} className="btn mb-1 dark_btn w-100" type="submit" >{t("Login")}</button>
                <Link to={"/ForgetPassword"}>{t("Forget your password ?")} </Link>
            
            </form>
        </div>
      </div>
    

    </>)
}
export default Login