import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { AddCart } from "../../services/redux/action/action";
import { useTranslation } from "react-i18next";
import WithOut from "../../components/OptionalComp/WithOut";
import NotesComp from "../../components/OptionalComp/NotesComp";
import Extra from "../../components/OptionalComp/Extra";
import CartItemAsidePage from "../../components/CardItemAsidPage/CartItemAsidePage";
import { TotalHook } from "../../hooks/TotalHook";

function AsideHome({
  activeid,
  counts,
  Change,
  textVal,
  setCount,
  setChange,
  itemActive,
}) {
  const [number, setnumber] = useState(1);
  const [Notes, setNotes] = useState(null);
  const [withOut, setWithOut] = useState(null);
  const [extra, setExtra] = useState(null);
  const prodect = JSON.parse(sessionStorage.getItem("items"));
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const user = JSON.parse(sessionStorage.getItem("user"));

  const optsessionstyle = useSelector((state) => state.opt_session_style);

  useEffect(() => {
    setnumber(counts);
  }, [counts]);

  const [details, setDetails] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (itemActive) {
      const filter = prodect.filter((employee) => {
        return employee.id === itemActive.id;
      });

      if (itemActive.type == "ticket") {
        const filterByTiket = filter.filter((employee) => {
          return employee.event.reference === itemActive.event.reference;
        });
        setDetails(...filterByTiket);
      } else {
        setDetails(...filter);
      }
    }

    // if (activeid) {
    //   const filtered =
    //     prodect &&
    //     prodect.filter((employee) => {
    //       return employee.id === activeid;
    //     });
    //   setDetails(...filtered);
    // }
  }, [activeid, itemActive]);
  // /////////////////// to scroll top
  useEffect(() => {
    if (document.querySelector(".list-group-item.active")) {
      let myElement = document.querySelector(".list-group-item.active");
      var topPos = myElement.offsetTop;
      document.querySelector(".items-price").scrollTop = topPos;
    }
  });

  const navigate = (itme) => {
    const filter = prodect.filter((employee) => {
      return employee.id === itme.id;
    });
    if (itme.type == "ticket" && filter) {
      const filterByTiket = filter.filter((employee) => {
        return employee.event.reference === itme.event.reference;
      });
      setDetails(...filterByTiket);
    } else {
      setDetails(...filter);
    }

    setChange(false);
    setCount(0);
  };
  // //////////////////////////   add list notes
  useEffect(() => {
    if (prodect && details != null) {
      const index = prodect.findIndex((item) => item.id == details.id);
      if (details && index != -1) {
        if (Notes !== null) {
          prodect[index]["Note"] = Notes;
        }
      }
    }

    dispatch(AddCart(prodect));
  }, [Notes]);

  // //////////////////////////   add list without
  useEffect(() => {
    if (prodect && details != null) {
      const index = prodect.findIndex((item) => item.id == details.id);
      if (details && index != -1) {
        if (withOut !== null) {
          prodect[index]["withOut"] = withOut;
        }
      }
    }
    dispatch(AddCart(prodect));
  }, [withOut]);
  useEffect(() => {
    if (prodect && details != null) {
      const index = prodect.findIndex((item) => item.id == details.id);
      if (details && index != -1) {
        if (extra !== null) {
          prodect[index]["extra"] = extra;
        }
      }
    }
    dispatch(AddCart(prodect));
  }, [extra]);

  // //////////////////////////   change count price and qyn
  useEffect(() => {
    if (prodect && details != null && details) {
      const index =
        details.type == "ticket"
          ? prodect.findIndex(
              (item) =>
                item.id == details.id &&
                item.event.reference == details.event.reference
            )
          : prodect.findIndex((item) => item.id == details.id);
      if (details && index != -1) {
        if (
          textVal == "Qty" &&
          Change &&
          prodect[index].type !== "ticket" &&
          prodect[index]["final_price"] > 0 &&
          ((user.data.pos_stock_permission === 1 &&
            prodect[index].stock >= counts) ||
            user.data.pos_stock_permission === 0)
        ) {
          prodect[index]["order"] = counts;
          setCount(prodect[index]["order"]);
        } else if (
          textVal == "Qty" &&
          Change &&
          prodect[index].type == "ticket" &&
          prodect[index]["final_price"] > 0 &&
          prodect[index].event?.active_tickets >= counts
        ) {
          prodect[index]["order"] = counts;
          setCount(prodect[index]["order"]);
        } else if (
          textVal == "Price" &&
          Change &&
          prodect[index]["final_price"] >= 0
        ) {
          prodect[index]["final_price"] = counts;
          prodect[index]["sent_price"] = counts;
          prodect[index]["price"] = counts;

          setCount(prodect[index]["final_price"]);
        } else if (
          textVal == "Disc" &&
          counts <= 100 &&
          prodect[index]["final_price"] > 0
        ) {
          prodect[index]["Disc"] = counts;
          setCount(prodect[index]["Disc"]);
        }
      }
    }
    dispatch(AddCart(prodect));
  }, [counts]);
  const [total, tax, extraTotal] = TotalHook(prodect);
  const handleClick = (e, removeitem) => {
    if (e.detail == 2) {
      let filtered = prodect.filter((item) => {
        return item.id != removeitem.id;
      });
      dispatch(AddCart(filtered));
    }
  };
  const removeItemWithevent = (e, removeItem) => {
    if (e.detail === 2) {
      const eventReference = removeItem.event.Reference;
      const itemIndex = prodect.findIndex(
        (item) =>
          item.id === removeItem.id && item.event.Reference === eventReference
      );

      if (itemIndex !== -1) {
        const filtered = [...prodect];
        filtered.splice(itemIndex, 1);
        dispatch(AddCart(filtered));
      }
    }
  };

  return (
    <>
      <WithOut setWithOut={setWithOut} />
      <NotesComp setNotes={setNotes} />
      <Extra setExtra={setExtra} />
      <div className="all-aside">
        <div
          className={
            optsessionstyle && optsessionstyle.Show_Keyboard
              ? "items-price"
              : "items-price items_price_height"
          }
        >
          <div className="list-group">
            {prodect
              ? prodect.map((item, index) => {
                  return (
                    <CartItemAsidePage
                      navigate={navigate}
                      details={details && details}
                      handleClick={
                        item.type == "ticket"
                          ? removeItemWithevent
                          : handleClick
                      }
                      removeitem={item}
                      key={index}
                      {...item}
                    />
                  );
                })
              : null}
          </div>
        </div>
        {false && (
          <div
            className={`  d-flex optinal_div text-center  justify-content-around`}
          >
            <button
              className="btn    "
              disabled={prodect && prodect.length < 1}
              data-bs-toggle="modal"
              data-bs-target="#WithOut"
            >
              {t("Without")}
            </button>

            <button
              onClick={() => setNotes(null)}
              disabled={prodect && prodect.length < 1}
              className="btn  "
              data-bs-toggle="modal"
              data-bs-target="#NotesComp"
            >
              {t("Notes")}
            </button>

            <button
              className="btn  "
              disabled={prodect && prodect.length < 1}
              data-bs-toggle="modal"
              data-bs-target="#Extra"
            >
              {t("extra")}
            </button>
          </div>
        )}
        <div className="total-price ">
          <div className="d-flex  text-center p-1 justify-content-around">
            <div>
              <p>{t("point")}</p>
              <p className=" text-danger">0</p>
            </div>
            <div>
              <p>{t("taxes")}</p>
              <p>
                {tax.toFixed(2)} {user.data.currency_symbol}{" "}
              </p>
            </div>
            <div>
              <p>{t("total")}</p>
              <p>{(total + extraTotal).toFixed(2)} </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AsideHome;
