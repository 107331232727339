import { t } from 'i18next';
 
import React from 'react'

export default function RefundTabel({data,Actives,setProdectsOrder,setActives}) {
  return (
   <>
      <table  className="table  mb-0  table-hover   table-bordered">
              <thead className="text-body-tertiary">
                  <tr>
                    <td>{t("OrderRef")}</td>
                    <td>{t("Date")}</td>
                    <td> {t("receiptNumber")}  </td>
                    <td> {t("Customer")} </td>
                    <td> {t("Employee")}</td>
                    <td> {t("total")} </td>
                    <td> {t("Status")} </td>
                  </tr>
              </thead>
              <tbody>
          
                  {
                    data  && data.length > 0 ? data.map((item) =>{
                      return(
                        <tr key={item.id} className={ Actives ===  item.id ? "active":''} onClick={() => {  setActives(item.id)}} >
                        <td>{item.reference}</td>
                        <td>{item.date} </td>
                        <td>{item.receipts_num} </td>
                        <td>{item.customer} </td>
                        <td>{item.user} </td>
                        <td>{item.total} </td>
 
                        <td > {item.status} </td>
                      </tr>
                      )
                    }) :<tr><td colSpan={6} className={"text-center"}>{t("no_data")}</td> </tr> 

                  }
             
                  
              </tbody>
          </table>  
   </>
  )
}
