import React from "react";
import NavComp from "../../layouts/NavComp/NavComp";
import "./style.css";
import InputLogin from "../../components/InputLogin/InputLogin";
import { useState } from "react";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";

import { useTranslation } from "react-i18next";
import Card from "./Card";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  add_product,
  business_taxes_list,
  pos_All,
  products_brands,
  products_categories,
  products_types,
} from "../../services/redux/action/action";
import SelectInput from "../../lib/SelectInput/SelectInput";
import CloneForm from "../../components/CloneForm/CloneForm";
export default function AddNewProdect() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSucss, setshowSucss] = useState("");
  const [isplaybutton, setdisplaybutton] = useState(false);
  const [data, setData] = useState({
    name_ar: "",
    name_en: "",
    price: "",
    barcode: "",
    desc_ar: "",
    desc_en: "",
  });
  const [image, setImage] = useState(null);
  const [imageapi, setImageApi] = useState(null);
  const [typePrice, setTypePrice] = useState("");
  const [goToProduct, setgoToProduct] = useState(false);
  const [brands, setbrands] = useState("");
  const [typeProdect, settypeProdect] = useState("");
  const [Categories, setCategories] = useState("");
  const [kindProduct, setkindProduct] = useState("");
  const [forms, setForms] = useState([{ id: "", qty: "" }]);

  const [TypeTax, setTypeTax] = useState(null);
  const lang = i18n.language;
  const user = JSON.parse(sessionStorage.getItem("user"));
  let businessTaxesList = useSelector((state) => state.business_taxes_list);
  let productsBrands = useSelector((state) => state.products_brands);
  let addProduct = useSelector((state) => state.add_product);
  let productsTypes = useSelector((state) => state.products_types);
  let productsCategories = useSelector((state) => state.products_categories);

  let optionTax = [];
  let optionBrand = [];
  let optionTypes = [];
  let optionCategories = [];
  let prodectID = [];
  let prodectQTY = [];

  useEffect(() => {
    if (user && Number(user.data.pos_add_product) !== 1) {
      navigate("/");
    }
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    setImageApi(event.target.files[0]);

    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const addDataUser = (e) => {
    setData({
      ...data,
      [e && e.target.name]: e && e.target.value,
    });
  };
  if (forms) {
    forms.forEach((item) => {
      if (item.id && item.qty) {
        prodectID.push(item.id);
        prodectQTY.push(item.qty);
      }
    });
  }
  const hendling = () => {
    const formdata = new FormData();
    formdata.append("image", imageapi);
    formdata.append("user_id", user.data.user_id);
    formdata.append("name_en", data.name_en);
    formdata.append("name_ar", data.name_ar);
    formdata.append("desc_en", data.desc_en);
    formdata.append("desc_ar", data.desc_ar);
    formdata.append("price", data.price);
    formdata.append("tax_id", TypeTax);
    formdata.append("brand_id", brands);
    formdata.append("tax_inclusive", typePrice);
    formdata.append("type", typeProdect);
    formdata.append("category_id", Categories);
    formdata.append("barcode_text", data.barcode);
    formdata.append("kind", Number(kindProduct));
    prodectID.map((element) => formdata.append("build_product_id[]", element));
    prodectQTY.map((element) =>
      formdata.append("build_product_qty[]", element)
    );

    dispatch(add_product(formdata));
    setdisplaybutton(true);
  };
  useEffect(() => {
    dispatch(business_taxes_list());
    dispatch(products_brands());
    dispatch(products_types());
    dispatch(products_categories());
  }, []);
  let valueText = 0;
  if (businessTaxesList && businessTaxesList.status) {
    let valueTx = businessTaxesList.data.filter((item) => item.id === TypeTax);
    valueText = valueTx && valueTx[0] ? valueTx[0].tax_rate : 0;
    businessTaxesList.data.forEach((item) => {
      optionTax.push({ value: item.id, label: item.name });
    });
  }
  if (productsBrands && productsBrands.status) {
    productsBrands.data.brands.forEach((item) => {
      optionBrand.push({ value: item.id, label: item[`name_${lang}`] });
    });
  }
  if (productsTypes && productsTypes.status) {
    productsTypes.data.types.forEach((item) => {
      optionTypes.push({ value: item.id, label: item[`name_${lang}`] });
    });
  }
  if (productsCategories && productsCategories.status) {
    productsCategories.data.categories.forEach((item) => {
      optionCategories.push({
        value: item.id,
        label: item[`category_${lang}_name`],
      });
    });
  }
  useEffect(() => {
    if (addProduct && addProduct.status && !goToProduct) {
      setshowSucss(t("Add_product_mssag"));
      setdisplaybutton(false);
      setData({
        name_ar: "",
        name_en: "",
        price: "",
        barcode: "",
        desc_ar: "",
        desc_en: "",
      });
      dispatch({ type: "add_product", add_product: [] });
      setTimeout(() => {
        setshowSucss("");
      }, 4000);
    } else if (addProduct && addProduct.status && goToProduct) {
      setdisplaybutton(false);

      setData({
        name_ar: "",
        name_en: "",
        price: "",
        barcode: "",
        desc_ar: "",
        desc_en: "",
      });

      dispatch({ type: "add_product", add_product: [] });
      navigate("/Prodects");
    } else if (addProduct && addProduct.status == false) {
      setdisplaybutton(false);

      setshowSucss(addProduct.message);
      setTimeout(() => {
        setshowSucss("");
      }, 5000);
    }
  }, [addProduct]);

  let priceTax = data.price
    ? Number(data.price) + (Number(valueText) / 100) * Number(data.price)
    : "";

  let buttonDisplay = () => {
    if (
      (TypeTax !== 0 && !typePrice ? true : false) ||
      !data.name_ar ||
      !data.name_en ||
      !data.price ||
      !data.barcode ||
      !kindProduct ||
      !image ||
      isplaybutton ||
      !typeProdect ||
      !Categories ||
      (kindProduct == 1 && prodectID.length == 0) ||
      (kindProduct == 1 && forms.length != prodectID.length) ||
      TypeTax == null
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <section className="add_Prodect">
      <NavComp />
      {showSucss && (
        <div
          className={`  alert_div     d-flex align-items-center justify-content-center `}
        >
          <div
            className={` fs-5 alert ${
              showSucss && addProduct.status == false
                ? "alert-danger"
                : "alert-success "
            } alert-success `}
            role="alert"
          >
            {showSucss}
          </div>
        </div>
      )}
      <div className="container">
        <div className="row   ">
          <div className="col-12 col-md-8 order-2 order-md-1 shadow p-2 mt-4 rounded-4 px-4">
            <h4 className="text-center">{t("Add product")}</h4>

            <div className="row">
              <div className="col-12 col-sm-6 ">
                <InputLogin
                  value={data.name_ar}
                  getvslueform={addDataUser}
                  type="text"
                  placeholder={" * " + t("Name Product Ar")}
                  name={"name_ar"}
                />
              </div>
              <div className="col-12 col-sm-6  ">
                <InputLogin
                  value={data.name_en}
                  getvslueform={addDataUser}
                  type="text"
                  placeholder={" * " + t("Name Product En")}
                  name={"name_en"}
                />
              </div>

              <div className="col-12 col-sm-6 mb-3">
                <SelectInput
                  placeholder={t("Select the Brand")}
                  option={optionBrand}
                  getValue={setbrands}
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <SelectInput
                  placeholder={" * " + t("Select products types")}
                  option={optionTypes}
                  getValue={settypeProdect}
                />
              </div>
              <div className="col-12 col-sm-6 mb-3 ">
                <SelectInput
                  placeholder={" * " + t("Select Categorie types")}
                  option={optionCategories}
                  getValue={setCategories}
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <select
                  value={kindProduct}
                  onChange={(e) => {
                    setkindProduct(e.target.value);
                    setForms([{ id: "", qty: "" }]);
                  }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="">{t("*select kind product")}</option>
                  <option value="0">{t("single")}</option>
                  <option value="1">{t("build")}</option>
                </select>
              </div>
              <div className="col-12">
                {kindProduct && kindProduct == 1 && (
                  <CloneForm forms={forms} setForms={setForms} />
                )}
              </div>
              <div className="col-12 col-sm-6  ">
                <InputLogin
                  value={data.barcode}
                  getvslueform={addDataUser}
                  type="text"
                  placeholder={" * " + t("barcode")}
                  name={"barcode"}
                />
              </div>
              <div className="col-12 col-sm-6  ">
                <SelectInput
                  placeholder={" * " + t("Select the tax")}
                  option={optionTax}
                  getValue={setTypeTax}
                />
              </div>
            </div>

            {TypeTax !== null && TypeTax !== 0 ? (
              <div className="mb-3 text-center">
                <form
                  value={typePrice}
                  name="TypePrice"
                  onChange={(e) => setTypePrice(e.target.value)}
                >
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value={1}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      {"*" + t("Inclusive of tax")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio3"
                      value={0}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio3">
                      {"*" + t("Not including tax")}
                    </label>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-6">
                <InputLogin
                  value={data.price}
                  getvslueform={addDataUser}
                  type="number"
                  placeholder={" * " + t("Price")}
                  name={"price"}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  readOnly
                  value={typePrice && typePrice == 0 ? priceTax : data.price}
                  className="form-control"
                  placeholder={t("price with tax")}
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <textarea
                  className="form-control"
                  value={data.desc_ar}
                  onChange={(e) => addDataUser(e)}
                  placeholder={t("description ar")}
                  name="desc_ar"
                  id="exampleFormControlTextarea1"
                  rows="2"
                ></textarea>
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <textarea
                  className="form-control"
                  value={data.desc_en}
                  onChange={(e) => addDataUser(e)}
                  placeholder={t("description en")}
                  name="desc_en"
                  id="exampleFormControlTextarea1"
                  rows="2"
                ></textarea>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="file-input" className="custom-file-upload">
                <input
                  id="file-input"
                  type="file"
                  className="d-none"
                  onChange={handleImageUpload}
                  accept="image/*"
                  capture="camera"
                />
                <MdOutlineAddPhotoAlternate size={40} /> {t("Select Image")}
              </label>
            </div>

            <div className="text-center     d-flex justify-content-between">
              <button
                disabled={buttonDisplay()}
                onClick={() => {
                  hendling();
                  setgoToProduct(true);
                }}
                className="btn red_btn w-50 mx-1 "
              >
                {t("Send")}
                {isplaybutton && goToProduct && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>

              <button
                disabled={buttonDisplay()}
                onClick={() => {
                  hendling();
                  setgoToProduct(false);
                }}
                className="btn red_btn w-50 mx-1"
              >
                {t("Send and new")}{" "}
                {isplaybutton && !goToProduct && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </div>
          <div className="col-12 col-md-4 order-1 order-md-2  mt-4">
            <Card lang={lang} data={data} user={user} image={image} t={t} />
          </div>
        </div>
      </div>
    </section>
  );
}
