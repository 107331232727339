import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavComp from "../../layouts/NavComp/NavComp";
import "./style.css";
import { FiMoreVertical } from "react-icons/fi";
import { BsWallet2 } from "react-icons/bs";
import { SiAirtable } from "react-icons/si";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  nearest_pos,
  session_details,
} from "../../services/redux/action/action";
import SpinnerLoding from "../../components/SpinnerLoding/SpinnerLoding";
import CustomAlert from "../../components/customeAlert/CustomAlert";
import DefouildImg from "../../asssets/images/VODOLOGO-card.png";

export default function HomePage() {
  const [locatinUSer, setlocatinUSer] = useState("");
  const [clickedActive, seclickedActive] = useState(true);
  const options2 = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    const crd = pos.coords;

    setlocatinUSer({ long: crd.longitude, lat: crd.latitude });
  }

  function error(error) {}
  const user = JSON.parse(sessionStorage.getItem("user"));
  const login = useSelector((state) => state.login);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, error, options2);
    if (user && user.data) {
      dispatch(
        session_details({ last_session: 1, user_id: user.data.user_id })
      );
    }
  }, [login]);
  const navgite = useNavigate();
  const dispatch = useDispatch();

  const getNamePosition = () => {
    dispatch(nearest_pos(user.data.user_id));
    seclickedActive(false);
  };
  const nearestpos = useSelector((state) => state.nearest_pos);
  const openref = useRef(null);
  const closeRef = useRef(null);
  useEffect(() => {
    if (
      nearestpos &&
      nearestpos.status &&
      nearestpos.message !== "error" &&
      nearestpos.data.current_pos
    ) {
      navgite("/Session");

      sessionStorage.setItem("nearestpos", JSON.stringify(nearestpos));
    } else if (
      nearestpos &&
      nearestpos.status &&
      nearestpos.message !== "error" &&
      nearestpos.data.current_pos.length < 1
    ) {
      seclickedActive(true);
      openref.current.click();
      setTimeout(() => {
        closeRef.current.click();
      }, 5000);
    } else if (
      nearestpos &&
      nearestpos.status &&
      nearestpos.message === "error"
    ) {
      seclickedActive(true);
      openref.current.click();
      setTimeout(() => {
        closeRef && closeRef.current.click();
      }, 5000);
    }
  }, [nearestpos]);
  let navget = useNavigate();
  let LastSession = useSelector((state) => state.session_details);
  const open_the_session = (data) => {
    sessionStorage.setItem("open_session", JSON.stringify(data));
    dispatch({
      type: "open_session",
      open_session: {
        status: true,
        message: "success",
        data: {
          pos_session: data,
        },
      },
    });

    navget("/session");
  };
  const replaceImage = (error) => {
    error.onerror = null; // prevents looping
    // check if the logo image exists
    const img = new Image();
    img.onerror = () => {
      // if the logo image is not found, use a placeholder image or an empty string
      error.target.src = ""; // or ''
    };
    img.src = DefouildImg;

    // if the logo image is found, use it as the replacement image
    error.target.src = DefouildImg;
  };
  return (
    <>
      <section className="HomePage  ">
        <CustomAlert closeRef={closeRef} openref={openref}>
          {nearestpos && nearestpos.message === "error"
            ? nearestpos.data.message
            : t("masseg_error_position")}
        </CustomAlert>
        <NavComp />
        {/* <div className="div_lift_Filter position-absolute fs-5">
      <div className="shadow my-3 p-2"> <AiTwotoneFilter/> {t("Filter")}</div>
      <div className="shadow my-4 p-2"> <IoMdList/> {t("GroupBy")}</div>
      <div className="shadow my-3  p-2"> <AiFillStar />  {t("Favorite")}</div>
    </div> */}
        <div className="container-xxl p-3">
          <div className="shadow   text-center    m-auto my-3 item-session rounded-4">
            <div className="row">
              <div className="col-3  d-flex title_cart  align-items-center justify-content-center">
                <img
                  onError={replaceImage}
                  width={"100%"}
                  src={user && user.data.business_logo}
                  alt=""
                />
              </div>
              <div className="col-9 p-2 ">
                <div>
                  <div className="dropdown dropdown_view_session text-end">
                    <span
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FiMoreVertical size={25} />
                    </span>
                    <ul
                      className="dropdown-menu dropSession shadow-sm border-0"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <Link
                          className="dropdown-item text-center py-2"
                          to={"/AllSession"}
                        >
                          {t("AllSession")}{" "}
                          <span className="icon_red">
                            <BsWallet2 />
                          </span>{" "}
                        </Link>
                      </li>
                      <hr className="m-1" />
                      <li>
                        <Link
                          className="dropdown-item text-center py-2"
                          to={"/TabelSetting"}
                        >
                          {t("TabelSetting")}{" "}
                          <span className="icon_red">
                            <SiAirtable />
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {LastSession.status ? (
                  <>
                    {LastSession.data.session.pos ? (
                      <>
                        <div className="  my-1 d-flex  fs-5 flex-column flex-sm-row   align-items-center">
                          <p className="fw-bold mb-1"> {t("LastDateOpen")} </p>
                          <p className="  px-2 mb-1">
                            {LastSession.data.session &&
                              LastSession.data.session.open_date}
                          </p>
                        </div>
                        <div className="  my-1 d-flex  fs-5 flex-column flex-sm-row   align-items-center">
                          <p className="fw-bold  m-0"> {t("LastDate")} </p>
                          <p className="  px-2 mb-1">
                            {LastSession.data.session &&
                              (LastSession.data.session.close_date
                                ? LastSession.data.session.close_date
                                : LastSession.data.session.status)}
                          </p>
                        </div>
                        <div className="  my-1 d-flex  fs-5  flex-column flex-sm-row  align-items-center">
                          <p className="fw-bold mb-1"> {t("LastCash")} </p>
                          <p className="px-2 mb-1">
                            {LastSession.data.session &&
                              LastSession.data.session.cash_total}
                          </p>
                        </div>

                        <div className="  my-1 d-flex  fs-5  flex-column flex-sm-row  align-items-center">
                          <p className="fw-bold mb-1"> {t("Balance")} </p>
                          <p className="px-2 mb-1">
                            {" "}
                            {LastSession.data.session &&
                              LastSession.data.session.payments_total}
                          </p>
                        </div>
                        <div className="  my-1 d-flex  fs-5  flex-column flex-sm-row  align-items-center">
                          <p className="fw-bold mb-1"> {t("pos")} </p>
                          <p className="px-2 mb-1">
                            {" "}
                            {LastSession.data.session &&
                              LastSession.data.session.pos}
                          </p>
                        </div>
                      </>
                    ) : (
                      <h3 className="my-5"> {t("It's your first session")}</h3>
                    )}
                    <div className=" text-end NewSession_btn">
                      {clickedActive ? (
                        <div>
                          {LastSession.data.pos_session.business_id && (
                            <button
                              onClick={() =>
                                open_the_session(LastSession.data.pos_session)
                              }
                              className="btn red_btn m-2 py-1 fs-6 rounded-pill"
                            >
                              {t("Continuation")}
                            </button>
                          )}
                          <button
                            onClick={() => {
                              getNamePosition();
                            }}
                            className="btn dark_btn m-2 py-1 fs-6 rounded-pill"
                          >
                            {t("NewSession")}
                          </button>
                        </div>
                      ) : (
                        <SpinnerLoding />
                      )}
                    </div>
                  </>
                ) : (
                  <SpinnerLoding />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
