import { t } from 'i18next'
import React from 'react'
import FooterInPrint from '../FooterInPrint/FooterInPrint'
import HeaderInPrint from '../HeaderInPrint/HeaderInPrint'

export default function OrdersSessionTabel({allOrderSession,componentRef ,children}) {
  return (
    <div  ref={componentRef} >
   
    <table className="table   table-hover  fs-5 ">
    
    <thead>
      <tr className='header_print'>
        <td colSpan={7} >
        <HeaderInPrint titel={"Orders"}/> 
        </td>
      </tr>
        <tr>
          
         <th>{t("OrderRef")}</th>
        <th>{t("Date")}</th>
        <th> {t("receiptNumber")}  </th>
        <th> {t("Customer")} </th>
        <th> {t("Employee")}</th>
        <th> {t("total")} </th>
        <th> {t("Status")} </th>
        </tr>
    </thead>
    <tbody>
  {
    
 allOrderSession && allOrderSession.data && allOrderSession.data.orders && allOrderSession.data.orders.length > 0 ?
 allOrderSession.data.orders.map(item =>(
     <tr key={item.id} >
            <td> pos {item.reference} </td>
            <td> {item.date}    </td>
            <td> {item.receipts_num} </td>
            <td> {item.customer} </td>
            <td> {item.user} </td>
            <td> {item.total} </td>
 
            <td > <span className='alert alert-primary m-0 p-1'>{item.status}</span>  </td>
        </tr>
 ))

 :<tr><td colSpan={7} className={"text-center"}>{t("no_data")}</td> </tr> }
    </tbody>
    <tfoot>
      <tr  className='footer_print' >
        <td colSpan={7}>
          <FooterInPrint/>
        </td>
      </tr>
    </tfoot>
</table>
    </div>
  )
}
