import React, { useState } from "react";
import VODOLOGO from "../../asssets/images/VODOLOGO.png";
  import './style.css';
import { Link } from "react-router-dom";
 import { useDispatch, useSelector } from "react-redux";
import { t, use } from "i18next";
import UlListNavbar from "./UlListNavbar";
import BtnFullscreen from "./BtnFullscreen";
import InputSearsh from "./InputSearsh";
import DropdownUser from "./DropdownUser";
import { cash_accounts_list, get_session_totals } from "../../services/redux/action/action";
  const logout = () => {
  sessionStorage.removeItem("user")
  sessionStorage.removeItem("userisLogin")
  window.location.reload();
}
function Navbar() {
   const [full_screen,setFull_screen]= useState(false)
  const element = document.querySelector("html");
  const orders_Suspension = useSelector(state => state.orders_Suspension)
  const orders_Suspension_local = JSON.parse(sessionStorage.getItem("orders_Suspension")) 
  const open__session = useSelector(state => state.open_session)
  let countOrder = orders_Suspension && orders_Suspension.length > 0 ?orders_Suspension.length :orders_Suspension_local && orders_Suspension_local.length 
  // full screen function to check if full screen or no
  document.addEventListener('fullscreenchange', function() {
    var full_screen_element = element.fullscreenElement;
    if(full_screen_element !== null){
      setFull_screen(!full_screen)
      sessionStorage.setItem("Full_screen_Boolean",!full_screen )
    }
    }	
   )
   
    const userLogn = JSON.parse(sessionStorage.getItem("user")); 
     let OpenSession =open__session && open__session.status ?open__session.data : JSON.parse(sessionStorage.getItem("open_session"));
const desiptch = useDispatch()
let sessionLocal =useSelector(state => state.open_session)

let session =(sessionLocal && sessionLocal.status) ? sessionLocal.data.pos_session: JSON.parse(sessionStorage.getItem("open_session"))

     const getTotalsession =()=>{
      if(session){
        desiptch(get_session_totals(session.id))
       }
 
}
   return (
    <>
      {/* /////////////////////////////////////// */}
      <nav className="navbar navbar-expand-lg  ">
  <div className="container-fluid">
  <Link className="logo navbar-brand"  >  <img alt="logo" src={VODOLOGO} /> </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarText">
      <UlListNavbar OpenSession={OpenSession} countOrder={countOrder}/>
      <BtnFullscreen full_screen={full_screen}/>
      <InputSearsh/>
      <div className="navbar-text d-flex     align-items-center">
   <DropdownUser OpenSession={OpenSession}  userLogn={userLogn} logout={logout}/>
     {OpenSession  &&  <button onClick={getTotalsession} type="button" className=" mx-2 btn red_btn py-1  fs-6" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                {t("Close")}
        </button>  }
      </div>
    </div>
  </div>
</nav>
    </>
  );
}

export default Navbar;

