import React from "react";

export const DiscHook =(price,disc) =>{
 
    let originalPrice = price;
    let discountPercentage = disc;
    let discountAmount = (originalPrice * discountPercentage) / 100;
    let discountedPrice = originalPrice - discountAmount;
  

   return [discountedPrice]
}