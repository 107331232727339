import React from "react";
import { useEffect } from "react";
import { memo } from "react";
import { useState } from "react";
import useAddCartWithEvent from "../../hooks/useAddCartWithEvent";
import i18next, { t } from "i18next";
import CartEvents from "../carts/CartEvents";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { events } from "../../services/redux/action/action";
import SpinnerLoding from "../SpinnerLoding/SpinnerLoding";
const SelectEvent = memo(
  ({
    refEvent,
    setactiveid,
    setTextVal,
    setcartid,
    itemProdect,
    setChange,
    setCount,
    setitemActive,
  }) => {
    const dispatch = useDispatch();
    const [event, setEvent] = useState("");
    const lang = i18next.language;
    useEffect(() => {
      setEvent("");
      dispatch({ type: "events", events: [] });
      dispatch(events(itemProdect.id, lang));
    }, [itemProdect, lang]);
    const eventdata = useSelector((state) => state.events);

    const [additemfun] = useAddCartWithEvent();

    const addEvent = () => {
      if (event) {
        let eventdedals = eventdata.data.filter(
          (item) => item.reference == event
        );
        additemfun(
          setactiveid,
          setTextVal,
          setcartid,
          itemProdect,
          setChange,
          setCount,
          eventdedals,
          setitemActive
        );
      }
    };

    return (
      <>
        <button
          ref={refEvent}
          type="button"
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#SelectEvent"
        ></button>

        <div
          className="modal fade"
          id="SelectEvent"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="SelectEventLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg  modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="SelectEventLabel">
                  {" "}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body  ">
                <h2 className="text-center">{t("Select th Event")}</h2>
                <div className="row row-cols-sm-2 row-cos-1">
                  {" "}
                  {eventdata && eventdata.status ? (
                    eventdata.data.map((eventItem) => (
                      <div className=" mt-2 " key={eventItem.reference}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="options-outlined"
                          id={`danger-${eventItem.reference}`}
                          value={eventItem.reference}
                          onChange={(e) => setEvent(e.target.value)}
                          disabled={eventItem.active_tickets < 1}
                          checked={event == eventItem.reference}
                        />
                        <label
                          className="btn w-100 btn-outline-secondary"
                          htmlFor={`danger-${eventItem.reference}`}
                        >
                          {<CartEvents event={eventItem} />}
                        </label>
                      </div>
                    ))
                  ) : (
                    <SpinnerLoding />
                  )}
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {t("Close")}
                </button>
                <button
                  type="button"
                  className="mx-2 btn red_btn py-1  fs-6"
                  data-bs-dismiss="modal"
                  onClick={addEvent}
                  disabled={!event}
                >
                  {t("Add to Cart")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default SelectEvent;
