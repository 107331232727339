import React, { useEffect, useState } from "react";
import {  Link, useNavigate } from "react-router-dom";
import { GrPowerReset ,GrSelect} from "react-icons/gr"
 import { MdCompareArrows } from "react-icons/md"
 import "./style.css"
 import { Helmet } from 'react-helmet-async';

 import { useDispatch, useSelector } from "react-redux";
import { AddCart, Listprodect, pos_order_detail, pos_session_orders_list } from "../../services/redux/action/action";
import Calculatorfun from "../../components/Calculator/Calculator";
 import { useTranslation } from "react-i18next";
import PaginateComp from "../../components/PaginateComp/PaginateComp";
import AsideRefund from "../../layouts/asideRefund/AsideRefund";
import { AiOutlineDoubleRight } from "react-icons/ai"
import { AiOutlineDoubleLeft } from "react-icons/ai"
import RefundTabel from "../../components/AllTabels/RefundTabel";
import FiGrFavComp from "../../components/FiGrFavComp/FiGrFavComp";
import SpinnerLoding from "../../components/SpinnerLoding/SpinnerLoding";
import { use } from "i18next";
const Refund = () => {
  const [counts, setCount] = useState(1);
  const [massge, setmassge] = useState("")
  const [ProdectsOrder, setProdectsOrder] = useState([])
  const [Actives, setActives] = useState("")
  const [startDate,setstartDate] = useState("")
  const [endDate,setendDate] = useState("")
  const [active, setActive] = useState(null);
  const [updateData,setupdateData] = useState(1)
  const [textVal, setTextVal] = useState("Qty")
  const [page,setpage] = useState(1)
   const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let sessionopen = JSON.parse(sessionStorage.getItem("open_session"))
 const valueSerssh= useSelector(state => state.ValueSearch)
const user = JSON.parse(sessionStorage.getItem("user"))
useEffect(()=>{
  if(user && Number(user.data.pos_refund) !== 1){
    navigate("/")
  }
},[])
 useEffect(()=>{
  dispatch(pos_order_detail(Actives))
 },[Actives])
 let posOrderDetail = useSelector(state =>state.pos_order_detail)
 
 useEffect(()=>{
   if(posOrderDetail && posOrderDetail.status){
    setProdectsOrder(posOrderDetail.data.items)
   }
 },[posOrderDetail])
  useEffect(()=>{
    if(sessionopen){
      dispatch(pos_session_orders_list(
            { 
            page:page,
            filter_reference:valueSerssh,
            filter_from_date:startDate,
            filter_to_date:endDate
        }
        ))
    }
},[page,startDate,endDate,valueSerssh])
   const path = window.location.origin;
 
  
    const RefoundOrder =() =>{
      const items =ProdectsOrder ?  ProdectsOrder.filter((item) => item.order_refund):[];
 
        if( items.length < 1){
          setmassge(t("masseg_Qyn"))
        
        } else{
          sessionStorage.setItem("Refund",JSON.stringify(Actives))
          setmassge("")
          items.forEach((item) => {item.order =  item.order_refund ;item.final_price  = item.final_price  * -1})
          dispatch(AddCart(items))
          navigate(`/Session`)
        }
      
    }
const allOrderSession = useSelector(status => status.pos_session_orders_list )
 const selectAll  =()=>{
  if(posOrderDetail.status){
    ProdectsOrder && ProdectsOrder.forEach((item,index) =>{
    ProdectsOrder[index]["order_refund"] = (Number(ProdectsOrder[index]["order"]) - Number(ProdectsOrder[index]["refund_amount"]))   ;
    if(ProdectsOrder[index]["order_refund"] < 1){
      delete ProdectsOrder[index]["order_refund"]
     }
    dispatch(AddCart(ProdectsOrder))
  })
  }
 
 }
 const resetAll = () => {
  if(posOrderDetail.status){
    ProdectsOrder && ProdectsOrder.forEach((item,index) =>{
    delete ProdectsOrder[index]["order_refund"]   ;
    dispatch(AddCart(ProdectsOrder))
  })
  }
 }

 const select_all_one_Item  =()=>{
  if(active){
    const  index = ProdectsOrder.findIndex(item => item.id == active) ;
    ProdectsOrder[index]["order_refund"] = Number(ProdectsOrder[index]["order"]) - Number(ProdectsOrder[index]["refund_amount"])   ;
   if(ProdectsOrder[index]["order_refund"] < 1){
    delete ProdectsOrder[index]["order_refund"]
   }
    dispatch(AddCart(ProdectsOrder))
 
  }
  }
 
 const reset_one_item = () => {
  if(active){
    const  index = ProdectsOrder.findIndex(item => item.id == active) ;
     delete ProdectsOrder[index]["order_refund"]  ;
    dispatch(AddCart(ProdectsOrder))
 
  }
 }

   return (
    <>
      <Helmet>
        <title>Refund </title>
        <link rel="canonical" href={path} />
        <meta
          name="description"
          content="A page showing your products that are in your store"
        />
      </Helmet> 

      <div className="  container-xxl">
    
         <div><Link to={"/Session"} className="btn red_btn py-1 mb-2">  {lang == "en" ? <AiOutlineDoubleLeft />: < AiOutlineDoubleRight />} {t("back")} </Link></div>
         <FiGrFavComp setstartDate={setstartDate} setendDate={setendDate}/>
        <div className="row m-auto ">
          
        <div className="content_orders  p-0     d-flex flex-column justify-content-between  col-12 col-md-8 ">
          <div className=' p-3 m-1  div_tabel shadow   overflow-auto'>
         {allOrderSession && allOrderSession.status ?  <RefundTabel data={allOrderSession.data.orders} Actives={Actives} setProdectsOrder={setProdectsOrder} setActives={setActives}/>:<SpinnerLoding/>}
          </div>
          <div className="d-flex justify-content-center">
          {allOrderSession && allOrderSession.status &&allOrderSession.data && <PaginateComp setnumberPage={setpage} pageCounts={allOrderSession.data.pages_number} />}
          </div>
          </div>
        <aside className="col-12 col-md-4 ">
            
            <AsideRefund active={active} setActive={setActive} updateData={updateData}  massge={massge} prodect={ProdectsOrder} setCount={setCount} setTextVal={setTextVal}  textVal={textVal}  counts={counts} />
            <div className="d-flex w-100 flex-column align-items-end">
              <div className="calc-fun w-100 position-relative ">
                <Calculatorfun setCount={setCount} />
                <div style={{ bottom: "25%" }} className="functions position-absolute">
                  <button name="Qty" className={"active calculator-key"}  >{t("Qty")}</button>
                  <button   className={'calculator-key'} onClick={(item) =>{  select_all_one_Item(); setupdateData(state => state + 1)}} >{t("All")}</button>
                  <button  name="Price"     className={'calculator-key'} onClick={(item) => {  reset_one_item() ; setupdateData(state => state + 1)}} >{t("Reset")}</button>
                </div>
              </div>
              <div className="w-100  d-flex">
                <div className="user-item  w-100 text-center ">
              
                    <div className="link cursor_pointer  "   onClick={() => RefoundOrder()}>
                      <span className="user-icon "><MdCompareArrows /> </span>
                      <p className="paym-text mb-1"> {t("Refund")}</p>
                    </div>
                 
                </div>
                <div className="user-item  w-100 text-center ">
              
              <div className="link cursor_pointer  "   onClick={() => {resetAll();setupdateData(state => state + 1)}}>
                <span className="user-icon "><GrPowerReset /> </span>
                <p className="paym-text mb-1"> {t("Reset All")}</p>
              </div>
           
          </div>
          <div className="user-item  w-100 text-center ">
              
              <div className="link cursor_pointer  "   onClick={() => {selectAll();setupdateData(state => state + 1)}}>
                <span className="user-icon "><GrSelect /> </span>
                <p className="paym-text mb-1"> {t("Select All")}</p>
              </div>
           
          </div>
              </div>

            
            </div>
          </aside>
        
        </div>
      
      </div>




    </>
  );
}

export default Refund;
