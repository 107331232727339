import React from 'react'
import Select from "react-select";

export default function SelectInput({option,getValue,placeholder,defouldValue,isMulti}) {
  return (
    <Select
    className="basic-single"
    classNamePrefix={"select"}
    isSearchable={true}
     options={option}
    isMulti={isMulti}
    isClearable={true}
    placeholder={placeholder && placeholder}
   value={defouldValue && defouldValue}
    onChange={(data) => getValue(isMulti ?data :( data && data.value))}
  />
  )
}
