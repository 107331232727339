import { t } from 'i18next';
import React from 'react'
 import { useEffect } from 'react';
import { useCallback } from 'react';
 import { useDispatch, useSelector } from 'react-redux';
import InputCalc from '../../components/InputCalc/InputCalc';
import { currency_categories_list } from '../../services/redux/action/action';
import SpinnerLoding from '../SpinnerLoding/SpinnerLoding';
import "./style.css";
export default function InputCalcTotal({setShowCalc,setTotalSession,setdetailsTotal,detailsTotal,setDataListTotal,dataListsTotal}) {
        const changeHandler = (e) => {
        setdetailsTotal({
          ...detailsTotal,
          [e.target.name]: Number(e.target.value) * Number(e.target.dirName),
        });
        const index = dataListsTotal.findIndex(obj => Object.values(obj).includes(e.target.name));
  if(index === -1){
    setDataListTotal([
      ...dataListsTotal,
      {name:e.target.name ,"value": Number(e.target.value)}
    ])
  }else{
    dataListsTotal[index].value = Number(e.target.value)
  }
      
      };
        const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(currency_categories_list())
  },[dispatch])
const currencyCategoriesList = useSelector(state => state.currency_categories_list)
   const TotalPrice = useCallback(() => {
    let total = 0;
    Object.keys(detailsTotal).forEach((key) => {
      total += Number(detailsTotal[key]);
    });

    return total;
  }, [detailsTotal]);
  
  return (
  <>
   <div className="calc_div position-absolute w-75 px-4 py-3">
                      <div className="shadow back_light  ">
                      <div className=" row h_input_calc">
                            {currencyCategoriesList && currencyCategoriesList.status ? currencyCategoriesList.data.value
.map((item,index) => (
                              <div key={index} className="col-12 col-sm-6">
                            <InputCalc
                             changeHandler={changeHandler}
                             nameInput={item}
                            LabelNAme={item}
                           />
                           </div>
                        )):
                        <SpinnerLoding/>
                        }
                     </div>
                        <div className="  text-center fs-4 fw-bold div_total py-4 rounded-5 my-2">
                          {t("total")}  {TotalPrice() === 0 ? "00.0" : TotalPrice().toFixed(2)}
                        </div>
                        <div className="d-flex align-items-center justify-content-around">
                          <button
                            onClick={() => {
                              setTotalSession(TotalPrice().toFixed(2));
                              setShowCalc(false);
                            }}
                            className="btn dark_btn"
                          >
                            {t("Confirm")}
                          </button>
                          <button
                            onClick={() => {setShowCalc(false);setdetailsTotal([])}}
                            className="btn red_btn"
                          >
                            {t("Discard")}
                          </button>
                        </div>
                      </div>
                    </div>
  </>
  )
}
