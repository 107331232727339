import React from 'react'
import { AddCart } from '../services/redux/action/action';
import { useDispatch } from 'react-redux';

export default function useAddCartWithEvent() {
    const dispatch = useDispatch();
    const user = JSON.parse(sessionStorage.getItem("user"));

    const addCartfun = (setactiveid, setTextVal, setcartid, items, setChange, setCount, event, setitemActive) => {
        let updateItem = {
            ...items,
            event: event[0]
        }
        const localcard = JSON.parse(sessionStorage.getItem("items")); //get itme
        setactiveid(items.id); // to active
        setitemActive(updateItem)
        setTextVal("Qty"); // to activ qty

        let refuntCheck =
            localcard && localcard.length > 0 && localcard[0]["final_price"] < 0
                ? false
                : true;

        if (refuntCheck) {
            if (localcard) {
                if (localcard.find((product) => (product.id === updateItem.id && updateItem?.event?.reference === product?.event?.reference))) {
                    setcartid(updateItem.id);
                    const index = localcard.findIndex((item) => item.id === updateItem.id && updateItem?.event?.reference === item?.event?.reference);
                    if (
                        localcard[index]["final_price"] > 0 &&
                        ((
                            localcard[index].event?.active_tickets > localcard[index]["order"])
                        )
                    ) {
                        localcard[index]["order"] += 1;
                        dispatch(AddCart(localcard));
                        document.querySelector(".scanner_sound")?.play();
                    }
                } else {
                    dispatch(AddCart([...localcard, updateItem]));
                    document.querySelector(".scanner_sound")?.play();
                }
            } else {
                dispatch(AddCart([updateItem]));
                document.querySelector(".scanner_sound")?.play();
            }
        }
        setChange(false);
        setCount(0);
    }
    return [addCartfun]
}
