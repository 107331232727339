import i18next, { t } from "i18next";
import React, { useEffect } from "react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.css";
import ar from "react-phone-input-2/lang/ar.json";
import es from "react-phone-input-2/lang/es.json";
import { useDispatch, useSelector } from "react-redux";
import { add_customer } from "../../services/redux/action/action";
import NavComp from "../../layouts/NavComp/NavComp";
import { useNavigate } from "react-router-dom";
import InputLogin from "../InputLogin/InputLogin";

export default function AddCustomerComp({ children }) {
  const [phone, setPhone] = useState("");
  const [showSucss, setshowSucss] = useState(false);
  const lang = i18next.language;
  const [data, setData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    CompanyName: "",
    TaxNumber: "",
    YypeUser: "",
  });
  const user = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useNavigate();
  useEffect(() => {
    if (user && Number(user.data.pos_add_customer) !== 1) {
      navigate("/");
    }
  }, []);
  const addDataUser = (e) => {
    setData({
      ...data,
      [e && e.target.name]: e && e.target.value,
      PhoneNumber: `+${phone}`,
    });
  };
  let dispatch = useDispatch();
  const hendling = (e) => {
    e.preventDefault();

    dispatch(
      add_customer({
        first_name: data.FirstName,
        vat_number: data.TaxNumber,
        business_id: user.data.business_id,
        email: data.Email,
        phone: data.PhoneNumber,
        company_name: data.CompanyName,
      })
    );
  };
  const dataResuilt = useSelector((state) => state.add_customer);
  useEffect(() => {
    if (dataResuilt && dataResuilt.status === 1) {
      setshowSucss(true);
      setData({
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        CompanyName: "",
        TaxNumber: "",
        YypeUser: "/",
      });
      dispatch({ type: "add_customer", add_customer: [] });
      setTimeout(() => {
        setshowSucss(false);
      }, 4000);
    }
  }, [dataResuilt]);

  return (
    <>
      {children && children}
      {showSucss && (
        <div className="  alert_div     d-flex align-items-center justify-content-center ">
          <div className="fs-5 alert alert-success" role="alert">
            {t("Add_clint_mssag")}
          </div>
        </div>
      )}
      <div className="container mt-5 addCustomer">
        <form className="shadow p-4 rounded-5" onSubmit={hendling}>
          <h2 className="text-center">{t("Add Customer")}</h2>
          <div className="row">
            <div className="col-12 mt-2 mb-2 col-md-6">
              <select
                value={data.YypeUser}
                name="YypeUser"
                onChange={(e) => addDataUser(e)}
                className="form-select"
                aria-label="Default select example"
              >
                <option value={""}>{t("select type user")}</option>
                <option value="Client">{t("Client")}</option>
                <option value="Company">{t("Company")}</option>
              </select>
            </div>
            <div className="col-12 mt-2 mb-2 col-md-6">
              <InputLogin
                value={data.FirstName}
                getvslueform={addDataUser}
                type="text"
                placeholder={t("your Name")}
                name={"FirstName"}
              />
            </div>

            <div className="col-12 mt-2 mb-2 col-md-6">
              <InputLogin
                value={data.Email}
                getvslueform={addDataUser}
                type="email"
                placeholder={t("Email")}
                name={"Email"}
              />

              {dataResuilt &&
                dataResuilt.status === 2 &&
                dataResuilt.msg.email && (
                  <div className="text_red">{dataResuilt.msg.email}</div>
                )}
            </div>

            <div className="col-12 mt-2 mb-2 col-md-6">
              <PhoneInput
                country={"sa"}
                localization={lang === "ar" ? ar : es}
                value={phone}
                onChange={(e) => {
                  setPhone(e);
                  addDataUser();
                }}
                inputProps={{
                  name: "PhoneNumber",
                  required: true,
                  autoFocus: true,
                  class: "form-control",
                }}
              />
              {dataResuilt &&
                dataResuilt.status === 2 &&
                dataResuilt.msg.phone && (
                  <div className="text_red">{dataResuilt.msg.phone}</div>
                )}
            </div>

            {data.YypeUser === "Company" && (
              <>
                <div className="col-12 mt-3 mb-2 col-md-6">
                  <InputLogin
                    value={data.CompanyName}
                    getvslueform={addDataUser}
                    type="text"
                    placeholder={t("Company Name")}
                    name={"CompanyName"}
                  />
                </div>
                <div className="col-12 mt-3 mb-2 col-md-6">
                  <InputLogin
                    value={data.TaxNumber}
                    getvslueform={addDataUser}
                    type="text"
                    placeholder={t("Tax Number")}
                    name={"TaxNumber"}
                  />
                </div>
              </>
            )}
          </div>

          <div className="text-center mt-3  ">
            <button
              disabled={
                (data.YypeUser === "Company" && !data.CompanyName
                  ? true
                  : false) ||
                data.PhoneNumber.length < 7 ||
                !data.YypeUser
              }
              type="submit"
              className="btn red_btn px-5"
            >
              {t("Create")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
