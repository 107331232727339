import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Selectuser from "../../components/selectUser/selectUser";
import "./style.css";
import { t } from "i18next";
import { ListUser } from "../../services/redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import PaginateComp from "../../components/PaginateComp/PaginateComp";
export default function SelectUserToPay() {
  const [user, setuser] = useState("");
  const dispatch = useDispatch();
  const navigat = useNavigate();
  const location = useLocation();
  const [numberPage, setnumberPage] = useState(1);
  const valueSearch = useSelector((state) => state.ValueSearch);
  useEffect(() => {
    setnumberPage(1);
  }, [valueSearch]);
  useEffect(() => {
    dispatch(ListUser(numberPage, valueSearch));
  }, [numberPage, valueSearch]);
  const data = useSelector((state) => state.users);

  let userLocal = JSON.parse(sessionStorage.getItem("user"));
  useEffect(() => {
    if (userLocal && Number(userLocal.data.pos_choose_customers) !== 1) {
      navigat("/");
    }
  }, []);
  const SetRefund = () => {
    navigat("/Refund");
    sessionStorage.setItem("customer", JSON.stringify(user));
  };
  // const SetSession = () => {
  //   sessionStorage.setItem("customer", JSON.stringify(user));
  //   navigat("/Session");
  // };
  // const SetPayment = () => {
  //   navigat("/Payment");
  //   sessionStorage.setItem("customer", JSON.stringify(user));
  // };
  const hendlarUser = () => {
    if (user) {
      navigat(`/${location.state}`);
      sessionStorage.setItem("customer", JSON.stringify(user));
    }

    // if (user) {
    //   switch (location.state) {
    //     case "Session":
    //       SetSession();
    //       break;
    //     case "Refund":
    //       SetRefund();
    //       break;
    //     case "Payment":
    //       SetPayment();
    //       break;
    //     case "Payment":
    //       SetPayment();
    //       break;
    //     default:
    //       SetSession();
    //       break;
    //   }
    // }
  };
  return (
    <section className=" SelectUserToPay">
      <div className="container">
        <div className="d-flex     align-items-center justify-content-between">
          <button onClick={hendlarUser} className="btn red_btn py-1 m-1">
            {t("Set user")}
          </button>
          <Link
            to={`/${location.state ? location.state : "Session"}`}
            className="btn dark_btn py-1 m-1 "
          >
            {t("Discard")}
          </Link>
        </div>

        <div className=" height_users_div">
          <Selectuser setuser={setuser} data={data} />
        </div>
        <div
          style={{ bottom: "0px" }}
          className="d-flex justify-content-center    mt-3  "
        >
          {data.status && (
            <PaginateComp
              pageCounts={data.data.last_page}
              setnumberPage={setnumberPage}
            />
          )}
        </div>
        <div className="d-flex     align-items-center justify-content-center">
          <Link to={`/AddCustomerSection`} className="btn red_btn py-1 m-1 ">
            {t("Add Customer")}
          </Link>
        </div>
      </div>
    </section>
  );
}
