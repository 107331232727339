import React from "react";
import { Link, useParams } from "react-router-dom";
import NavComp from "../../layouts/NavComp/NavComp";
import "./style.css";
import PaginateComp from "../../components/PaginateComp/PaginateComp";
import FiGrFavComp from "../../components/FiGrFavComp/FiGrFavComp";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  pos_session_orders_list,
  pos_session_orders_list_all,
} from "../../services/redux/action/action";
import { useState } from "react";
import SpinnerLoding from "../../components/SpinnerLoding/SpinnerLoding";
import PrintSession from "../../components/PrintSession/PrintSession";
import { useRef } from "react";
import OrdersSessionTabel from "../../components/AllTabels/OrdersSessionTabel";
export default function OrdersSession() {
  const despitch = useDispatch();
  const [page, setpage] = useState(1);
  const param = useParams();
  const [Idpos, setidpos] = useState("");

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const valueSerssh = useSelector((state) => state.ValueSearch);
  useEffect(() => {
    if (param.id) {
      despitch(
        pos_session_orders_list({
          pos_session_id: param.id,
          page: page,
          filter_reference: valueSerssh,
          filter_from_date: startDate,
          filter_to_date: endDate,
        })
      );
    }
  }, [param, page, startDate, endDate, valueSerssh]);

  useEffect(() => {
    if (param.id) {
      despitch(
        pos_session_orders_list_all({
          pos_session_id: param.id,

          filter_reference: valueSerssh,
          filter_from_date: startDate,
          filter_to_date: endDate,
        })
      );
    }
  }, [param, startDate, endDate, valueSerssh]);

  const allOrderSession = useSelector(
    (status) => status.pos_session_orders_list
  );
  const posSessionOrdersListAll = useSelector(
    (status) => status.pos_session_orders_list_all
  );
  const componentRef = useRef();

  return (
    <>
      <section className="AllSession">
        <NavComp />
        <div className="container-xxl">
          <div className="d-sm-flex mt-3    justify-content-between">
            <nav aria-label="breadcrumb ">
              <ol className="breadcrumb fs-4">
                <li className="breadcrumb-item">
                  <Link to={"/"}>{t("PointOfSale")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/AllSession"}>{t("Sessions")} </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/Sessions/${param.id}`}>pos {param.id}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t("Orders")}
                </li>
              </ol>
            </nav>
            <div className="d-flex justify-content-end">
              {allOrderSession &&
                allOrderSession.status &&
                allOrderSession.data && (
                  <PaginateComp
                    setnumberPage={setpage}
                    pageCounts={allOrderSession.data.pages_number}
                  />
                )}
            </div>
          </div>
          <FiGrFavComp
            setReset={setidpos}
            setstartDate={setstartDate}
            setendDate={setendDate}
          />

          <PrintSession componentRef={componentRef} />
          <div className="d-none">
            {posSessionOrdersListAll && posSessionOrdersListAll.status && (
              <OrdersSessionTabel
                componentRef={componentRef}
                allOrderSession={posSessionOrdersListAll}
              ></OrdersSessionTabel>
            )}
          </div>
          <div ref={componentRef} className="shadow p-2     overflow-auto">
            {allOrderSession &&
              !allOrderSession.status &&
              allOrderSession.message === "error" && (
                <h5 className="alert alert-danger text-center py-1">
                  {allOrderSession.data}
                </h5>
              )}

            {allOrderSession && allOrderSession.status ? (
              <OrdersSessionTabel allOrderSession={allOrderSession} />
            ) : (
              <SpinnerLoding />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
