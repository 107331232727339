import React from 'react'

export default function InputLogin({name,value,getvslueform,type,placeholder}) {
 
  return (
   <>
    <div className="mb-3">
     <input    value={value} name={name} onChange={(event) => getvslueform(event)} type={type} className="form-control" placeholder={placeholder} aria-label={name} />
    </div>
   </>
  )
}
