import React from 'react'
import "./style.css"
import { AiTwotoneFilter,AiFillStar } from "react-icons/ai";
import { IoMdList } from "react-icons/io";
 import { t } from 'i18next';
 import { FaFilter } from "react-icons/fa";
import { useState } from 'react';
 export default function FiGrFavComp({setstartDate,setendDate,children,setReset}) {
  const [active,setActive]=useState(false)
  const [datestart,setdateStart]=useState('')
  const [dateEnd,setdateEnd]=useState('')

let Today =new Date().toISOString().split('T')[0]
   const resetFun =() =>{
    setdateStart(' ')
    setdateEnd('')
    setstartDate('')
    setendDate('')
    setReset('')
    setActive(!active)
   }
   const hendeling = (e)=>{
    e  && e.preventDefault() 
    setstartDate(datestart)
    setendDate(dateEnd)
  }
  return (
    <>
     <div className="div_lift_Filter_session position-absolute fs-5 d-flex flex-column flex-sm-row">
      <div className="    p-2">
        
        <button onClick={() => setActive(!active)} className={`${active && "activeIconFilter"} accordion-button collapsed`} type="button" data-bs-toggle="collapse" data-bs-target="#FilterTime_collapse" aria-expanded="false" aria-controls="flush-collapseOne">
         <FaFilter size={24} />
        </button>
         </div>
      {/* <div className="  p-2"> <IoMdList/> {t("GroupBy")}</div>
      <div className="   p-2"> <AiFillStar />  {t("Favorite")}</div> */}
    </div>
    <div id="FilterTime_collapse" className="py-2 accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
  
    <form  className="row g-3 m-auto justify-content-center" onSubmit={(e)=>hendeling(e)}>
    
    <div className="col-auto d-flex align-items-center">
      <label htmlFor="staticEmail2" className="my-1 fs-5">{t("From")}</label>
      <input max={dateEnd ? dateEnd : Today}    type="date"  className="form-control"  onChange={(e) => setdateStart(e.target.value)} value={datestart}  id="staticEmail2" />
    </div>
     <div className="col-auto d-flex align-items-center">
      <label htmlFor="inputPassword2 " className='my-2 fs-5'  > {t("To")} </label>
      <input max={Today} min={datestart}  type="date" onChange={(e) => setdateEnd(e.target.value)} value={dateEnd}  className="form-control " id="inputPassword2"  />
    </div>
    <div className="col-auto">
      <button type="submit" disabled={!dateEnd || !datestart} className="btn red_btn py-1 fs-5  ">{t("Confirm")}</button>
    </div>
  </form>
{children}
<div className='text-end'>
<button onClick={()=>{resetFun()}}    className="btn dark_btn p-0 px-2 fs-5  mx-2"
data-bs-toggle="collapse" data-bs-target="#FilterTime_collapse" aria-expanded="false" aria-controls="flush-collapseOne"
>{t("Reset")}</button>

</div>
 </div>
    </>
  )
}
 
