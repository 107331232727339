import React from "react";
import "./style.css";
import { t } from "i18next";
export default function CartEvents({ event }) {
  return (
    <div className="text-start CartEvents">
      <h4 className="text-center title_event fw-bold">{event.title}</h4>
      <div className=" row row-cols-2">
        <p className="m-0 d-flex flex-column align-items-center">
          <span>{t("Start")} </span>
          <span className="data_event"> {event.start}</span>
        </p>
        <p className="m-0 d-flex flex-column align-items-center">
          <span>{t("End")} </span>
          <span className="data_event"> {event.end}</span>
        </p>
      </div>
      <p className="m-0 d-flex flex-column align-items-center">
        {event.active_tickets > 0 ? t("Availability") : t("Not available")} :
        <span className=" "> {event.active_tickets}</span>
      </p>
    </div>
  );
}
