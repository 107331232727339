import React from 'react'
import "./style.css"
import {  FaDollarSign } from "react-icons/fa"
import { IoIosCash ,IoMdList} from "react-icons/io"
import {  AiOutlineMail} from "react-icons/ai"
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { cash_accounts_list } from '../../services/redux/action/action'
export default function UlListNavbar({countOrder,OpenSession}) {


  let user =JSON.parse(sessionStorage.getItem("user"))
  const desiptch = useDispatch();
     const cashinOut =()=>{
      desiptch(cash_accounts_list(user.data.user_id))

     }
   return (
    <>
     <ul className="navbar-nav me-auto mb-2 mb-lg-0 p-0 flex-row     justify-content-center justify-content-center">
   
      {OpenSession ? <> {user && Number(user.data.pos_sessions_transfer) === 1 && <li className="nav-item">
           <button onClick={cashinOut} type="button" className="nav-link  " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">{t("CashIn/Out")} <IoIosCash  /> </button>

        </li>}
        <li className="nav-item">
        <Link type="button" to={"/OrderSuspension"} className="btn nav-link    border border-1  position-relative  ">
              {t("Orders")}   
               <span className="position-absolute Number_notification  start-100 translate-middle badge rounded-pill bg-danger">
                  {countOrder}
                  </span>
                </Link>
        </li>
        </>:
        <>
        <li className="nav-item">
        <Link
        //  data-bs-toggle="modal" data-bs-target="#PageNotification" 
          type="button" className="btn rounded-pill back-massge position-relative  mx-2">
                 <AiOutlineMail/>
                  <span className="position-absolute Number_notification top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    0
                  </span>
                </Link>
                </li>
                <li className="nav-item">
                 <Link type="button" className="btn rounded-pill back-massge position-relative  mx-2">
                 <FaDollarSign/>
                  <span className="position-absolute top-0 start-100 Number_notification translate-middle badge rounded-pill bg-danger">
                  0
                  </span>
                </Link> 
                </li>
                <li className="nav-item">
                 <Link type="button" className="btn rounded-pill back-massge position-relative mx-2">
                 <IoMdList/>
                  <span className="position-absolute top-0 start-100 Number_notification translate-middle badge rounded-pill bg-danger">
                   0
                  </span>
                </Link>
         </li>
         </>
}
              
      </ul>
    </>
  )
}
