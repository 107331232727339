
import React, { Component, useEffect, useState } from 'react'
import BarcodeReader from 'react-barcode-reader'
import { useDispatch, useSelector } from 'react-redux'
import { AddCart, Listprodect, opt_session_style } from '../../services/redux/action/action'
import sound from "../../asssets/sound/public_scanner.mp3"
import AddCartHook from '../../hooks/AddCartHook'

const Scinner = ({ setCount, setactiveid, setTextVal, setcartid, setitemActive, setChange, setitemProdect, refEvent }) => {







  const [result, setresult] = useState('No result')
  const [code, sercode] = useState()
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const prodects = useSelector(state => state.prodects);
  const open__session = useSelector(state => state.open_session);
  const user = JSON.parse(sessionStorage.getItem("user"));
  let OpenSession = open__session && open__session.status && open__session.message !== "error" ? open__session.data.pos_session : JSON.parse(sessionStorage.getItem("open_session"));
  const [AddCart] = AddCartHook();
  useEffect(() => {
    if (OpenSession !== null) {


      dispatch(Listprodect({ pos_id: OpenSession.pos_id }))
    }


  }, [open__session])
  useEffect(() => {
    if (prodects && prodects.data) {
      setData(prodects.data.products_list)

    }

  }, [prodects])

  const optsessionstyle = useSelector(state => state.opt_session_style);

  const HieadShowAuto = () => {
    if ((optsessionstyle.Show_Keyboard === true) || (optsessionstyle.show_Prodect === true)) {
      dispatch(opt_session_style({ Show_Keyboard: false, show_Prodect: false }))

    }
  }


  const handleScan = (dat) => {
    setresult(dat)
    const prodectOne = data.filter((item) => {
      return item.barcode_text == dat
    })


    let items = { ...prodectOne[0], order: 1 }

    if (items.id) {
      if (items.type == "ticket") {
        setitemProdect(items);
        if (refEvent.current) {
          refEvent.current.click();
        }
      } else {
        AddCart(
          setactiveid,
          setTextVal,
          setcartid,
          items,
          setChange,
          setCount,
          setitemActive
        );
      }
      // setCount(1)

      // const localcard = JSON.parse(sessionStorage.getItem("items"))
      // setactiveid(itemCard.id)
      // setitemActive(itemCard)
      // setTextVal("Qty")
      // let refuntCheck = localcard && localcard.length > 0 && localcard[0]["final_price"] < 0 ? false : true
      // if (refuntCheck) {
      //   if (localcard) {

      //     if (localcard.find((product) => product.id === itemCard.id)) {
      //       setcartid(itemCard.id)
      //       const index = localcard.findIndex(item => item.id == itemCard.id);
      //       if ((user.data.pos_stock_permission === 1 &&
      //         localcard[index].stock > localcard[index]["order"]) ||
      //         user.data.pos_stock_permission === 0) {
      //         localcard[index]["order"] += 1
      //         dispatch(AddCart(localcard))
      //         document.querySelector(".scanner_sound").play();
      //       }
      //     } else {
      //       dispatch(AddCart([...localcard, itemCard]))
      //       document.querySelector(".scanner_sound").play();
      //     }
      //   } else {
      //     dispatch(AddCart([itemCard]))
      //     document.querySelector(".scanner_sound").play();
      //   }
      // }
    }
    HieadShowAuto()
  }
  const handleError = (err) => {
  }
  return (
    <div>
      <audio className="d-none scanner_sound" controls>
        <source src={sound} type="audio/ogg" />
        <source src={sound} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <BarcodeReader
        onError={(err) => handleError(err)}
        onScan={data => { handleScan(data); sercode(data) }}
      />
    </div>
  )
}
export default Scinner;