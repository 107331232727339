import { t } from 'i18next'
import React from 'react'

export default function FooterInPrint() {
  const user = JSON.parse(sessionStorage.getItem("user"))

  return (
   
         <div className='d-flex align-items-center  justify-content-between p-2'>
         <div > 
         {user && user.data.business_address}
         </div>
         <div>
             
             <h6>   {t("commercial number")}    {user && user.data.business_commercial_number}</h6>
             
         </div>
        </div>
  )
}
