import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import NavComp from '../../layouts/NavComp/NavComp'
import "./style.css"
import { FaShoppingBasket,FaUserCircle } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import { BiX } from "react-icons/bi";
 import { t } from 'i18next';
import {  session_details } from '../../services/redux/action/action';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import SpinnerLoding from '../../components/SpinnerLoding/SpinnerLoding';
import PrintSession from '../../components/PrintSession/PrintSession';
import { useRef } from 'react';
import HeaderInPrint from '../../components/HeaderInPrint/HeaderInPrint';
import FooterInPrint from '../../components/FooterInPrint/FooterInPrint';
 
export default function DetailsSession() {
   const dispatch = useDispatch();
    const user =JSON.parse(sessionStorage.getItem("user"))
    const prame =useParams()
    const navget = useNavigate()
    useEffect(()=>{
      
             dispatch(session_details({session_id:prame.id,user_id:user.data.user_id}))

     
      },[prame.id])
    
      const sessionDetails = useSelector(state => state.session_details);
 
const open_the_session =(data)=>{
   sessionStorage.setItem("open_session",JSON.stringify(data)) 
   dispatch({type:"open_session",open_session:{ "status": true, "message": "success",
         "data": {
             "pos_session": data}}
   })

   navget("/session")
}
const componentRef = useRef()
  return (
    <>
<section className='DetailsSession'>
    <NavComp/>
    
    <div className='container-xxl'>
            <nav aria-label="breadcrumb  ">
               <ol className="breadcrumb fs-4">
                  <li className="breadcrumb-item"><Link  to={"/"}>{t("PointOfSale")}</Link></li>
                  <li className="breadcrumb-item"><Link  to={"/AllSession"}>{t("Sessions")} </Link></li>
                  <li className="breadcrumb-item active" aria-current="page">{sessionDetails && sessionDetails.data && sessionDetails.data.session.id}</li>
                </ol>
            </nav>
            <PrintSession componentRef={componentRef}/>
           {sessionDetails && sessionDetails.status  ?<div ref={componentRef} className='row '>
            <div className='header_print'>
           <HeaderInPrint titel={"Details Session"}/>
            </div>
               <div className="col-12 col-md-8  p-1  ">
                <div className='shadow m-2'>
                   <nav className='d-flex  fs-5  justify-content-end   flex-wrap nav_section_details  '>
                   <Link className='   d-flex align-items-center text-reset'  to={`/OrdersSession/${sessionDetails.data.session.id}`}> <FaShoppingBasket size={23}/>  { t("Orders") } </Link>
                     <Link className='  text-reset p-2'   to={`/SessionPayments/${sessionDetails.data.session.id}`}> <MdPayments/>  
                       {t("Payments")}  </Link>
                     
                   <div>
{             sessionDetails.data.pos_session.business_id&&        <button onClick={() => open_the_session(sessionDetails.data.pos_session)} className='red_btn btn '>{t("open the session")}</button>
}
                   </div>
                 </nav>
                <div className='p-2 fs-5'>
                     <h2 className='fw-bold'>Pos/{sessionDetails.data.session.reference}</h2>    
             
                 <div className='row   my-2'>
                    <div className=" col-5 ">{t("OpenedBy")}</div>
                    <div className=" col-7 text-muted">{sessionDetails.data.session.user}</div>
                 </div>
                 <div className='row   my-2'>
                    <div className=" col-5  ">{t("PointOfSale")}</div>
                    <div className=" col-7 text-muted">{sessionDetails.data.session.pos}</div>
                 </div>
              
                 <div className='row   my-2'>
                    <div className=" col-5  ">{t("OpeningDate")}</div>
                    <div className=" col-7 text-muted">{sessionDetails.data.session.open_date}</div>
                 </div>
                 <div className='row   my-2'>
                    <div className=" col-5  ">{t("ClosingDate")}</div>
                    <div className=" col-7 text-muted">{sessionDetails.data.session.close_date ?sessionDetails.data.session.close_date: sessionDetails.data.session.status}</div>
                 </div>
                 <div className='row   my-2'>
                    <div className=" col-5  ">{t("Payments")}</div>
                    <div className=" col-7 text-muted">{sessionDetails.data.session.payments_total} {user && user.data.currency_symbol}</div>
                 </div>
                 <div className='row   my-2'>
                    <div className=" col-5  ">{t("session closing difference")}</div>
                    <div className=" col-7 text-muted">{sessionDetails.data.session.closing_difference} {user && user.data.currency_symbol}</div>
                 </div>
               
                </div>
                </div>
              </div>
              <aside className='col-12 col-md-4  '>
               <div className='shadow div_parent'>
            
                  <div className='d-flex item my-2' >
                     <div className='p-2'>< FaUserCircle size={30}/></div>
                     <div className=''> 
                        <div className='d-flex     align-items-center ' >
                             <h4>{sessionDetails.data.session.user}</h4> 
                             <span className='text-muted px-2'>open</span>   
                        </div>
                        <div className='text-muted'>
                           <p>{sessionDetails.data.session.pos} </p>
                           {sessionDetails.data.session.opening_cash_currency_cat && JSON.parse(sessionDetails.data.session.opening_cash_currency_cat).map(item =>(
                           <p className='mb-0'> {item.name} <BiX/> {item.value}</p>

                           ))
                           }
                        </div>
                 
                     </div>
                     
                  </div>
                  <div className='d-flex item my-2' >
                     <div className='p-2'>< FaUserCircle size={30}/> </div>
                   
                     <div className=''> 
                        <div className='d-flex     align-items-center ' >
                             <h4>{sessionDetails.data.session.user}</h4> 
                             <span className='text-muted px-2'> close</span>   
                        </div>
                        <div className='text-muted'>
                           <p>{sessionDetails.data.session.pos} </p>
                           {sessionDetails.data.session.closing_cash_currency_cat && JSON.parse(sessionDetails.data.session.closing_cash_currency_cat).map(item =>(
                           <p className='mb-0'> {item.name} <BiX/> {item.value}</p>

                           ))
                           }
                        </div>
                 
                     </div>
                  </div>
               </div>
        
                    
                 
              </aside>
              <div className='footer_print'>
               <br />
            <FooterInPrint/>
            </div>
            </div> :<SpinnerLoding/>}
    </div>

            
</section>
    </>
  )
}
