import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { ListUser } from "../../services/redux/action/action";
import SpinnerLoding from "../SpinnerLoding/SpinnerLoding";
import { t } from "i18next";
const Selectuser = ({ setuser, data }) => {
  const dispatch = useDispatch();
  const [Actives, setActives] = useState("");

  return (
    <>
      {data.status ? (
        <table
          data-testid={"tabel_users"}
          className="table  mb-0  table-hover  table-striped fs-6 shadow selectuser"
        >
          <thead className="text-body-tertiary">
            <tr>
              <th>{t("Name")}</th>
              <th> {t("Phone")} </th>
              <th> {t("Email")} </th>
              {/* <th> {t("Points")}</th> */}

              <td> {t("Amount Due")} </td>
            </tr>
          </thead>
          <tbody>
            {data.data &&
              data.data.data.map((item) => {
                return (
                  <tr
                    key={item.id}
                    className={Actives === item.id ? "active" : ""}
                    onClick={() => {
                      setActives(item.id);
                      setuser(item);
                    }}
                  >
                    <td>{item.first_name}</td>
                    <td>{item.phone} </td>
                    <td>{item.email} </td>
                    {/* <td>{item.total} </td> */}

                    <td> {Number(item.due_balance).toFixed(2)} </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <SpinnerLoding />
      )}
    </>
  );
};

export default Selectuser;
