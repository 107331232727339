import React from "react";
import "./style.css";
import NavComp from "../../layouts/NavComp/NavComp";
import Draggable from "react-draggable";
import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import Shapes_div_input from "./Shapes_div_input";
import { t } from "i18next";
export default function TabelSetting() {
  const [copies, setCopies] = useState([]);
  const [Tabels, setTabels] = useState([]);
  const [CountPeopel, setCountPeopel] = useState([]);
  const [TabelsNumber, setTabelsNumber] = useState([]);
  const [tatelPosition, setatelPosition] = useState('');
   const Get_All_Tabel  =(TabelsData,data) =>{
    let indexNumber =  TabelsData.findIndex((item) => item.id ==data.node.id);
     if(indexNumber == -1){
      setTabels([
        ...TabelsData,
        {
          x: data.x,
          y: data.y,
          type: data.node.nonce,
          id: data.node.id,
        
        },
      ]) 
     }else{
      TabelsData[indexNumber] ={
        x: data.x,
        y: data.y,
        type: data.node.nonce,
        id: data.node.id,
    
      }
      setTabels(TabelsData)
     }
   
  };
 const Value_Tabels_Number =(number,id) =>{
   let indexNumber =  TabelsNumber.findIndex((item) => item.id ==id);
if(indexNumber == -1){
  setTabelsNumber([...TabelsNumber,{id:id, number:number}])
}else{
    TabelsNumber[indexNumber] = {id:id, number:number}
    setTabelsNumber([...TabelsNumber])
}
 }
 const Value_people_Number =(number,id) =>{
  let indexNumber =  CountPeopel.findIndex((item) => item.id ==id);
if(indexNumber == -1){
  setCountPeopel([...CountPeopel,{id:id, number:number}])
}else{
  CountPeopel[indexNumber] = {id:id, number:number}
 setCountPeopel([...CountPeopel])
}
}
const Remove_One_Tabel_by_id =(id) =>{
  setCopies(prevCopies => prevCopies.filter(copy => copy.props.id !== id));
  setTabels(prevCopies => prevCopies.filter(copy => copy.id != id ));
  setTabelsNumber(prevCopies => prevCopies.filter(copy => copy.id != id ));
  setCountPeopel(prevCopies => prevCopies.filter(copy => copy.id != id ));
}
    const handleStop = (e, data) => {
     const Data = [...Tabels]
     Get_All_Tabel(Data,data)
    };
const contentTabelIput =(type,ClassType) =>{
 let RandomID=(Math.random() * 200).toFixed(5)
return(
  <div nonce={type}  id={RandomID} className={ClassType} >
   <div className="text-end w-100"> <button onClick={() =>Remove_One_Tabel_by_id(RandomID)}  className="remove_btn" ><AiFillDelete size={25}/></button></div>
</div>
)
}
  const handleCopy = (ClassType, x = 0, y = 0, type) => {
    setCopies((prevCopies) => [
      ...prevCopies,
      contentTabelIput(type,ClassType)
    ]);
  };
  const validtion = (input_numberTabel)=>{
    input_numberTabel.forEach((item)=>{
      if(!item.value){
        item.classList.add("worning_requert")
      }
    })
  }
  const submitHandler = (e) => {
    e.preventDefault();
    const input_numberTabel = document.querySelectorAll(".input_numberTabel")
    const input_numberPeopel = document.querySelectorAll(".input_numberPeopel")
    const TitelSection = document.querySelectorAll(".titelPossstion")
    validtion(input_numberTabel)
    validtion(input_numberPeopel)
    validtion(TitelSection)
 let AllDataTabels =[]
   if(Tabels.length == TabelsNumber.length &&  CountPeopel.length == Tabels.length && tatelPosition){
    Tabels.forEach((item)=>{ 
       let index = TabelsNumber.findIndex((numb) =>numb.id == item.id)
       let indexPeopel = CountPeopel.findIndex((numb) =>numb.id == item.id)
       AllDataTabels.push({
        id:item.id,
        x:item.x,
        y:item.y,
        type:item.type,
        tabel_number:TabelsNumber[index].number,
        Count_Peopel: CountPeopel[indexPeopel].number
      })

    })
   sessionStorage.setItem("AllDataTabels",JSON.stringify({PositionName:tatelPosition,tabelsData:AllDataTabels}))
  setCopies([])
   setTabels([])
   setCountPeopel([])
    setTabelsNumber([])
     setatelPosition('')
   }
  };
  const AddRemoveClass = (e) =>{
    if(e.target.value){
     e.target.classList.remove("worning_requert")
    }else{
       e.target.classList.add("worning_requert")
    }
       }
  return (
    <section className="Tabels">
      <NavComp />
      <div>
        <section className="roomSection">
        <div className="d-flex btns_Tabels justify-content-center">
          <button
            className="circle_div shapes_div "
            onClick={(e) =>
              handleCopy("circle_div shapes_div ", e.screenX, 0, "circle")
            }
          >
          </button>
          <button
            className=" shapes_div"
            onClick={(e) => handleCopy(" shapes_div ", e.screenX, 0, "Square")}
          >
          </button>
          <button
            className="shapes_div triangle_div"
            onClick={(e) => {
              handleCopy(" shapes_div triangle_div ", e.screenX, 0, "triangle");
            }}
          >
          </button>
          <button
            className="shapes_div rectangle_div"
            onClick={(e) =>
              handleCopy(
                " shapes_div rectangle_div ",
                e.screenX,
                0,
                "rectangle"
              )
            }
          >
          </button>
          
        </div>
          <form onSubmit={(e) => submitHandler(e)}>
            <div className="container ">
              <div className="row g-3 justify-content-center align-items-center div_input_Tabel_titel">
                <div className="col-auto">
                  <input
                    type="text"
                    className="form-control titelPossstion"
                    id="titelPossstion"
                    placeholder={`${t("Place name")}`}
                    value={tatelPosition}
                    onChange={(e) =>{ setatelPosition(e.target.value);AddRemoveClass(e)}}
                  />
                </div>
                <div className="col-auto">
                  <button type="submit" className="btn dark_btn  py-1">
                    {t("save")}
                  </button>
                </div>
              </div>
            </div>
            <div className="conent_Draggable shadow">
              {/* <h3 className="text-center">{tatelPosition}</h3> */}
                {copies.map((copy, index) => (
              <Draggable    key={index} positionOffset={{ x: 0, y: 0 }} onStart={handleStop} onStop={handleStop}>
                <div id={copy.props.id}  nonce={copy.props.nonce} > 
                {copy}
           {/* ////////////// */}
           <Shapes_div_input onAddRemoveClass={AddRemoveClass} Value_Tabels_Number={Value_Tabels_Number} copy={copy} Value_people_Number={Value_people_Number}/>
                </div>
              </Draggable>
            ))}
            </div>
          
          </form>
        </section>
      </div>
    </section>
  );
}
