import React from "react";

import NavComp from "../../layouts/NavComp/NavComp";
import AddCustomerComp from "../../components/AddCustomerComp/AddCustomerComp";

export default function AddCustomerMain() {
  return (
    <AddCustomerComp>
      {" "}
      <NavComp />
    </AddCustomerComp>
  );
}
