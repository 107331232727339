import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavComp from "../../layouts/NavComp/NavComp";
import "./style.css";
import PaginateComp from "../../components/PaginateComp/PaginateComp";
import FiGrFavComp from "../../components/FiGrFavComp/FiGrFavComp";
import { createInstance, t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  pos_All,
  sessions_list,
  sessions_list_all,
} from "../../services/redux/action/action";
import SpinnerLoding from "../../components/SpinnerLoding/SpinnerLoding";
import PrintSession from "../../components/PrintSession/PrintSession";
import { useRef } from "react";
import Select from "react-select";
import AllSessionTabel from "../../components/AllTabels/AllSessionTabel";
export default function AllSession() {
  const dispatch = useDispatch();
  const [page, setpage] = useState(1);
  const [Idpos, setidpos] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    let data = {
      user_id: user.data.user_id,
      page: page,
      filter_from_date: startDate,
      filter_pos_id: Idpos ? Idpos.value : "",
      filter_to_date: endDate,
    };
    dispatch(sessions_list(data));
  }, [page, Idpos, startDate, endDate]);
  useEffect(() => {
    let data = {
      user_id: user.data.user_id,
      filter_from_date: startDate,
      filter_pos_id: Idpos ? Idpos.value : "",
      filter_to_date: endDate,
    };
    dispatch(sessions_list_all(data));
  }, [Idpos, startDate, endDate]);

  useEffect(() => {
    dispatch(pos_All({ business_id: user.data.business_id }));
  }, []);
  const sessionsList = useSelector((state) => state.sessions_list);
  const sessionsListAll = useSelector((state) => state.sessions_list_all);
  const posAll = useSelector((state) => state.pos_All);
  const componentRef = useRef();
  let optionPos = [];
  if (posAll && posAll.status) {
    posAll.data.forEach((e) => {
      optionPos.push({ value: e.id, label: e.name });
    });
  }
  return (
    <>
      <section className="AllSession">
        <NavComp />

        <div className="container-xxl">
          <div className="d-sm-flex mt-3    justify-content-between">
            <nav aria-label="breadcrumb ">
              <ol className="breadcrumb fs-4">
                <li className="breadcrumb-item">
                  <Link to={"/"}>{t("PointOfSale")}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {t("Sessions")}
                </li>
              </ol>
            </nav>

            <div className="d-flex justify-content-end">
              {sessionsList && sessionsList.status && sessionsList.data[0] && (
                <PaginateComp
                  setnumberPage={setpage}
                  pageCounts={sessionsList.data[0].pages_number}
                />
              )}
            </div>
          </div>

          <FiGrFavComp
            setReset={setidpos}
            setstartDate={setstartDate}
            setendDate={setendDate}
          >
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3 my-2">
                {optionPos && optionPos.length > 0 ? (
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    options={optionPos}
                    value={Idpos}
                    placeholder={t("PointOfSale")}
                    isClearable={true}
                    onChange={(data) => setidpos(data && data)}
                  />
                ) : (
                  <SpinnerLoding />
                )}
              </div>
            </div>
          </FiGrFavComp>
          <PrintSession componentRef={componentRef} />
          <div className="d-none">
            {sessionsListAll && sessionsListAll.status && (
              <AllSessionTabel
                componentRef={componentRef}
                sessionsList={sessionsListAll}
              />
            )}
          </div>
          <div className="shadow p-2     overflow-auto">
            {sessionsList &&
              !sessionsList.status &&
              sessionsList.message === "error" && (
                <h5 className="alert alert-danger text-center py-1">
                  {sessionsList.data}
                </h5>
              )}

            {sessionsList && sessionsList.status ? (
              <AllSessionTabel sessionsList={sessionsList} />
            ) : (
              <SpinnerLoding />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
