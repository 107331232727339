import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import SpinnerLoding from "../SpinnerLoding/SpinnerLoding";
import { useState } from "react";

export default function CashInOut() {
  const [nameManger, setnameManger] = useState("");
  const [Amount, setAmount] = useState("");
  const [Type, setType] = useState("Conversion");
  const [Notes, setNotes] = useState("");

  let user = JSON.parse(sessionStorage.getItem("user"));

  const cashAccountsList = useSelector((state) => state.cash_accounts_list);

  const options = [];
  if (cashAccountsList && cashAccountsList.status) {
    cashAccountsList.data.data.forEach((item) => {
      options.push({ value: item.id, label: item.name });
    });
  }
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header     justify-content-center">
              <h5 className="modal-title   " id="exampleModalLabel">
                {t("CashIn/Out")}{" "}
              </h5>
            </div>
            <div className="modal-body">
              <form>
                <div className="row px-4 py-2">
                  <div className="col-12 col-sm-4 col-lg-6 d-flex align-items-center  ">
                    <h4 className="px-2 mb-0">{t("From")}:</h4>
                    <p className="m-0 fs-5 lh-1">
                      {" "}
                      {user && user.data.cash_account_name}
                    </p>
                  </div>
                  <div className="col-12 col-sm-8 col-lg-6 d-flex align-items-center">
                    <h4 className="m-0 px-2">{t("To")}:</h4>
                    <div className="w-100">
                      {options.length > 0 ? (
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          options={options}
                          onChange={(data) => setnameManger(data && data.value)}
                        />
                      ) : (
                        <SpinnerLoding />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mt-4 row">
                      <label
                        htmlFor="inputPassword"
                        className="col-sm-4 col-form-label fs-5  "
                      >
                        {t("Amount")}
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="number"
                          onChange={(e) => setAmount(e.target.value)}
                          value={Amount}
                          name="Amount"
                          className="form-control"
                          id="inputPassword"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mt-4 row">
                      <label
                        htmlFor="inputPassword"
                        className="col-sm-2 col-form-label fs-5 "
                      >
                        {t("Type")}
                      </label>
                      <div className="col-sm-10">
                        <select
                          value={Type}
                          onChange={(e) => setType(e.target.value)}
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value="Conversion">{t("Conversion")}</option>
                          <option value="Reception">{t("Reception")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="message-text"
                    className="col-form-label fs-5 "
                  >
                    {t("Notes")}
                  </label>
                  <textarea
                    value={Notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className="form-control"
                    id="message-text"
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn dark_btn py-1"
                data-bs-dismiss="modal"
              >
                {t("Close")}
              </button>
              <button
                type="button"
                disabled={!nameManger || !Amount || !Type}
                className="btn red_btn py-1"
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
