import React from 'react'

export default function Card({lang,data,user,image,t}) {
  return (
     <>
         <div className='shadow p-2 Product_form'>
           <h4 className='text-center'>{t("Product form")}</h4>
      
        <div className="card border-0"  >
         { <img src={image} className="card-img-top"  />}
          <div className="card-body">
            <h5 className="card-title text-center"> {lang === "ar" ? data.name_ar:data.name_en} </h5>
            <p className="card-text text-center"> {lang === "ar" ? data.desc_ar:data.desc_en}  </p>
             <div className='d-flex justify-content-between'>
              <h4> {data.barcode}</h4>
             {data.price && <h4>{data.price}  {user && user.data.currency_symbol} </h4>}
             </div>
          </div>
          </div>
        
        </div>
     </>
  )
}
 