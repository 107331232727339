import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCart } from "../../services/redux/action/action";
import "./style.css";
import logo from "../../asssets/images/default_image.512x512.webp";
import i18next, { t } from "i18next";
import sound from "../../asssets/sound/public_scanner.mp3";
import AddCartHook from "../../hooks/AddCartHook";

function Carts({
  item,
  setactiveid,
  setTextVal,
  setcartid,
  setChange,
  setCount,
  refEvent,
  setitemProdect,
  setitemActive,
}) {
  // const { t, i18n } = useTranslation();
  const lang = i18next.language;
  let styleData = useSelector((state) => state.Select_Cart_Style);
  const user = JSON.parse(sessionStorage.getItem("user"));
  let itemCard = { ...item, order: 1, sent_price: item.final_price };
  let addEventTiket = (item) => {
    setitemProdect(item);
    if (refEvent.current) {
      refEvent.current.click();
    }
  };
  const [AddCart] = AddCartHook();
  const getprodect = (items) => {
    if (items.type == "ticket") {
      addEventTiket(items);
    } else {
      AddCart(
        setactiveid,
        setTextVal,
        setcartid,
        items,
        setChange,
        setCount,
        setitemActive
      );
    }
  };
  const replaceImage = (error) => {
    error.onerror = null; // prevents looping
    // check if the logo image exists
    const img = new Image();
    img.onerror = () => {
      // if the logo image is not found, use a placeholder image or an empty string
      error.target.src = ""; // or ''
    };
    img.src = logo;

    // if the logo image is found, use it as the replacement image
    error.target.src = logo;
  };

  return (
    <>
      <audio className="d-none scanner_sound" controls>
        <source src={sound} type="audio/ogg" />
        <source src={sound} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

      <div
        key={item.id}
        className="card text-center     position-relative"
        onClick={() => {
          ((user && user.data.pos_stock_permission !== 1) || item.stock > 0) &&
            getprodect(itemCard);
        }}
      >
        {item.product_brand && (
          <div className="brand_logo">
            <img src={item.product_brand} className="card-img-top" />
          </div>
        )}
        {(styleData.Cardcontent === "Image" ||
          styleData.Cardcontent === "All") && (
          <img
            src={item.image}
            onError={replaceImage}
            className="card-img-top"
            alt={item[`product_${lang}_name`]}
          />
        )}
        <div className="card-body">
          {(styleData.Cardcontent === "Name" ||
            styleData.Cardcontent === "All") && (
            <p className="card-title">
              {item[`product_${lang}_name`].length > 20
                ? item[`product_${lang}_name`].slice(0, 20) + "..."
                : item[`product_${lang}_name`]}
            </p>
          )}
          <p className="mb-0">
            {item.final_price} {user && user.data.currency_symbol}{" "}
          </p>
          {user && user.data.pos_stock_permission === 1 && (
            <div className=" fs-6 text-muted">
              {item.stock > 0 ? (
                `${t("Available")} :  ${item.stock}`
              ) : (
                <span className="text_red"> {t("Not available")}</span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Carts;
