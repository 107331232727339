import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Calculatorfun from "../../components/Calculator/Calculator";
import {
  add_sales_invoice,
  create_credit_note_refund,
} from "../../services/redux/action/action";
import { Helmet } from "react-helmet-async";

import "./style.css";
import { useTranslation } from "react-i18next";
import { TotalHook } from "../../hooks/TotalHook";
import { DiscHook } from "../../hooks/DiscHook";
function Payment() {
  const [number, setnumber] = useState(0);
  const [textVal, setTextVal] = useState("cash");
  const [cashpay, setcashpay] = useState(0);
  const [bankpay, setbankpay] = useState(0);
  const [worning, setworning] = useState(false);
  const [buttonActive, setbuttonActive] = useState(true);

  const navigate = useNavigate();

  const dispach = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const activfun = (item) => {
    setTextVal(item.target.name);
    item.target.classList.add("active");
  };
  let OpenSession = JSON.parse(sessionStorage.getItem("open_session"));

  useEffect(() => {
    if (!OpenSession) {
      navigate("/");
    }
  }, []);
  const prodect = JSON.parse(sessionStorage.getItem("items"));
  const [total, tax, extraTotal] = TotalHook(prodect);
  const getClassName = (eventKey) => {
    return eventKey === textVal
      ? "active calculator-key w-100 mt-2 rounded-pill"
      : " calculator-key w-100 mt-2 rounded-pill";
  };
  useEffect(() => {
    if (total > 0) {
      setbankpay(total);
    } else {
      setcashpay(total);
    }
  }, []);
  useEffect(() => {
    if (textVal == "cash" && total > 0) {
      setcashpay(Number(number));
    } else if (textVal == "Bank" && total > 0) {
      setbankpay(Number(number));
    }
  }, [number]);

  const Remaining = total - cashpay - bankpay;
  const bankTotal = total - bankpay > 0 ? total - bankpay : 0;
  const cashTotal = total - cashpay > 0 ? total - cashpay : total;
  const bantest =
    cashTotal >= 0
      ? cashTotal <= bankTotal
        ? cashTotal
        : total - bankTotal
      : 0;
  const cashtest =
    bankTotal >= 0
      ? bankTotal <= cashTotal
        ? bankTotal
        : total - cashTotal
      : 0;
  const vewCash = Remaining >= cashpay ? cashpay : cashtest;
  const vewBank = Remaining >= bankpay ? bankpay : bantest;

  const Change = Number(cashpay) + Number(bankpay);

  const changes = Change > total ? Change - total : 0;
  const customer = JSON.parse(sessionStorage.getItem("customer"));
  const user = JSON.parse(sessionStorage.getItem("user"));
  const date = new Date();
  var item_id = [];
  var item_price = [];
  var item_qty = [];
  var item_desc = [];
  var item_discount = [];
  var event_reference = [];
  if (prodect) {
    prodect.forEach((item) => {
      item_id.push(Number(item.id));
      item_price.push(Number(item.sent_price));
      item_qty.push(Number(item.order));
      item_desc.push(item.product_en_desc);
      item_discount.push(item.Disc ? item.Disc : 0);
      event_reference.push(item.event ? Number(item.event.reference) : "");
    });
  }
  let Refund = sessionStorage.getItem("Refund");
  const PaymentMethods = () => {
    if (total <= Change || (customer && customer.id)) {
      const data = {
        business_id: user.data.business_id,
        client_id: customer && customer.id,
        representative_id: user.data.user_id,
        created_date: new Date(date).toISOString().split("T")[0],
        due_date: new Date(date).toISOString().split("T")[0],
        description: "Invoice Desc",
        discount_type: 0,
        visa_amount: vewBank,
        cash_amount: vewCash,
        item_id: item_id,
        item_price: item_price,
        item_qty: item_qty,
        item_desc: item_desc,
        pos_session_id: OpenSession.id,
        item_discount,
        event_reference: event_reference,
      };

      const datRefund = {
        user_id: user.data.user_id,
        created_date: new Date(date).toISOString().split("T")[0],
        due_date: new Date(date).toISOString().split("T")[0],
        item_id: item_id,
        item_qty: item_qty,
        item_desc: item_desc,
        pos_session_id: OpenSession.id,
        order_id: Refund,
      };
      if (total > 0) {
        dispach(add_sales_invoice(data));
      } else if (total < 0) {
        dispach(create_credit_note_refund(datRefund));
      }
    } else {
      setworning(true);
      setTimeout(() => {
        setworning(false);
      }, 3000);
    }
    setbuttonActive(false);
  };
  const creatinv = useSelector((state) => state.add_sales_invoice);
  const createRefund = useSelector((state) => state.create_credit_note_refund);

  useEffect(() => {
    if (creatinv && creatinv.status == 1) {
      sessionStorage.setItem(
        "invoice_local",
        JSON.stringify({
          bankpay: vewBank,
          cashpay: vewCash,
          Change: changes,
          total,
          prodect,
          creatinv,
        })
      );
      navigate("/validate");
    } else if (createRefund && createRefund.status) {
      sessionStorage.setItem(
        "invoice_local",
        JSON.stringify({ creatinv: createRefund.data })
      );
      navigate("/validate");
    } else if (createRefund || creatinv) {
      setbuttonActive(true);
    }
  }, [creatinv, createRefund]);
  const path = window.location.origin;

  return (
    <>
      <Helmet>
        <title>Payment </title>
        <link rel="canonical" href={path} />
        <meta
          name="description"
          content="Determine the payment of Cam cash and Cam visa"
        />
      </Helmet>
      <div className="container-xxl payment-page">
        <div className="payment rounded p-2 d-flex justify-content-between">
          {buttonActive ? (
            <button
              disabled={total <= Change || customer ? false : true}
              type="button"
              onClick={PaymentMethods}
              className=" btn btn-next-prevn "
            >
              {lang == "ar" ? (
                <AiOutlineDoubleRight />
              ) : (
                <AiOutlineDoubleLeft />
              )}
              {t("next")}{" "}
            </button>
          ) : (
            <button
              type="button"
              className=" btn btn-next-prevn border-0  "
              disabled
            >
              {" "}
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              {t("next")}{" "}
            </button>
          )}
          <h2> {t("Payment")}</h2>
          <Link to={"/Session"}>
            {" "}
            <button type="button" className=" btn btn-next-prevn">
              {" "}
              {t("back")}
              {lang == "ar" ? (
                <AiOutlineDoubleLeft />
              ) : (
                <AiOutlineDoubleRight />
              )}{" "}
            </button>
          </Link>
        </div>
        {cashpay == 0 && bankpay == 0 ? (
          <div className="total text-center">
            <div
              className="number-total"
              onClick={() => {
                textVal == "Bank"
                  ? setbankpay(Remaining)
                  : setcashpay(Remaining);
              }}
            >
              {" "}
              {total.toFixed(2)} {user && user.data.currency_symbol}
            </div>
            <div className="message">
              {" "}
              {customer ? t("paymwntMithodCustomer") : t("paymwntMithod")}{" "}
            </div>
          </div>
        ) : (
          <div className="total container-xxl">
            <div className="d-flex justify-content-between  flex-column flex-sm-row div-price  ">
              <div>
                {" "}
                {t("Remaining")}{" "}
                <span
                  className={`color-number-price ${
                    worning && "text-danger text-decoration-underline"
                  } `}
                  onClick={() => {
                    Remaining > 0 &&
                      (textVal == "Bank"
                        ? setbankpay(Remaining)
                        : setcashpay(Remaining));
                  }}
                >
                  {" "}
                  {Remaining > 0 ? Remaining.toFixed(2) : 0}{" "}
                  <span className="fs-5">
                    {user && user.data.currency_symbol}
                  </span>
                </span>
                {Remaining > 0 && customer && (
                  <span className="px-2 fs-6">
                    {t(
                      "The amount will be deducted from the customer's balance."
                    )}
                  </span>
                )}
              </div>
              <div>
                {" "}
                {t("Change")}{" "}
                <span className="color-number-price">
                  {" "}
                  {Change > total ? Change - total : 0}{" "}
                  <span className="fs-5">
                    {user && user.data.currency_symbol}
                  </span>
                </span>
              </div>
            </div>
            <div>
              {t("TotalDue")} {user && user.data.currency_symbol}{" "}
              {total.toFixed(2)}
            </div>
            <div>
              {t("The amount paid")} {user && user.data.currency_symbol}{" "}
              <span className="fw-bold text_red text-decoration-underline fs-5">
                {number.toFixed(2)}
              </span>{" "}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col col-md-4 col-12 waypay">
            {bankpay != 0 ? (
              <div className="p-3  div-value-pay text-center rounded-pill mt-3 d-flex justify-content-between">
                <span> {t("Bank")}</span>{" "}
                <span>
                  {" "}
                  {vewBank.toFixed(2)}{" "}
                  <span className="fs-5">
                    {user && user.data.currency_symbol}
                  </span>{" "}
                </span>{" "}
                <span
                  onClick={() => {
                    setbankpay(0);
                    setnumber(0);
                  }}
                >
                  X
                </span>
              </div>
            ) : (
              <button
                name="Bank"
                className={getClassName("Bank")}
                onClick={(item) => {
                  total > 0 && activfun(item);
                  setnumber(0);
                }}
              >
                {t("Bank")}
              </button>
            )}
            {cashpay != 0 ? (
              <div className="p-3  div-value-pay text-center rounded-pill mt-3 d-flex justify-content-between">
                <span> {t("cash")}</span>{" "}
                <span>
                  {" "}
                  {vewCash.toFixed(2)}{" "}
                  <span className="fs-5">
                    {user && user.data.currency_symbol}
                  </span>{" "}
                </span>{" "}
                <span onClick={() => total > 0 && setcashpay(0)}>X</span>
              </div>
            ) : (
              <button
                className={getClassName("cash")}
                name="cash"
                onClick={(item) => {
                  activfun(item);
                  setnumber(0);
                }}
              >
                {t("cash")}
              </button>
            )}
            {user && Number(user.data.pos_choose_customers) === 1 && (
              <Link
                to={"/SelectUserToPay"}
                state={"Payment"}
                className="p-1 d-block  text-dark py-3 button-div text-center rounded-pill mt-3"
              >
                <span className="user-icon">
                  <FaUserCircle />{" "}
                </span>
                {t("List of clients")}
              </Link>
            )}
          </div>
          <div className="col col-md-8 col-12">
            <div className="calc-fun w-100 position-relative col col-md-6 col-8">
              <Calculatorfun setnumber={setnumber} />
              <div className="functions dd-numbers position-absolute">
                <button
                  className="calculator-key"
                  onClick={() => {
                    setnumber((val) => val + 10);
                  }}
                >
                  +10
                </button>
                <button
                  className="calculator-key"
                  onClick={() => {
                    setnumber((val) => val + 20);
                  }}
                >
                  +20
                </button>
                <button
                  className="calculator-key"
                  onClick={() => {
                    setnumber((val) => val + 30);
                  }}
                >
                  +30
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn m-2 w-50 p-3 btn-outline-secondary fs-4"
                disabled
              >
                <FaUserCircle size={30} /> {customer && customer.first_name}{" "}
              </button>
              {buttonActive ? (
                <button
                  onClick={PaymentMethods}
                  disabled={total <= Change || customer ? false : true}
                  type="button"
                  className="btn fs-4 m-2 w-50 p-3 btn-outline-secondary"
                >
                  <FaFileInvoiceDollar /> {t("invoce")}
                </button>
              ) : (
                <button
                  className="btn m-2 w-50 p-3 btn-outline-secondary fs-4"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
