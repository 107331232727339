import React from 'react'
import ReactPaginate from 'react-paginate';
import { FiChevronsLeft,FiChevronsRight } from "react-icons/fi";

export default function PaginateComp({setnumberPage,pageCounts}) {
   
  return ( 
  <>
     <ReactPaginate
                nextLabel={<FiChevronsRight/>}
                onPageChange={(e) => setnumberPage(e.selected + 1)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={0}
                pageCount={pageCounts}
                previousLabel={<FiChevronsLeft/>}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
          />
  </>
  )
}
