import React from 'react'
import SelectCartStyle from '../selectCartStyle/SelectCartStyle'
 import "./style.css"
export default function SettingSectionCart({StyelOption,setStyelOption,setProduct_display_format,Product_display_format}) {
  return (
<>
 
 
 
 
 
  <div className=" SettingSectionCart  p-1">
  <SelectCartStyle setProduct_display_format={setProduct_display_format} Product_display_format={Product_display_format}  StyelOption={StyelOption} setStyelOption={setStyelOption}/>  </div>
 
</>
  )
}
