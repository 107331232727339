import React, { useEffect } from "react";
import "./style.css";
import NavComp from "../../layouts/NavComp/NavComp";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginateComp from "../../components/PaginateComp/PaginateComp";
import {
  ListprodectByBusinessId,
  ListprodectByCatigory, pos_All, products_brands,
} from "../../services/redux/action/action";
import i18next, { t } from "i18next";
import logo from "../../asssets/images/default_image.512x512.webp";
import SpinnerLoding from "../../components/SpinnerLoding/SpinnerLoding";
import Select from "react-select";
import SelectInput from "../../lib/SelectInput/SelectInput";

export default function Prodects() {
  const [catigory, setCategory] = useState("all");
  const [numberPage, setnumberPage] = useState(1);
  const [Nearestpos, setNearestpos] = useState("");
  const [brands, setbrands] = useState("");
  const dispatch = useDispatch();
  let lang = i18next.language;
  const valueSearch = useSelector((state) => state.ValueSearch);
  const posAll = useSelector((state) => state.pos_All);
  const user = JSON.parse(sessionStorage.getItem("user"));
  let productsBrands = useSelector((state) => state.products_brands);

  useEffect(() => {
    if (user) {
      dispatch(pos_All({ user_id: user.data.user_id }));
    dispatch(products_brands());

    }
  }, []);
  const optionPoint = [];
  if (posAll && posAll.status) {
    posAll.data.forEach((e) => {
      optionPoint.push({ value: e.id, label: e.name });
    });
  }
   useEffect(() => {
    //get all data by filter page and search and category
if(Nearestpos){
  
  dispatch(
    ListprodectByCatigory(  { pos_id: Nearestpos ,page: numberPage  ,category_id:catigory,keyword: valueSearch,brand_id:brands } ) );


}else{
  dispatch(
    ListprodectByBusinessId(  { page: numberPage  ,category_id:catigory,keyword: valueSearch,brand_id :brands}  ) );
  
}
   
  }, [numberPage, valueSearch, catigory, Nearestpos,brands]);
  const prodects = useSelector((state) => state.ListprodectByCatigory);
  const replaceImage = (error) => {
    error.onerror = null; // prevents looping
     const img = new Image();
    img.onerror = () => {
       error.target.src = ""; // or ''
    };
    img.src = logo;
     error.target.src = logo;
  };
  let optionBrand=[]
  if (productsBrands && productsBrands.status) {
    productsBrands.data.brands.forEach((item) => {
      optionBrand.push({ value: item.id, label: item[`name_${lang}`] });
    });
  }
 
  return (
    <section className="prodects ">
      <NavComp />
      <div className="container-xxl ">
        {prodects && prodects.status === 1 ? (
          <div className="row">
            <div className="col-sm-4 col-lg-2 col-12 my-2  select_div_cstigory">
            <SelectInput  placeholder={t("Select the Brand")} option={optionBrand} getValue={setbrands} />
            <br className="my-3"/>
            <SelectInput  placeholder={t("Select the pos")} option={optionPoint} getValue={setNearestpos} />

             
      

              <ul className="list-group fs-5 mt-3">
              
                {prodects &&
                  prodects.status === 1 &&
                  prodects.data.category_list.map((item) => (
                    <li
                    key={item.id}
                      onClick={() => setCategory(item.id)}
                      className={`list-group-item  ${
                        catigory === item.id && "active"
                      } `}
                      aria-current="true"
                    >
                      {item[`category_${lang}_name`]}{" "}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="col-sm-8 col-lg-10 col-12 mt-2  all_prodects">
              <div className="row row-cols-2       row-cols-md-3  row-cols-lg-4 row-cols-xxl-5  g-4 ">
                {prodects &&
                prodects.status === 1 &&
                prodects.data.products_list.length > 0 ? (
                  prodects.data.products_list.map((item) => (
                    <div key={item.id} className="col ">
                      <div className="card position-relative shadow-sm border-0">
                        { item.product_brand && <div className="brand_logo">
                             <img src={item.product_brand}      className="card-img-top"    />
                           </div>}
                        <img
                          src={item.image}
                          className="card-img-top img_card"
                          onError={replaceImage}
                          alt="..."
                        />
                        <div className="card-body">
                          <h5 className="card-title text-center">
                            {item[`product_${lang}_name`]}
                          </h5>
                          <div className=" p-2   d-flex justify-content-between">
                            <div> {item.stock}</div>
                            <div>
                              {" "}
                              {item.final_price}{" "}
                              {user && user.data.currency_symbol}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h3 className="mt-5 text-center w-100">{t("no_data")}</h3>
                )}
              </div>

              <div className="mt-2 d-flex justify-content-center">
                {prodects && prodects.status === 1 && (
                  <PaginateComp
                    pageCounts={prodects.data.pages_number}
                    numberPage={numberPage}
                    setnumberPage={setnumberPage}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <SpinnerLoding />
        )}
      </div>
    </section>
  );
}
