import React from "react";

import AddCustomerComp from "../../components/AddCustomerComp/AddCustomerComp";
import { Link } from "react-router-dom";
import { t } from "i18next";

export default function AddCustomerSection() {
  return (
    <AddCustomerComp>
      <div className="container mt-3">
        <Link to={`/SelectUserToPay`} className="btn red_btn py-1 m-1 ">
          {t("List of clients")}
        </Link>
      </div>
    </AddCustomerComp>
  );
}
