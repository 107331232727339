import { t } from 'i18next'
import React from 'react'
 

export default function ItemNotifcation() {
  return (
  <>
    <div className="item">
                <div className="card w-100">
                  <div className="card-body py-2">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                    <h5  >Card title</h5>
                    <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
                        <p className="m-0 lh-1" >
                      With supporting text below as a natural lead-in to
                      additional content.
                      With supporting text below as a natural lead-in to
                      additional content.
                    </p>
                        </div>
                        <div className=" col-12 col-lg-4 mt-1 d-flex justify-content-between align-items-center ">
                                <button type="button" className="btn dark_btn  py-0" >{t("Refusal")}</button>
                            <button type="button" className="btn  red_btn  py-0">{t("Confirm")}</button>
                       </div>
                      
                    </div>
                  
                   
                  </div>
                </div>
              </div>
  </>
  )
}
