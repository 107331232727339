import "./style.css"
import React from "react";
import { FiDelete} from "react-icons/fi"
import PointTarget from 'react-point'
 class CalculatorKey extends React.Component {
  render() {
    const { onPress, className, ...props } = this.props
    return (
      <PointTarget onPoint={onPress}> 
       <div className={`calculator-key ${className}`} {...props}/>
      </PointTarget>
    )
  }
}
  const  Calculatorfun =  ({setCount,setnumber,setChange}) =>{
    const  inputDigit =(digit) => {
      setChange &&  setChange(true)
      if(setCount){
        setCount(val =>Number (val + String(digit) ))
      }
      if(setnumber)
        setnumber(val =>Number (val + String(digit) ))
     }
     return (
      <div className="calculator">
         <div className="calculator-keypad">
          <div className="input-keys">
            <div className="digit-keys ">
              <CalculatorKey onPress={() => inputDigit(1)} className="key-1 "  >1</CalculatorKey>
              <CalculatorKey onPress={() => inputDigit(2)} className="key-2 " >2</CalculatorKey>
              <CalculatorKey onPress={() => inputDigit(3)} className="key-3 " >3</CalculatorKey>
              <CalculatorKey onPress={() => inputDigit(4)} className="key-4 " >4</CalculatorKey>
              <CalculatorKey onPress={() => inputDigit(5)} className="key-5 " >5</CalculatorKey>
              <CalculatorKey onPress={() => inputDigit(6)} className="key-6 ">6</CalculatorKey>
              <CalculatorKey onPress={() => inputDigit(7)} className="key-7 " >7</CalculatorKey>
              <CalculatorKey onPress={() => inputDigit(8)} className="key-8 " >8</CalculatorKey>
              <CalculatorKey onPress={() => inputDigit(9)} className="key-9 " >9</CalculatorKey>
              <CalculatorKey   className="key-sign d-flex " ><button className="btn w-50" onClick={() => {setCount && setCount(old =>Number(old)  + 1); setnumber &&  setnumber(old =>Number(old)  + 1)}}>+</button>/<button className="btn w-50" onClick={() => { setCount && setCount(old => old > 1 ?  old - 1: 0);setnumber && setnumber(old =>old > 1 ? old  - 1: 0)}}>-</button>  </CalculatorKey>
              <CalculatorKey onPress={() => inputDigit(0)} className="key-0 " >0</CalculatorKey>
              <CalculatorKey  onPress={() =>{setnumber &&  setnumber(old =>`${old}.`); setCount && setCount(old =>`${old}.`)}} className="key-dot " >●</CalculatorKey>
            </div>
          </div>
          <div onClick={() => { setCount && setCount(0); setnumber && setnumber(0)}} className="key-clear" ><FiDelete/></div>

        </div>
      </div>
    )
 
}

export default Calculatorfun