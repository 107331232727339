import { DiscHook } from "./DiscHook";

export const TotalHook = (prodect)=>{
    let total = 0;
    let tax =0;
    let extraTotal =0
  
      if(prodect){
        prodect.map((item) => {
         const [discountedPrice] = DiscHook(item.final_price,item.Disc)
         const [discountedPriceTax] = DiscHook(item.price,item.Disc)
         total += item.Disc ?item.order *  discountedPrice:item.order *  item.final_price
          tax += Number((item.order * ((item.Disc  ?discountedPriceTax :item.price ) * Number(`.${item.tax_amount}`)  )).toFixed(2))
          if( item.extra){
            item.extra.map((itemEX) => {
              extraTotal += itemEX.price 
            })
         
           }
        })
       }
       return[total,tax,extraTotal]
     
}