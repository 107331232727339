import React, { useState } from 'react'
 import { Link, useNavigate } from 'react-router-dom';
import AsideOrderSuspension from '../../layouts/asideOrderSuspension/asideOrderSuspension';
import "./style.css"
import { AiFillDelete } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { AddCart, Orders_Suspension } from '../../services/redux/action/action';
import { t } from 'i18next';

export default function OrderSuspension() {
   const [Actives, setActives] = useState("")
  const [ProdectsOrder, setProdectsOrder] = useState([])
const dispatch = useDispatch()
const navigate = useNavigate()
const orders_Suspension = JSON.parse(sessionStorage.getItem("orders_Suspension")) 
 

  const remove =(id)=>{
    const removeOrder = orders_Suspension.filter((item) => item.id !== id)
     dispatch(Orders_Suspension(removeOrder))
  }
  const completOrder = (order)=>{
    if(Actives){
       dispatch(AddCart(order))
       remove(Actives)
     navigate(`/Session`)  
    }
  }

   return (
     <section className='container-xxl'>
     <div>
      <Link to={`/Session`} className='btn dark_btn py-1 m-1 '>{t("back")}</Link>

     </div>
     <div className='row'>
  <div className='col-12 col-md-8'>
   <div className=' p-3 m-1  Order_tabel_Suspension shadow   overflow-auto'>
            <table  className="table  mb-0  table-hover   table-bordered">
              <thead className="text-body-tertiary">
                  <tr>
                    <td>{t("OrderRef")}</td>
                  <td>{t("Date")}</td>
                  <td> {t("Number_products")}  </td>
                  <td> {t("Customer")} </td>
                  <td> {t("Employee")}</td>
              

                  <td> {t("total")} </td>
               
                    
                  </tr>
              </thead>
              <tbody>
          
                  {
                   
                         orders_Suspension  && orders_Suspension.map((item) =>{
                            let Total  =item.prodects && item.prodects.reduce((accumulator,currentValue) =>accumulator+ (currentValue.final_price *currentValue.order)   ,0)
                            let extra =0
                            item.prodects.forEach((pro) =>{
                              if(pro.extra){
                                 extra  =  pro.extra.reduce((accumulator,currentValue) =>accumulator+  currentValue.price   ,0)

                              }
                            })
                            
                            return(
                        <tr key={item.id} className={ Actives ===  item.id && "active"} onClick={() => {setProdectsOrder(item.prodects); setActives(item.id)}} >
                        <td>{item.id}</td>
                        <td>{item.date} </td>
                        <td>{item.prodects && item.prodects.length} prodect </td>
                        <td>{item.customer_name} </td>
                        <td>{item.customer_id } </td>
                        <td>{Total + extra} </td>
                        <td><button onClick={() => remove(item.id)}> <AiFillDelete size={25}  /></button></td>
                        
                      </tr>
                      )
                    }) 
                  }
             
                  
              </tbody>
          </table>  
          </div>
  </div>
  <div  className='col-12 col-md-4'>
  <AsideOrderSuspension
            //   setCount={setCount}
            //   setTextVal={setTextVal}
            //   activeid={activeid}
            //   textVal={textVal}
            //   cartid={cartid}
             onCompletOrder={completOrder}
              prodect={ProdectsOrder}
            />
  </div>
     </div>
   
     </section>
  )
}
