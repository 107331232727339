
const initalValue =
{
      prodects: [],
      custom: [],
      users: [],
      item: [],
      count: 0,
      login: [],
      add_sales_invoice: [],
      Tabels: [],
      orders_Suspension: [],
      openSession: [],
      currency_categories_list: [],
      cash_accounts_list: [],
      nearest_pos: [],
      ValueSearch: '',
      ListprodectByCatigory: [],
      open_session: [],
      Select_Cart_Style: {},
      sessions_list: [],
      session_details: [],
      close_session: [],
      get_session_totals: [],
      pos_session_orders_list: [],
      session_payment_list: [],
      sessions_list_all: [],
      pos_session_orders_list_all: [],
      opt_session_style: {},
      pos_All: [],
      add_customer: [],
      pos_order_detail: [],
      create_credit_note_refund: [],
      business_taxes_list: [],
      products_brands: [],
      add_product: [],
      reset_password: [],
      forgot_password: [],
      products_types: [],
      products_categories: [],
      events: []
}
export const casherReducer = (state = initalValue, action) => {
      switch (action.type) {
            case "LISTUSER":
                  return { ...state, users: action.users }
            case "events":
                  return { ...state, events: action.events }
            case "ADDITEM":
                  return { ...state, item: action.payload }
            case "Tabels":
                  return { ...state, Tabels: action.payload }
            case "Qunt":
                  return { ...state, count: state.count + action.payload }
            case "ADDCUSTOM":
                  return { ...state, custom: action.custom };
            case "ALLPRODECTS":
                  return { ...state, prodects: action.prodects }
            case "login":
                  return { ...state, login: action.login }
            case "add_sales_invoice":
                  return { ...state, add_sales_invoice: action.add_sales_invoice }
            case "orders_Suspension":
                  return { ...state, orders_Suspension: action.payload }
            case "openSession":
                  return { ...state, openSession: action.openSession }
            case "currency_categories_list":
                  return { ...state, currency_categories_list: action.currency_categories_list }
            case "cash_accounts_list":
                  return { ...state, cash_accounts_list: action.cash_accounts_list }
            case "nearest_pos":
                  return { ...state, nearest_pos: action.nearest_pos }
            case "ValueSearch":
                  return { ...state, ValueSearch: action.ValueSearch }
            case "ListprodectByCatigory":
                  return { ...state, ListprodectByCatigory: action.ListprodectByCatigory }
            case "open_session":
                  return { ...state, open_session: action.open_session }
            case "Select_Cart_Style":
                  return { ...state, Select_Cart_Style: action.Select_Cart_Style }
            case "sessions_list":
                  return { ...state, sessions_list: action.sessions_list }
            case "session_details":
                  return { ...state, session_details: action.session_details }
            case "close_session":
                  return { ...state, close_session: action.close_session }
            case "get_session_totals":
                  return { ...state, get_session_totals: action.get_session_totals }
            case "pos_session_orders_list":
                  return { ...state, pos_session_orders_list: action.pos_session_orders_list }
            case "session_payment_list":
                  return { ...state, session_payment_list: action.session_payment_list }
            case "sessions_list_all":
                  return { ...state, sessions_list_all: action.sessions_list_all }
            case "pos_session_orders_list_all":
                  return { ...state, pos_session_orders_list_all: action.pos_session_orders_list_all }
            case "opt_session_style":
                  return { ...state, opt_session_style: action.opt_session_style }
            case "pos_All":
                  return { ...state, pos_All: action.pos_All }
            case "add_customer":
                  return { ...state, add_customer: action.add_customer }
            case "pos_order_detail":
                  return { ...state, pos_order_detail: action.pos_order_detail }
            case "create_credit_note_refund":
                  return { ...state, create_credit_note_refund: action.create_credit_note_refund }
            case "business_taxes_list":
                  return { ...state, business_taxes_list: action.business_taxes_list }
            case "products_brands":
                  return { ...state, products_brands: action.products_brands }
            case "add_product":
                  return { ...state, add_product: action.add_product }
            case "reset_password":
                  return { ...state, reset_password: action.reset_password }
            case "forgot_password":
                  return { ...state, forgot_password: action.forgot_password }
            case "products_types":
                  return { ...state, products_types: action.products_types }
            case "products_categories":
                  return { ...state, products_categories: action.products_categories }
            default:
                  return state
      }
}