import { t } from 'i18next'
import React from 'react'
import { useState } from 'react'
 
export default function NotesComp({setNotes}) {
const [valueNotes,setvalueNotes] = useState('')
 
  const sentNotes =( ) =>{
    setNotes(valueNotes)
    setvalueNotes("")
  }

  return (
     <>

<div className="modal fade" id="NotesComp" tabIndex="-1" aria-labelledby="NotesCompLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <div className="text-center">
        <h4 className="modal-title " id="NotesCompLabel">{t("Notes")} </h4>
       </div>
      <div className="modal-body">
        <form>
         
          <div className="mb-3">
             <textarea value={valueNotes} onChange={(event) => setvalueNotes(event.target.value)} className="form-control" id="message-text"></textarea>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t("Discard")}</button>
        <button type="button" className="btn py-1 red_btn" onClick={sentNotes}   data-bs-dismiss="modal">{t("Confirm")}</button>
      </div>
    </div>   
  </div>
</div>
 </>
  )
}
